.container {
    max-width: 100%;
    height: 800px;
    background:url(https://assets.cdn.filesafe.space/gDomeI4d9kjTwwlhShAw/media/6393588c36ca03c82782d4d4.jpeg);
    display: flex;
    justify-content: center;
    object-fit: cover;
    overflow: hidden;
}


.content_wrapper{
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap:20px
}

.content_wrapper>div{
    width:83%;
    max-width:83%;
}

.heading{
    font-weight: 700;
    /* font-size: 42px; */
    font-size: 36px;
    color:white;
    text-align: start;
    max-width: 83%;
    width: 83%;
    margin-bottom: 20px;
}

.card_wrapper{
    display: flex;
    gap:40px;
    justify-content: start;
    max-width: 100%;
    width: 100%;
    overflow-x: auto;
}


.card_wrapper::-webkit-scrollbar {
    display: none;
}

.card{
    max-width: 380px;
    min-width: 380px;
    background-color: white;
    display: flex;
    flex-direction: column;
    gap: 20px;
    border-radius: 15px;
    box-shadow: 20px;
}

.card>img{
   width:100%;
   /* height:50%; */
   height:42%;
   object-fit: cover;
   border-top-right-radius: 15px;
   border-top-left-radius: 15px;
}

.card_bottom{
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 0 20px;
}

.card_bottom>h4{
    color:black;
    font-weight: 700;
    text-align: center;
}

.card_bottom>p{
    text-align: center;
    color:black;
    font-size: 14px;
}

.card_bottom>h6{
    text-align: center;
    color: blue;
    cursor: pointer;
}

@media screen and (max-width:426px) {
    .heading{
        font-size: 35px;
        text-align: center;
        margin-bottom: 30px;
        width: 100%;
        max-width:100%;
    }
    .content_wrapper>div{
        width:100%;
        max-width:100%;
    }
    .card{
        max-width: 360px;
        min-width: 360px;
    }
}