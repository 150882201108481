.about_content h5 {
    font-size: 17px;
    font-weight: 600;
}


.about_content {
    width: 100%;
    background: none;
    padding: 25px 20px;
    padding-top: 0px;
}

.about_content h4 {
    text-align: left;
    font-size: 16px;
    margin-bottom: 10px;
    color: #111;
    font-weight: 600;
}

.about_content p {
    /* margin-bottom: 0px; */
    text-align: justify;
    font-size: 12px;
    color: #0d0a0a;
}

.about_content iframe {
    margin-top: 20px;
}

.about_content button {
    display: block;
    margin-top: 10px;
    background: linear-gradient(180deg, #00526b 0%, #0098b9 100%);
    width: 100%;
    border: none;
    color: #fff;
    padding: 18px 20px;
    outline: none;
    border-radius: 15px;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 13px;
}

@media screen and (min-width: 300px) and (max-width: 767px) {
    .about_content {
        padding: 15px 0px;
    }

    .about_content h4 {
        font-size: 14px;
    }

    .about_content p {
        font-size: 13px;
    }

    .about_content button {
        padding: 15px 20px;
        font-size: 11px;
        border-radius: 10px;
    }
}

@media screen and (min-width: 1440px) {
    .about_content {
        padding-left: 150px;
    }
}