:root {
    --white: #ffffff;
    --black: #000000;
    --transparent: transparent;
    --color-lffu6uvb: #603F8B;
    --color-lffudicf: #F43AB0;
    --color-lffy4ue2: #9C9C9C;
    --primary-btn-clr: #1a385c;
}


.section1 {
    width: 100%;
    height: 80vh;
    min-height: 600px;
    background-position: 50% !important;
    background-repeat: repeat !important;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    background-image: url(https://assets.cdn.msgsndr.com/gDomeI4d9kjTwwlhShAw/media/641789725d1877ba92b34e37.jpeg);
}

.header {
    padding: 25px 15px;
    display: flex;
    align-items: center;   
}

@media screen and (min-width:768px) {
    .header{
        display: none;
    }
}

.header div {
    z-index: 90;
}

.header div img {
    width: 215px;
}

.header p {
    font-size: 18px !important;
    font-weight: 600 !important;
}

.section1_text_box {
    margin-left: 15px !important;
    margin-top: 25px;
}

.section1_text_box>h1 {
    color: var(--color-lffu6uvb) !important;
    font-weight: 600;
    font-size: 45px;
    width: 900px;
}

.section1_text_box>p {
    width: 540px;
    font-size: 20px !important;
    /* font-weight: 400 !important; */
    font-weight: 400 !important;
}

.section1_text_box>a>button {
    background-color: var(--primary-btn-clr);
    color: var(--white);
    padding: 15px 40px;
    font-size: 16px;
    border-radius: 5px;
    margin-top: 40px;
    font-weight: 700;
}
.p1{
    margin-bottom: 15px !important;
    font-weight: 600;
    margin-top: 155px;
    margin-left: 25px;
}
.p1>span{
    color: var(--color-lffudicf);
}
.panda {
    display: flex;
    gap: 50px;
    margin-left: 25px;
}

.panda>img {
    border-radius: 5px;
    width: 216px !important;
}

@media only screen and (max-width: 1200px) {
    .section_under{
          padding-inline: 38px;
    }
}

@media only screen and (max-width: 767px) {

    .section1 {
        /* height: 700px; */
        height: 40vh;
        min-height: 550px;
    }
    .section1_text_box>a>button {
        padding: 10px 30px;
        font-size: 16px;
    }
    .section_under{
        padding-inline: 0;
    }
    .header {
        /* justify-content: center; */
        padding-top: 25px;
    }

    .header div img {
        width: 250px;
    }

    .section1_text_box {
        /* margin-left: 15px !important;
        margin-top: 25px; */
        padding-inline: 20px;
        margin-left: 0 !important;
        margin-top: 20%;
        width: 79%;
        flex-direction: column;
        display: flex;
    }

    .section1_text_box>h1 {
        width: 100%;
        /* text-align: center; */
        text-align: start;
        /* font-size: 32px; */
        font-size: 30px;
        font-weight:700;
    }

    .section1_text_box>p {
        /* width: 380px; */
        max-width: 100%;
        margin: 0 auto;
        /* text-align: center; */
        text-align: start;
        font-size: 16px !important;
        width: 100%;
        font-weight:700;
    }

    .section1_text_box>a>button {
        /* padding: 9px 26px; */
        margin-top: 17px;
        font-size: 14px;
    }

    .section1_text_box>div {
        display: flex !important;
        justify-content: center !important;
    }
}


@media only screen and (max-width: 456px) {

    .section1_text_box {
        padding-inline: 20px;
        margin-left: 0 !important;
        /* margin-top: 78px; */
        width: 87%;
        flex-direction: column;
        display: flex;
        align-items: flex-start;
    }

    .section1_text_box>h1 {
        color: var(--color-lffu6uvb) !important;
        font-weight: 700;
        /* font-size: 40px; */
        font-size: 28px;
    }

    .section1_text_box>p {
        padding-right: 18px;
        font-weight: 600 !important;
        font-size: 16px !important;
    }

    .section1_text_box>div>button {
        margin-top: 20px;
        width: 244px;
    }

    .section1_text_box>a>button {
        /* padding: 6px 20px; */
         font-size: 14px;
        margin-top: 21px;
    }

}

@media only screen and (max-width: 385px) {
    .section1 {
        /* height: 600px; */
        left: 0;
        top: 0;
        margin: 0;
    }
    .section1_text_box {
        padding-inline: 13px;
        margin-left: 0 !important;
        /* margin-top: 40px; */
        margin-top: 30px;
        width: 92%;
    }
}