.causes_grid_container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    column-gap: 29px;
    row-gap: 50px;
}

@media screen and (max-width: 1100px) {
    .causes_grid_container {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
}

@media screen and (max-width:880px) {
    .causes_grid_container {
        grid-template-columns: 1fr 1fr 1fr;
    }
}

@media screen and (max-width:768px) {
    .causes_grid_container {
        display: grid !important;
    }
}

@media screen and (max-width:630px) {
    .causes_grid_container {
        grid-template-columns: 1fr 1fr !important;
    }
}