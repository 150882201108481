.desktop_browse_card_content {
    display: flex;
    flex-direction: column;
    /* gap: 15px; */
    gap: 5px;
    justify-content: center;
    align-items: center;
    position: relative;
}

.desktop_browse_card_heading {
    /* font-size: 42px; */
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 47px;
    /* letter-spacing: 0.84px; */
    text-align: center;
}

.low_quality_section_image {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    height: 100%;
    object-fit: cover;
    object-position: center;
    width: 100%;
    border-radius: 25px;
}

@media screen and (max-width:768px) {
    .desktop_browse_card_content {
        gap: 0px;
    }
    /* .low_quality_section_image {
        height: 155px;
    } */
}

@media screen and (max-width:310px) {
    .desktop_browse_card_heading {
        margin-bottom: 0;
    }
}