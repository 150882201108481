.dropDownContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.label {
    font-size: 15px;
    font-weight: 600;
}