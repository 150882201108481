.bag-card-container {
    width: 100%;
    height: 143px;
    border: 1px solid #A7A7A7;
    border-radius: 20px;
    margin: 0 auto;
    margin-bottom: 20px;
    display: flex;
    overflow: hidden;
}

.bag-card-img_container {
    width: 143px;
    height: 143px;
    padding: 10px;
}

.bag-card-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
}

.bag-card-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 5px 0;
}

.bag-card-content > h1 {
    font-family: "AvenirNext";
    font-size: 18px;
    font-weight: 600;
    padding-top: 15px;
}

.bag-card-content > p {
    font-family: "AvenirNext";
    font-size: 12px;
    font-weight: 500;
    line-height: 12px;
    text-align: left;
    height: 85px;
    overflow: hidden;
}

.bag-card-qty_price {
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-size: 12px;
}

