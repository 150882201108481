.imageinputContainer {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 10px;
  gap: 10px;
  /* background-color: #ebebeb; */
  border: 1px solid var(--black-color);
  border-radius: 5px;
  height: 102px;
}

.imageinputImage {
  width: 80px;
  height: 80px;
  border-radius: 10px;
}

.imageinputVideo {
  width: 100px;
  /* height: 100px; */
  height: 100%;
  max-height: 100%;
  border-radius: 10px;
  overflow: hidden;
}

.deleteImageIcon {
  position: absolute;
  right: 2%;
  top: 5%;
  background-color: var(--white-color);
  color: var(--red-color);
  width: 18px;
  height: 18px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  cursor: pointer;
  font-weight: 600;
  z-index: 20;
}

.imageinputImage>img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 10px;
}

.imageinputFileWrapper {
  width: 80px;
  height: 80px;
  position: relative;
  overflow: hidden;
  background: var(--white-color);
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  font-size: 4rem;
  border-radius: 10px;
}

.imageinputFileWrapper>input {
  position: absolute;
  inset: 0;
  cursor: pointer;
  opacity: 0;
  filter: alpha(opacity=0);
}