.profitContainer_one {
    /* background-color:  #F0E8E3; */
    padding: 40px 21px;
    display: flex;
    flex-direction: column;
    gap: 27px;
    border-radius: 15px;
    border-collapse: separate;
    width: 96%;
    margin: auto;
    border: #F0E8E3
}

.first_section {
    display: flex;
    flex-direction: column;
    /* gap: .8rem; */
    justify-content: space-between;
    height: 100%
}

.inner_section {
    display: flex;
    flex-direction: column;
    gap: .8rem;
}

.inner_section>h3 {
    font-size: 32px;
    width: 82%;
    font-style: normal;
    font-weight: 400;
    line-height: 35px;
    letter-spacing: -0.64px;
}

.inner_section>p {
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.36px;
}

.first_section>div {
    font-size: 18px;
    font-weight: 600;
    padding: 5px 21px;
    border-radius: 20px;
    /* border: 2px solid #FC3300; */
    width: fit-content;
}

hr {
    /* border: 1px solid #FC3300 !important; */
    opacity: 1 !important;
    width: 98%;
    margin-inline: auto !important;
}

/* styles for desktop design */

.desktop_section {
    height: 600px;
}

.desktop_container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-block: 70px;
    gap: 30px;
    height: 100%;
}

@media screen and (min-width:768px) {
    .inner_section>h3 {
        font-size: 42px;
        line-height: 47px;
    }

    .inner_section>p {
        font-size: 23px;
        line-height: 30px;
    }
}

@media screen and (max-width:1205px) {
    .desktop_section {
        height: 650px;
    }
}

@media screen and (max-width:861px) {
    .desktop_section {
        height: 731px;
    }
}