.brand_partner_container {
  margin: 0 auto;
  padding: 47px 80px 0;
}

.brand_partner_container div>img {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
}

.brand_partner_container p {
  margin: 0 0 30px !important;
  font-weight: 400;
}

.section2 {
  display: grid;
  grid-template-columns: 49% 49%;
  row-gap: 30px;
  column-gap: 20px;
  margin-top: 30px;
}

.section2 h2 {
  grid-column: 1/3;
  font-weight: 600;
  font-size: 30px;
}

.section2>div {
  background: #FFFFFF;
  border: 1px solid #d3d3d3;
}

.card_bottom {
  padding: 20px 27px;
}

.card_bottom h3 {
  font-weight: 700;
  font-size: 16px;
}

.card_bottom p {
  font-weight: 400;
  font-size: 14px;
  margin: 15px 0;
}

.section3 {
  margin-top: 91px;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}

.section3 h3 {
  font-weight: 500;
  font-size: 28px;
}

.contact_us {
  display: flex;
  justify-content: start;
  padding-top: 10px;
}

.contact_us button {
  background-color: #ff0000 !important;
  color: #fff !important;
  border-radius: 25px !important;
  font-weight: bold !important;
  font-family: "alternategotno1dregular" !important;
  font-size: 32px !important;
  line-height: 1 !important;
  padding: 12px 40px !important;
  min-width: 200px !important;
  text-align: center !important;
  text-transform: uppercase !important;
  letter-spacing: 0.3px !important;
}

.image_container {
  display: grid;
  grid-template-columns: auto auto auto auto;
  gap: 10px;
  margin: 0 0 30px;
}

.image_container>img {
  margin-top: 60px;
  border-radius: 25px;
  border: solid #1a385c;
}

@media screen and (max-width: 768px) {
  .brand_partner_main {
    display: none;
  }

  .brand_partner_container {
    padding: 20px;
  }

  .contact_us {
    justify-content: center;
  }
}