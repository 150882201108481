.countdown_container {
    font-family: Arial, sans-serif;
    display: flex;
    gap:20px;
  /*   flex-direction: column; */
    align-items: center;
    /* padding: 20px; */
  /*    background-color: #f0f0f0; */
    /* border-radius: 10px; */
  /*    max-width: 600px; */
    margin: 0 auto;
    color: black;
    display: flex;
    padding: 20px;
    justify-content: space-between;
    background-color: white;
    flex-wrap: wrap;
    position: fixed;
    top: 0;
    width: 90%;
  }

  .countdown_title{
    display: flex;
    align-items: center;
    width: 40%;
  }

  .title_main{
    font-size: 42px;
    font-weight: 700;
    text-align: left;
  }

  .countdown_header {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 10px;
  }

  .countdown_timer {
    display: flex;
    justify-content: center;
    gap: 10px;
    flex-wrap: wrap;
    width: fit-content;
  }

  .countdown_item {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 50px;
    flex-grow: 1; 
    font-weight: 700;
    /* font-size: 48; */
    line-height: 38px;
    color: #FC3300;
  }

  .countdown_value {
    font-size: 26px;
    font-weight: 700;
  }

  .countdown_label {
    font-size: 0.8rem;
    font-weight: 600;
    line-height: 20px;
  }

  .countdown_footer {
    font-size: 1rem;
    margin-top: 10px;
  }

  @media (max-width: 600px) {

    .countdown_title{
      display: none;
    }

    .item_count{
      display: none;
    }

    .countdown_container {
      padding: 10px;
      gap: 0;
      /* flex-direction: column; */

    }

    .countdown_header {
      font-size: 1.5rem;
    }

    .countdown_timer {
      gap: 0;
    }

    .countdown_item {
      min-width: 40px;
    }

    .countdown_value {
      font-size: 1.5rem;
    }

    .countdown_label {
      font-size: 0.7rem;
    }
  }