:root {
    --white: #ffffff;
    --black: #000000;
    --transparent: transparent;
    --color-lffu6uvb: #603F8B;
    --color-lffudicf: #F43AB0;
    --color-lffy4ue2: #9C9C9C;
    --primary-btn-clr: #1a385c;
}

.button_container {
    display: flex;
    margin-bottom: 70px;
}

.button_container>button {
    background-color: var(--primary-btn-clr);
    color: var(--white);
    padding: 15px 40px;
    font-size: 16px;
    border-radius: 5px;
    margin-top: 10px;
    font-weight: 700;
    margin-left: 20px;
}

.section4>div>.p1 {
    font-size: 42px;
    color: var(--color-lffu6uvb);
    margin-top: 60px;
    margin-left: 20px;
}

.section4>div>.p2 {
    font-size: 14px;
    color: var(--color-lffy4ue2);
    margin-top: 30px;
    margin-left: 20px;
}

.section4>div>.p3 {
    font-size: 20px;
    font-weight: 400;
    margin-left: 20px;
    font-weight: 400;
}

.image_container {
    display: grid;
    grid-template-columns: 20% 20% 20% 20% 20%;
    margin-top: 10px;
}

.image_container>div {
    max-width: 100%;
    padding: 10px 30px;
}

.image_container>div>img {
    width: 100%;
    border-radius: 5px;
}

.image_container>div>p {
    text-align: center;
    font-size: 18px;
    font-weight: 700;
    margin-top: 10px;
}

@media only screen and (min-width: 768px) {
    .section4>div>.p1 {
        font-family: var(--headlinefont);
    }
}

@media only screen and (max-width: 768px) {

    .image_container {
        display: grid;
        grid-template-columns: 100%;
        margin-top: 10px;
    }

    .image_container>div {
        max-width: 270px;
        padding: 10px 30px;
        margin: 0 auto;
    }

    .section4>div>.p1 {
        line-height: 40px;
        text-align: center;
        margin: auto 10px;
        margin-top: 35px;

    }

    .section4>div>.p2 {
        text-align: center;
        margin: auto 10px;
        margin-top: 15px;

    }

    .section4>div>.p3 {
        text-align: center;
        margin: auto 10px;
    }

    .button_container {
        justify-content: center;
    }

}