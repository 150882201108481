/* .dhome_container{
    background-color:#F4F4F4; ;
} */

.dhero {
    display: flex;
    /* margin-bottom: 50px; */
}

.dhero_left {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #F4F4F4;
}

.dhero_left > div {
    max-width: 70%;
}

.dhero_left > div > div:nth-child(1) {
    font-size: 4rem;
    font-weight: 600;
}

.dhero_left > div > div:nth-child(2) {
    margin: 30px 0;
    font-size: 1.3rem;
    color: #333333;
    font-weight: 500;
}

.dhero_left > div > div:nth-child(3) {
    border: 1px solid #d3d3d3;
    border-radius: 30px;
    display: inline-block;
    padding: 10px 30px;
    font-size: 0.9rem;
}


.dhero_right {
    flex: 1;
    overflow: hidden;
}

.dhero_right > img {
    width: 900px;
    height: 780px;
}

.skeleton {
    position: relative;
    background-color: #e2e2e2;
}

.skeleton::after {
    display: block;
    content: "";
    position: absolute;
    inset: 0;
    transform: translateX(-100%);
    background: -webkit-gradient(linear, left top,
                right top, from(transparent), 
                color-stop(rgba(255, 255, 255, 0.2)),
                to(transparent));
                  
    background: linear-gradient(90deg, transparent,
            rgba(255, 255, 255, 0.2), transparent);

    animation: loading 0.8s infinite;
}

@keyframes loading {
    100% {
        transform: translateX(100%);
    }
}

.dsection {
    max-width: 1250px;
    margin: 70px auto;
    padding: 0 20px;
}

.dcurrent_events_container {
    background-color: #F4F4F4;
    padding: 30px 10px;
    margin: 70px 0;
}

.dcurrent_events {
    margin: 0 auto !important;
}

.dcurrent_events > h3 {
    font-weight: 600;
    font-size: 42px;
    color: var(--color-lffu6uvb);
    margin-bottom: 50px;
}

.dcurrent_cards {
    display: flex;
    gap: 30px;
    flex-wrap: nowrap;
    overflow-x: auto;
    padding-bottom: 13px;
}

.dcurrent_cards::-webkit-scrollbar {
    display: none;
}

.dcurrent_card {
    flex-shrink: 0;
    width: 240px;
    height: 500px;
    /* height: 100%; */
}

.dcurrent_card_img {
    height: 75%;
    width: 100%;
}

.dcurrent_card_img > img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.dcurrent_card_content {
    height: 25%;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: center;
}

.dcurrent_card_about {
    /* padding: 10px; */
    padding: 1px 1px;
    font-size: 0.9rem;
    text-align: center;
}

.dcurrent_card_btn {
    background-color: var(--primary-btn-clr);
    color: var(--white);
    padding: 10px 50px;
    border-radius: 30px;
    cursor: pointer;
}

.dup_for > h3 {
    font-weight: 600;
    margin-bottom: 30px;
    text-align: center;
    font-size: 2rem;
}

.dup_for > div {
    display: flex;
    justify-content: center;
    cursor: pointer;
}

.dup_for > div > img {
    width: 100%;
    object-fit: cover;
}

.dcategories_more {
    text-align: center;
    margin-top: 40px;
}

.dcategories_more > div {
    border: 1px solid #d3d3d3;
    padding: 10px 30px;
    display: inline-block;
    border-radius: 30px;
    cursor: pointer;
}

.dbrowse_cards {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    row-gap: 60px;
    column-gap: 20px;
}

.dhome_learnmore {
    margin-bottom: 30px !important;
}

.dhome_learnmore:hover {
    cursor: pointer;
    text-decoration: underline;
}

@media screen and (max-width: 1100px) {
    .dhero_left > div > div:nth-child(1) {
        font-size: 3.2rem;
    }
    .dhero_left > div > div:nth-child(2) {
        font-size: 1.1rem;
    }
}

@media screen and (max-width: 925px) {
    .dhero_left > div > div:nth-child(1) {
        font-size: 2.9rem;
    }
    .dhero_left > div > div:nth-child(2) {
        font-size: 1rem;
    }
    .dbrowse_cards {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media screen and (max-width:768px) {
    .dhero {
         /* margin-top: 14px; */
         padding-top: 14px;
         flex-direction: column;
         gap: 11px;
    }
}
/* mobile */

.label_container{
    display: flex;
    justify-content: center;
    align-items: end;
    
}
.label_container button{
    position: absolute;
    text-align: center;
    color: white;
    font-size: 14px;
    background-color: #1a385c;
    margin-bottom: 15px;
    border-radius: 5px;
    font-weight: 500;
    padding: 10px;
}

.featured_dcurrent_cards {
    display: flex;
    gap: 30px;
    flex-wrap: nowrap;
    overflow-x: auto;
    padding-bottom: 13px; 
}

.featured_dcurrent_cards::-webkit-scrollbar {
    display: none;
}

.featured_h3 {
    font-weight: 600;
    margin-bottom: 50px;
}

.featured_card{
    min-width: 320px;
    max-width: 320px;
}

.featured_card_mobile{
    min-width: 100%;
    max-width: 100%;
}