:root {
  --primary-backgroundcolor: var(--pure-white);
}

.faq_main {
  padding: 15px 25px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 150px;
}

.overflow-hidden {
  overflow: hidden;
}

.faq_block>h4 {
  margin: 25px 0;
  font-weight: 800;
}

.faq_block>ol {
  padding: 0 15px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-weight: 300;
}

.faq_mini_wraper>h5 {
  margin-bottom: 15px;
}

.about-container {
  height: fit-content;
  background-color: var(--primary-backgroundcolor);
  padding: 0 80px;
  padding-top: 155px;
  /* color: rgba(252, 51, 0, 1); */
  /* font-family: "Instrument Sans"; */
}

.ab-heading {
  font-size: 42px;
  font-weight: 400;
  line-height: 52px;
  text-align: justify;
  margin-bottom: 25px;
}

.ab-sub-heading {
  font-size: 32px;
  font-weight: 400;
  line-height: 35px;
}

.ab-common-style {
  font-size: 18px;
  font-weight: 400;
  margin: 20px 0;
  line-height: 24px;
}

.team-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  row-gap: 30px;
  column-gap: 20px;
}

.about-container .profile {
  width: 140px;
}

.about-container .profile img {

  margin-bottom: 15px;
  width: 100%;
  height: 140px;
  border-radius: 20px;
  object-fit: cover;
  object-position: top;
}

.about-container .profile .description p {
  margin-bottom: 0;
}

.about-container .profile .description .name {
  font-size: 27px;
  font-weight: 700;
  font-family: "alternategotno1dregular";
  letter-spacing: 0.5px;
  line-height: 27px;
}

.about-container .profile .description .designation {
  font-size: 12px;
  font-weight: 400;
  color: #8f8f8f;
}

@media screen and (min-width: 768px) {
  .support_main {
    width: 85vw;
    margin: 0 auto;
  }

  .dabout_image_container {
    width: 100%;
    height: 405px;
  }

  .dabout_image_container>img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .dabout_part {
    font-weight: 600;
  }

  .about-container {
    padding-top: 170px;
  }

  .ab-heading {
    font-size: 70px;
    font-style: normal;
    line-height: 75px;
    /* letter-spacing: 1.4px; */
    margin-bottom: 33px;
  }
}

@media screen and (min-width:980px) {
  .about-container .profile {
    width: 190px;
  }

  .about-container .profile img {
    height: 190px;
    width: 100%;
  }
}

:root {
  --primary-backgroundcolor: var(--pure-white);
}

.faq_main {
  padding: 15px 25px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 150px;
}

.faq_block>h4 {
  margin: 25px 0;
  font-weight: 800;
}

.faq_block>ol {
  padding: 0 15px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-weight: 300;
}

.faq_mini_wraper>h5 {
  margin-bottom: 15px;
}

.about-container {
  height: fit-content;
  background-color: var(--primary-backgroundcolor);
  padding-top: 155px;
  /* color: rgba(252, 51, 0, 1); */
  /* font-family: "Instrument Sans"; */
}

.ab-heading {
  font-size: 42px;
  text-align: center;
  margin-bottom: 25px;
  font-weight: bold;
}

.ab-sub-heading {
  font-size: 32px;
  font-weight: 400;
  line-height: 35px;
}

.ab-common-style {
  font-size: 18px;
  font-weight: 400;
  margin: 20px 0;
  line-height: 24px;
}

.our-team-sec {
  padding: 80px;
}

.our-team-sec .ab-heading {
  margin-bottom: 50px;
  font-family: "alternategotno1dregular";
  text-transform: uppercase;
  color: #ff0000;
}

.team-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  row-gap: 30px;
  column-gap: 50px;
}

.about-container .profile {
  width: 180px;
  text-align: center;
  color: var(--black);
}

.about-container .profile img {
  margin-bottom: 15px;
  width: 100%;
  height: 140px;
  border-radius: 20px;
  object-fit: cover;
  object-position: top;
}

.about-container .profile .description p {
  margin-bottom: 0;
  text-align: center;
}

.about-banner {
  position: relative;
}

.about-banner .subheading {
  font-size: 50px;
  display: block;
  margin: 0 0 15px;
  font-family: "alternategotno1dregular";
}

.about-banner h1 {
  font-family: "alternategotno1dregular";
  font-size: 100px;
  color: var(--black);
  font-weight: bold;
  margin: 0 0 15px;
  line-height: 100px;
  letter-spacing: 1.3px;
}

.about-banner p {
  /* width: 45%; */
  font-size: 20px;
  color: var(--black);
  margin-bottom: 20px !important;
}

.about-banner h3 {
  margin: 0 0 30px;
  font-family: "alternategotno1dregular";
  font-size: 80px;
  font-weight: bold;
}

.counter-section {
  background-color: var(--black);
  color: var(--pure-white);
  padding: 40px 80px;
  text-align: center;
}

.counter-section .box-holder {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 50px;
  flex-wrap: wrap;
  gap: 15px;
}

.counter-section .txt-holder {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 5px;
}

.counter-section strong {
  font-size: 96px;
  line-height: 1.3;
  font-family: "alternategotno1dregular";
  color: #ff0000;
}

.counter-section span {
  color: var(--red-color);
  font-size: 18px;
  text-transform: uppercase;
}

.two-columns {
  padding: 80px;
}

.two-columns h2 {
  font-size: 85px;
  font-weight: bold;
  color: var(--black);
  margin: 0 0 30px;
  font-family: "alternategotno1dregular";
}

.two-columns p {
  width: auto;
  margin: 0 0 20px !important;
}

.two-columns img {
  display: block;
  margin: 0 auto;
  width: 100%;
}

.our-value-sec {
  padding: 80px;
  background-color: var(--black);
  color: var(--pure-white);
}

.our-value-sec .head {
  text-align: center;
}

.our-value-sec .subheading {
  font-size: 38px;
  display: block;
  margin: 0 0 15px;
  color: var(--red-color);
  text-transform: uppercase;
  font-family: "alternategotno1dregular";
}

.our-value-sec h2 {
  font-size: 96px;
  display: block;
  margin: 0 0 30px;
  font-weight: bold;
  font-family: "alternategotno1dregular";
}

.our-value-sec .item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  max-width: 440px;
  margin: auto; 
}

.our-value-sec .item span {
  color: var(--red-color);
  font-size: 20px;
}

.our-value-sec .item .title {
  font-size: 30px;
  font-family: "alternategotno1dregular";
  color: #ff0000;
}

.our-value-sec .item p {
  font-size: 20px;
  text-align: center;
}

.our-mission-sec {
  text-align: center;
  max-width: 1020px;
  margin: 0 auto;
  padding: 0 15px;
}

.our-mission-sec strong {
  display: block;
  margin: 0 0 20px;
  font-size: 62px;
  font-family: 'alternategotno1dregular';
  line-height: 60px;
}

.our-mission-sec .title {
  color: var(--black);
}

.our-mission-sec .auther-name {
  font-size: 30px;
  color: #ff0000;
}

.margin-auto {
  margin: auto !important;
}

.hero-section {
  max-width: 445px;
}

@media screen and (min-width: 768px) {
  .support_main {
    width: 85vw;
    margin: 0 auto;
  }

  .dabout_image_container {
    width: 100%;
    height: 405px;
  }

  .dabout_image_container>img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .dabout_part {
    font-weight: 600;
  }

  .ab-heading {
    font-size: 70px;
    font-style: normal;
    line-height: 75px;
    /* letter-spacing: 1.4px; */
    margin-bottom: 33px;
  }

  .about-banner h1 {
    font-size: 60px;
    line-height: 60px;
  }

  .heart-img {
    max-width: 520px;
    margin: auto;
  }
}

@media screen and (min-width: 980px) {
  .about-container .profile {
    width: 210px;
  }

  .about-container .profile img {
    height: 190px;
    width: 100%;
  }

  .about-img img {
    max-width: 258px;
  }
}

@media only screen and (min-width: 991px) {
  .about-banner .img-holder {
    max-width: 600px;
    float: right;
  }

  .about-img img {
    max-width: 100%;
  }

  .counter-section .box-holder {
    justify-content: space-between;
  }

  .about-banner p {
    font-size: 18px;
  }

  .about-banner h1 {
    font-size: 80px;
    line-height: 1;
  }
}

@media (max-width: 768px) {
  .about-container {
    padding: 71px 16px 16px;
  }

  .about-banner {
    padding: 25px 0 0 0;
    position: relative;
  }

  .about-banner h1 {
    font-size: 7vw;
    text-align: left;
    margin-bottom: 4px;
    line-height: 7vw;
  }

  .our-team-sec,
  .our-value-sec {
    padding: 25px;
  }

  .about-banner p {
    width: 100%;
    text-align: left;
    font-size: 3vw;
    margin-bottom: 10px !important;
  }

  .about-banner .subheading {
    text-align: left;
    /* font-size: 25px; */
    font-size: 7vw;
    margin: 0;
  }

  .counter-section .box-holder {
    padding: 0;
  }

  .counter-section strong {
    font-size: 40px;
  }

  .our-value-sec h2 {
    font-size: 40px;
  }

  .our-value-sec .item p {
    font-size: 12px;
  }

  .counter-section {
    padding: 30px;
  }
  
}

@media (max-width: 899px) {
  .our-value-sec .item {
    flex-direction: row;
  }

  .title {
    text-align: right;
    min-width: 44%;
  }

  .our-value-sec .item p {
    text-align: left;
    min-width: 56%;
    text-align: left;
  }

  .our-mission-sec .title {
    text-align: center;
    font-size: 30px;
    line-height: 35px;
  }

  .two-columns h2 {
    text-align: center;
    font-size: 10vw;
    padding-right: 100px;
    text-align: left;
  }

  .two-columns {
    padding: 40px 16px 16px;
  }

  .heart-img {
    position: absolute;
    top: 10%;
    right: 6%;
    max-width: 100px;
  }

  .our-value-sec .subheading {
    font-size: 24px;
  }

  .about-banner h3 {
    max-width: 190px;
    margin: 0 0 30px;
    font-family: "alternategotno1dregular";
    font-size: 27px;
    font-weight: bold;
  }
}