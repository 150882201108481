.address-main h1 {
    font-weight: bold;
    font-family: "alternategotno1dregular";
    color: #000;
    margin-bottom: 10px;
    font-size: 2.5rem;
    letter-spacing: 0.5px;
}

.address-information-container {
    padding: 30px !important;
    background-color: white;
    border-radius: 30px;
    width: 100%;
    padding: 15px 15px;
    overflow-x: hidden;

}

.address-info_sub_heading {
    /* font-family: "AvenirNext"; */
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 10px;
}

.address-info_half_inp {
    display: flex;
    justify-content: space-between;
    gap: 20px;
}

.address-info_half_inp input {
    width: 48%;
}

.address-information-container input[type="text"],
input[type="number"],
.select-input,
.address-info-phone input {
    height: 36px;
    padding: 12px;
    margin-bottom: 20px;
    /* border: 1px solid rgba(0, 0, 0, 0.23); */
    border: none;
    border-radius: 5px;
    background-color: #F4F4F4;
    width: 100%;
    font-size: 14px;
}

@media (max-width: 768px) {
    .address-info_half_inp {
        flex-direction: column;
    }
}