.MuiTabs-flexContainer {
    justify-content: space-around;
}

@media screen and (min-width: 768px) {
    .search-page {
        /* width: 70vw; */
        margin: 0 auto;
        width: auto;
        padding-top: 190px;
    }
}

.pagination_container {
    margin-top: 80px;
     width: 100%;
     display: flex;
     justify-content: space-between;
     align-items: center;
 }

 .auction_grid_container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    column-gap: 29px;
    row-gap: 50px;
}

@media screen and (max-width: 1100px) {
    .auction_grid_container {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
}

@media screen and (max-width:880px) {
    .auction_grid_container {
        grid-template-columns: 1fr 1fr 1fr;
    }
}

@media screen and (max-width:768px) {
    .auction_grid_container {
        display: grid !important;
    }
}

@media screen and (max-width:630px) {
    .auction_grid_container {
        grid-template-columns: 1fr 1fr !important;
    }
}