.content_wrapper {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 40px 25px;
}

.content_text {
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 35px;
    letter-spacing: -0.64px;
}

@media screen and (min-width:768px) {
    .content_text {
        font-size: 42px;
        line-height: 47px;
        letter-spacing: 0.84px;
    }
    .content_wrapper {
        gap: 2rem;
        padding: 87px;
    }
}