.map-section {
  background-color: #eff0f3;
  padding: 80px;
}

.map-section h2 {
  font-size: 64px;
  font-weight: bold;
  font-family: "alternategotno1dregular";
  line-height: 72px;
  letter-spacing: 0.5px;
}

.map-section p {
  font-size: 28px;
  padding: 5px;
  margin-top: 22px;
}

.map-section .img-holder img {
  float: right;
}

.three-cards {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  margin: 20px 0 0;
}

.three-cards .c-card {
  background-color: var(--pure-white);
  border-radius: 12px;
  padding: 15px;
  min-width: 120px;
  min-height: 120px;
}

.three-cards .s-icon {
  margin-bottom: 15px;
  color: var(--red-color);
}

.map-section .txt-holder {
  max-width: 500px;
}

@media (max-width: 767px) {
  .map-section .img-holder img {
    float: none;
  }

  .map-section .img-holder {
    text-align: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .map-section h2 {
    font-size: 60px;
    font-weight: bold;
    font-family: "alternategotno1dregular";
    line-height: 60px;
    letter-spacing: 0.5px;
  }

  .map-section {
    padding: 35px;
  }

  .three-cards {
    flex-wrap: nowrap;
    gap: 5px;
  }

  .three-cards .c-card {
    padding: 5px;
    min-width: 80px;
    min-height: 80px;
  }

  .c-card strong {
    font-size: 15px;
  }
}

.img-holder {
  max-width: 600px;
}


@media only screen and (min-width: 768px) {
  .map-section .img-holder {
    float: right;
  }
}