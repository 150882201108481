.bannerContentContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.bannerHeading {
  font-size: 60px;
  font-weight: bold;
  line-height: 61.6px;
  color: var(--pure-white);
  font-family: "alternategotno1dregular";
}

.shop_now_banner {
  background-color: red;
  min-height: 350px;
}

.shop_now_banner img {
  min-height: 350px;
}

.bannerPara {
  font-size: 20px;
  font-weight: 400;
  line-height: 32px;
  color: var(--pure-white);
}

.paraSection {
  padding: 40px;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
  height: 100%;
}

.headerContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.searchbarFilter {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.filtersSection {
  display: none;
  justify-content: space-between;
  align-items: center;
  gap: 24px;
  position: relative;
}

.filters,
.searchAndSortContainer {
  display: flex;
  gap: 24px;
  align-items: center;
}

.filterButtons {
  overflow-x: auto;
}

.filters {
  width: 60%;
}

.filterHeading {
  color: var(--black-color) !important;
  min-width: fit-content;
  margin: 0 0 25px;
}

.searchAndSortContainer {
  justify-content: flex-end;
  width: fit-content;
  flex-direction: row-reverse;
}

.sortButton {
  padding: 12px 16px;
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  font-size: 14px;
  font-weight: 700;
  line-height: 17.08px;
  border: 0.6px solid var(--line-color);
  color: var(--black-color);
}

.sortIcon {
  transform: rotate(90deg);
}

.filtersOutputSection {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
  row-gap: 24px;
}

.filterdOutputContainer {
  display: flex;
  align-items: center;
  gap: 24px;
}

.filterdOutputs {
  max-width: 60vw;
  overflow-x: auto;
  display: flex;
  gap: 24px;
  align-items: center;
}

.outPut {
  padding: 12px 16px;
  border-radius: 25px;
  font-size: 14px;
  font-weight: 700;
  line-height: 17.08px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  width: 100%;
  background-color: var(--off-white);
}

.outSpan {
  width: max-content;
  text-transform: uppercase;
}

.clearSingleIcon {
  cursor: pointer;
}

.clearAllButton {
  border: none;
  background-color: transparent;
  font-size: 14px;
  font-weight: 700;
  line-height: 17.08px;
}

.clearAllButton > span {
  display: flex;
  width: max-content;
  color: var(--black-color);
}

.itemNumberPara {
  font-size: 16px;
  line-height: 25.6px;
  font-weight: 400;
  color: var(--off-black);
}

.line {
  width: 100%;
  color: var(--line-color);
}

.productsSection {
  display: flex;
  flex-direction: column;
  /* gap: 80px; */
  gap: 70px;
  justify-content: center;
  margin-top: 70px;
}

.productsGridContainer {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  row-gap: 70px;
  column-gap: 14px;
  padding: 0 15px;
}

.shopNowPagination {
  margin-top: 0;
  padding-bottom: 0;
}

.progressContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 500px;
}

.noProductsContainer {
  position: absolute;
  left: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 300px;
}

.noProductsText {
  color: var(--black-color);
  font-weight: 700;
}

.productSectionLine {
  color: var(--line-color);
  margin-inline: 0 !important;
  width: 100%;
}

.rightArrow {
  display: none;
}

.mobile_view {
  display: none;
}

.Secondline {
  display: none;
}

.d_mobile_none {
  color: black;
  font-size: 18px;
}

@media screen and (min-width: 1151px) {
  .searchBar {
    width: 400px;
  }
}

@media screen and (max-width: 1440px) {
  .productsGridContainer {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media screen and (max-width: 1150px) {
  .productsGridContainer {
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (max-width: 950px) {
  .filterHeading {
    font-size: 38px !important;
    line-height: 26px !important;
  }
  .sortButton {
    font-size: 14px;
    padding: 7px 14px;
    gap: 2px;
  }
  .sortIcon {
    font-size: 20px !important;
  }

  .sx-none {
    display: none;
  }
}

@media screen and (max-width: 880px) {
  .filters {
    width: auto;
  }
  .searchBar {
    display: none;
  }
  .searchBarMobile {
    display: block;
    /* margin-top: 13px; */
    margin-bottom: 0px;
  }

  .searchbarFilter {
    flex-direction: column;
  }

  .mobile_view {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    top: 0;
  }

  .Secondline {
    display: block;
    padding-bottom: 20px;
    margin: 0;
    width: 100%;
    color: var(--line-color);
    margin-bottom: 10px;
  }

  .line {
    margin-bottom: 5px;
    margin-top: 0;
  }
  .mobile_view h1 {
    font-weight: bold;
    font-family: "alternategotno1dregular";
    font-size: 30px;
    color: #000;
  }

  .d_mobile_none {
    display: none;
  }

  .page_header ul > .d_dropdown {
    /* left: 0; */
    right: -17px;
    min-width: 100px;
  }

  .searchbarFilter {
    flex-direction: row-reverse;
    align-items: center;
  }
}

@media screen and (max-width: 768px) {
  .productsSection {
    margin-top: 30px;
  }
  .filtersSection {
    gap: 24px;
    /* display: none; */
  }
  /* .sortButton {
        width: 100%;
        flex-direction: row-reverse;
        justify-content: space-between;
        gap: 24px;
    } */
  .filterdOutputContainer {
    flex-wrap: wrap;
  }
  .filterdOutputs {
    overflow-x: hidden;
    flex-wrap: wrap;
    max-width: 100%;
  }
  .outPut {
    width: auto;
  }
  .outSpan {
    width: auto;
  }
}

@media screen and (max-width: 680px) {
  .filters,
  .filterButtons {
    gap: 9px;
  }
}

@media screen and (max-width: 660px) {
  .productsGridContainer {
    grid-template-columns: 1fr;
  }
}

@media screen and (max-width: 610px) {
  .filtersSection {
    gap: 10px;
  }
  .filters {
    width: 77%;
  }
  .filterHeading {
    font-size: 24px !important;
    line-height: 22px !important;
    min-width: 63px;
  }
  .sortButton {
    font-size: 11px;
    padding: 3px 10px;
    gap: 1px;
  }
  .sortIcon {
    font-size: 15px !important;
  }
}

@media screen and (max-width: 540px) {
  .bannerHeading {
    font-size: 36px;
    line-height: 39.6px;
  }
}

@media screen and (max-width: 490px) {
  .filterButtons {
    overflow-x: auto;
  }
  .filters,
  .filterButtons {
    gap: 4px;
  }
}

.custom-space {
  margin-left: 10px;
}

.page_header {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  position: relative;
  /* margin: 0 0 50px; */
}

/* .page_header:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: -9999px;
    right: -9999px;
    height: 1px;
    background-color: #f1f1f1;
  } */

.page_header ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

.page_header ul > li .d_dropdown {
  min-width: 140px;
}

.page_header ul > li:last-child .d_dropdown a {
  text-align: left;
}

.custom_dropdown {
  position: relative;
  margin-bottom: 0 !important;
}

.custom_dropdown .d_dropdown {
  border: 3px solid #f1f1f1;
  border-radius: 30px;
  padding: 0;
  display: none;
  min-width: 230px;
  position: absolute;
  top: 100%;
  right: 0;
  background-color: #fff;
  z-index: 99;
}

.custom_dropdown:hover .d_dropdown {
  display: block;
}

.custom_dropdown .d_dropdown ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: block;
}

.custom_dropdown .d_dropdown ul li {
  display: block;
}

.custom_dropdown .d_dropdown ul li a {
  display: block;
  padding: 6px 15px;
  text-align: center;
}

.custom_dropdown .d_dropdown ul li a:hover {
  background-color: #f1f1f1;
}

.color_palete {
  display: flex !important;
  gap: 10px;
  flex-wrap: wrap;
  padding: 20px !important;
}

.color_palete li {
  width: 23%;
}

.color_palete a {
  width: 30px;
  height: 30px;
  border-radius: 100%;
  margin: 0 auto;
  display: block !important;
  padding: 0 !important;
}

.color_palete a.white {
  border: 2px solid #000;
}

.color_palete .grey {
  background-color: #8f8f8f;
}
.color_palete .pink {
  background-color: #c85287;
}
.color_palete .orange {
  background-color: #c2127f;
}
.color_palete .red {
  background-color: #9d0000;
}
.color_palete .brown {
  background-color: #793f10;
}
.color_palete .yellow {
  background-color: #f4d66e;
}
.color_palete .purple {
  background-color: #4f1769;
}
.color_palete .blue {
  background-color: #28508d;
}
.color_palete .green {
  background-color: #254b2f;
}
.color_palete .black {
  background-color: #000000;
}
.color_palete .white {
  background-color: #ffffff;
}

.shop_now_filter_btn .filterButtons {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  border: none;
}

.shop_now_filter_btn button {
  border-radius: 0 !important;
  color: black !important;
  border-bottom: 1px solid rgb(134, 133, 133);
  display: flex !important;
  justify-content: space-between !important;
  padding: 40px 0 !important;
  width: 100% !important;
  border-top: 0 !important;
  border-left: 0 !important;
  border-right: 0 !important;
}

@media only screen and (max-width: 899px) {
  .noProductsContainer {
    position: static;
  }
}