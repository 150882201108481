.bannerContainer {
    width: 100%;
    height: 320px;
    position: relative;
}

.bannerImageContainer {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}

.bannerImageContainer > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

@media screen and (max-width:768px) {
    .bannerContainer {
        height: 360px;
    }
}