:root {
    --primary-white-clr: #F0E8E3;
    --primary-red-clr: #FC3300;
}

.view_blog_section {
    background-color: var(--primary-white-clr);
}

.container{
    /* width:100%; */
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 40px;
    /* margin-top:40px ; */
    padding: 5vw;
    padding-top: 210px;
}

.container>h1{
    text-align: center;
    font-weight: 700;
    padding-inline: 19px;
}

.subtitle{
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
    gap: 20px;
    flex-direction: column-reverse;
}
.subtitle>h3{
    font-weight: 700;
}

.subtitle>div {
    font-size: 14px;
    color: #818181;
    width: 100%;
    text-align: end;
}

/* .image_wrapper{
    width: 100%;
    height: auto;
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
} */

.image_wrapper{
    width: auto;
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.image_wrapper>img{
    /* width: 70%; */
    /* width: 100%; */
    height: 100%;
    object-fit: cover;
}

.content_wrapper{
    display: flex;
    justify-content: center;
    flex-direction: column;
    /* padding-inline: 127px; */
    gap: 20px;
}

.content_wrapper>p{
    text-align: justify;
}

.htmlcontent>p{
    text-align: justify;
}

@media screen and (max-width:768px) {
    .image_wrapper{
        height: auto;
    }
    .image_wrapper>img{
        width: 100%;
    }
    .content_wrapper{
        /* padding-inline: 50px; */
    }
    .subtitle>h3{
        text-align: center;
    }
    
}