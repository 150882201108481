.capture-card-details {
  padding-top: 170px;
}

.capture-card-details__section__heading {
  font-weight: 800;
  font-size: 21px;
  margin-bottom: 24px;
}

.capture-card-details input {
  width: 100%;
  display: flex;
  padding: 14px 20px 14px 10px;
  align-items: center;
  gap: 10px;
  border-radius: 8px !important;
  margin-bottom: 23px;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  font-family: 'Instrument Sans';
}

.capture-card-details input[type=number] {
  height: 48px;
  padding: 12px;
  margin-bottom: 24px;
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 4px;
  background-color: rgb(240, 232, 227);
}

.capture-card-details input:hover {
  border-color: black;
}

.capture-card-details section {
  display: flex;
  flex-direction: column;
}

#billing-address {
  margin-bottom: 36px;
}

.card-secure-info {
  display: flex;
  justify-content: space-between;
  column-gap: 20px;
}

.card-secure-info__input {
  width: 100%; 
  height: 40px; 
  padding: 10px; 
  font-size: 16px; 
  border: 1px solid #ccc; 
  border-radius: 4px;
  background-color: white; 
  color: #333;
  outline: none;
  transition: border 0.3s ease-in-out;
}

.card-secure-info__input::placeholder {
  color: #aaa;
  font-size: 14px;
}

.card-secure-info__input:focus {
  border: 1px solid #007bff;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.card-secure-info__input:invalid {
  border: 1px solid red;
}

@media (max-width: 768px) {
  .card-secure-info__input {
    font-size: 14px;
    height: 35px;
  }

  .card-icons-container {
    flex-direction: column;
  }
}

.card-secure-info .country-selector {
  flex: 1;
}

.card-secure-info .country-selector__value-container {
  position: initial;
}

.card-icons-container {
  display: flex;
  gap: 18px;
  margin-bottom: 15px;
  align-items: center;
}

.card-icons>img {
  width: 60px;
  height: 60px;
}

.capture-card-details input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.input_label {
  margin-bottom: 10px;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.36px;
}

#demo-simple-select{
  font-size: 12px;
  font-family: 'Instrument Sans';
}

.visa_container {
  border-radius: 25px;
  background: white;
  padding: 7px 10px;
}

.visa_img {
  height: 16px !important;
  width: auto !important;
}

.master_container {
  padding: 3px 10px;
  background: white;
  border-radius: 25px;
}

.master_card_img {
    width: auto !important;
    height: 32px !important;
    object-fit: cover;
}

.amex_container {
    padding: 6px 13px;
    background: white;
    border-radius: 25px;
}

.amex_img {
  object-fit: cover;
  height: 26px !important;
  width: 52px !important;
  background-color: transparent;
  border-radius: 3px;
}

@media screen and (max-width:768px) {
  .capture-card-details {
    padding-top: 135px;
    padding-inline: 20px;
  }

  .capture-card-details__section__heading {
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 25px;
  }

  .capture-card-details input{
    display: flex;
    padding: 14px 20px 14px 10px;
    align-items: center;
    gap: 10px;
    border-radius: 8px !important;
    margin-bottom: 23px;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    font-family: 'Instrument Sans';
  }

}

.field-input {
  width: 100%;
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 8px 12px;
  border-radius: 8px;
  color: black;
  background: '#fff';
  border: 1px solid grey;
  /* box-shadow: 0 2px 2px grey; */
}










.payment-container {
  padding: 40px 0 60px;
  overflow: auto;
  max-height: 82vh;
}

.payment-container h1 {
  font-weight: bold;
  font-family: "alternategotno1dregular";
  color: #000;
  margin-bottom: 10px;
  font-size: 2.5rem;
  letter-spacing: 0.5px;
}

.container-payment-show {
  max-width: 363.573px;
  /* height: 245.036px; */
  height: 255px;
  padding: 20px;
  border: 1px solid #e5e1e1;
  border-radius: 15px;
  background-color: #ffffff;
}

.container-payment-show .payment-card {
  display: flex;
  min-height: 159px;
  justify-content: space-between;
  align-items: center;
  padding: 20px 14px;
  border-radius: 12px;
  background-color: #FCFCFC;
  box-shadow: 0px 2px 2px 2px rgba(0, 0, 0, 0.25);
  border: none;
  width: 100%;
  color: #8f8f8f;
}

.container-payment-add {
  padding: 20px;
  border: 1px solid #8F8F8F;
  border-radius: 15px;
  background-color: #ffffff;
}

.add-payment-method {
  width: 100%;
  margin-top: 20px;
}

.save-payment-details-btn {
  border: 2px solid rgb(252, 51, 0);
  background-color: var(--pure-white);
  color: rgb(252, 51, 0);
  padding-block: 7px;
  margin-top: 10px;
  font-weight: 600;
  padding: 5px 21px;
  border-radius: 25px;
  &:hover {
    background-color: rgb(252, 51, 0);
    color: var(--pure-white);
  }
}

.bag_shipping_edit {
  color: #000;
  font-weight: 600;
  cursor: pointer;
}