.button {
  font-size: 18px;
  font-weight: 600;
  padding: 5px 21px;
  border-radius: 25px;
  /* border: 2px solid #FC3300; */
  width: fit-content;
  font-style: normal;
  /* letter-spacing: 0.98px; */
  letter-spacing: 0px;
  /* text-transform: uppercase; */
}

.buttonIcon {
  font-size: 40px;
}

.browse_sort_by_class {
  border-color: var(--black-color) !important;
  color: var(--black-color) !important;
  text-transform: uppercase !important;
  min-width: max-content;
  padding: 5px 15px;
  display: flex;
  align-items: center;
  gap: 4px;
}

.browse_shop_now_class {
  border: 6px solid var(--line-color);
  ;
  color: var(--black-color) !important;
  text-transform: uppercase !important;
  min-width: fit-content;
  padding: 5px 15px;
  display: flex;
  align-items: center;
  gap: 4px;
}

.favouriteButtons {
  font-family: 'Instrument Sans';
  font-size: 14px;
  /* padding: 4px 11px; */
}

.formSubmit {
  padding-block: 7px !important;
  width: 40% !important;
  margin-top: 10px !important;
  color: var(--red-color) !important;
  background-color: var(--white-color) !important;
  border: 1px solid var(--red-color) !important;
}

.carouselCardButton {
  background-color: var(--red-color) !important;
  color: var(--white-color) !important;
  font-size: 14px !important;
  line-height: 17.08px !important;
  padding: 16px 24px !important;
}

.subscribeButton,
.headerShopNow {
  color: var(--white-color) !important;
  background-color: #ff0000 !important;
  font-size: 14px !important;
  line-height: 17.08px !important;
  padding: 16px 24px !important;
}

.homeBanner {
  color: var(--white-color) !important;
  background-color: var(--red-color) !important;
  border: 0 !important;
  font-size: 14px !important;
  line-height: 17.08px !important;
  padding: 16px 24px !important;
  width: 40%;
}

.sortScreenSubmit {
  background-color: var(--black-color) !important;
  width: 100% !important;
  font-size: 14px !important;
  line-height: 17.08px !important;
  color: var(--pure-white) !important;
  padding: 20px 24px;
}

@media screen and (max-width:950px) {

  .browse_shop_now_class,
  .browse_sort_by_class {
    font-size: 14px !important;
    padding: 5px 12px !important;
    gap: 2px !important;
  }

  .buttonIcon {
    font-size: 20px;
  }
}

@media screen and (max-width:900px) {
  .headerShopNow {
    padding: 8px 15px !important;
    font-size: 10px !important;
  }
}

@media screen and (max-width:768px) {
  .formSubmit {
    width: 100% !important;
  }

  .homeBanner {
    width: 50%;
  }
}

@media screen and (max-width:610px) {

  .browse_shop_now_class,
  .browse_sort_by_class {
    font-size: 13px !important;
    padding: 3px 10px !important;
    gap: 0px !important;
  }

  .buttonIcon {
    font-size: 15px !important;
  }
}

@media screen and (max-width:540px) {
  .carouselCardButton {
    width: 100%;
  }

  .subscribeButton {
    width: 100%;
  }
}

@media screen and (max-width:500px) {
  .favouriteButtons {
    width: 90%;
  }
}

@media screen and (max-width:312px) {
  .button {
    height: fit-content;
  }
}