
.section2{
    /* margin-bottom: 80px; */
}
.section2>.cards_container {
    max-width: 960px !important;
    margin: 60px auto;
    padding: 35px 20px;
    /* margin-top: -70px; */
    border-radius: 10px;
    box-shadow: 0 1px 5px #0003;
    background-color: var(--white);
}

.section2>.cards_container>h2 {
    /* font-size: 42px; */
    font-size: 36px;
    text-align: center;
    color: var(--color-lffu6uvb);
}

.section2>.cards_container>p {
    margin-top: 5px;
    text-align: center;
    color: var(--color-lffy4ue2);
}
.section2>.cards_container>p>span {
    color: var(--color-lffudicf);
}

.section2>.cards_container>.cards_inner_container {
    margin: 5px auto;
    width: 90%;
    display: flex;
    justify-content: space-around;
}

.section2>.cards_container>.cards_inner_container>div {
    background-color: #fbf6f2;
    padding: 20px;
    width: 30%;
    border-radius: 7px;
    text-align: center;
    margin: 20px 0;
}

.section2>.cards_container>.cards_inner_container>div>h3 {
    font-size: 19px;
    text-align: center;
    font-weight: 700;
    color: var(--color-lffu6uvb);
}

.section2>.cards_container>.cards_inner_container>div>img {
    max-width: 40%;
    margin: 16px auto;
}

.section2>.cards_container>.cards_inner_container>div>p {
    text-align: center;
}

.section2>.cards_container>div {
    display: flex;
    justify-content: center;
}

.section2>.cards_container>div button {
    background-color: var(--primary-btn-clr);
    color: var(--white);
    padding: 15px 40px;
    font-size: 16px;
    border-radius: 5px;
    font-weight: 700;
}

@media only screen and (max-width: 768px) {
    .section2{
        margin-top: 40px; 
    }
  
    .section2>.cards_container>.cards_inner_container {
        width: 100%;
        margin-top: 0px;
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    .section2>.cards_container>.cards_inner_container>div {
        width: 100%;
        text-align: center;
    }

    .section2>.cards_container>h2 {
        margin-top: 20px;
        /* font-size: 20px; */
        font-size: 30px;
    }

    .section2>.cards_container>p {
        margin-bottom: 0px;
    }

    .section2>.cards_container>.cards_inner_container>div>img {
        max-width: 20%;
        margin: 5px auto;
    }

    .section2>.cards_container {
        margin: 0 auto;
        padding: 20px 10px 40px;
        margin-top: -70px;
        border-radius: 0;
        box-shadow:none;
    }

    .section2>.cards_container>div button {
        font-size: 14px;
    }
}
@media only screen and (max-width: 426px) {
    .section2>.cards_container {
       
        margin-top:0px;
       
    }
}

.click_here:hover{
    cursor: pointer;
    text-decoration: underline;
}