.slider_sec .container .carousel .control-dots .dot {
    background: white !important;
}

.slider_sec .container .carousel .control-dots{
    margin-bottom: -30px !important;
}

.splash_image_container {
    height: 100vh;
    width: 100vw;
}

.splash_image_last {
    height: 100vh;
    width: 100vw;
}

.splash_image_container > img {
    width: 100% !important;
    height: 100% !important;
    object-fit: cover !important;
}

.splash_image_last > img {
    width: 100% !important;
    height: 100% !important;
    object-fit: cover !important;
}

.start_btn_container {
    position: absolute;
    top: 5%;
    right: 10%;
    color: #006883;
    z-index: 3;
    text-transform: uppercase
}

.hideSkip {
    color: black;
    font-weight: 600;
}

.showStart {
    color: black;
    font-weight: 600;
    font-size: 1rem;
    margin-right: 10px;
    line-height: 5px;
}