.categoryCard {
    display: flex;
    flex-direction: column;
    gap: 24px;
    max-width: 180px;
    align-items: flex-start;
    cursor: pointer;
}

.catImageContainer {
    height: 180px;
    width: 180px;
    scale: 100%;
    transition: scale 0.5s ease-out;
}
.catImageContainer :hover {
   scale: 97%;
   transition: scale 0.3s;

}

.catImageContainer > img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.categoryName {
    font-family: var(--test-founders-grotesk-x-condensed-bold);
    font-weight: 400;
    font-size: 32px;
    line-height: 24px;
    max-width: 100%;
    color: var(--black-color);
    text-transform: uppercase;
    cursor: pointer;
}

@media screen and (max-width:768px) {
    .categoryCard {
        max-width: 160px;
    }
    .catImageContainer {
        height: 160px;
        width: 160px;
    }
}

@media screen and (max-width:569px) {
    .categoryCard {
        margin-inline: auto;
    }
}

@media screen and (max-width:350px) {
    .categoryCard {
        max-width: 140px;
    }
    .catImageContainer {
        height: 140px;
        width: 140px;
    }
    .categoryName {
        font-size: 26px;
        line-height: 20px;
    }
}