
.ep_container{
    min-height:88vh ;
    background-color: var(--pure-white);
    padding-top: 150px;
    padding-bottom: 17px;

}
.ep_container>h5{
    text-align: left;
    padding:50px 25px 25px 0 ;
    font-weight: 700;
    font-size: 20px;
}

.bag_shipping_sec {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px 15px;
    border-radius: 12px;
    background: #FCFCFC;
    box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
    border: 0.5px solid rgb(229 231 235);
    margin-top: auto;
    width: 85%;
    margin-bottom: 30px;
    
}
.bag_shipping_content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    font-size: 18px;
    line-height: 24px;
    font-weight: 400;
    width: 50%;
    color:#30313A ;
}

.change_payment_btn{
    width: 100%;
    text-align: start;
}
@media screen and (min-width:768px) {
    .ep_container>div{
        width: 308px;
    }
}
@media screen and (max-width:768px) {


    .bag_shipping_sec {
        display: flex;
        min-height: 159px;
        padding: 20px 14px;
        align-items: center;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        border: none;
        margin-inline:auto ;
    }
    .ep_container>h5{
        padding:50px 25px 25px 25px ;
    }

    .bag_shipping_content {
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: -0.36px;
        gap: 10px;
    }
    .change_payment_btn{
        width: 85%;
        margin-inline: auto;
    }
    
.ep_container{
    padding-top: 130px;

}

}
.mh_100{
    min-height: 100vh;
}