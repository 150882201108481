.section_wrapper {
    display: flex;
    flex-direction: column;
    /* gap: 30px; */
}

.text_section > p {
    font-size: 20px;
    font-weight: 400;
}

.logo_section {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.logo_section > img {
    /* height: 67px; */
    object-fit: cover;
    width: 292px;
}

.btn_wrapper > button {
    padding: 5px 24px;
    border-radius: 20px;
}

.imgSubSection > img {
    margin-block: auto;   
}

.imgSubSection > .common_style {
    width:200px;
}

@media screen and (min-width:767px) {
    .text_section {
        margin-left: 22px;
    }

    .btn_wrapper {
        margin-left: 22px;
    }

    .section_wrapper {
        max-width: 1250px;
        margin: auto;
    }
}

@media screen and (min-width:480px) {
    .logo_section > img {
        width: 426px;
    }
}


/* styles for new desktop brands section */

.desktop_brands_container {
    padding-block: 118px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 54px;
}

.desktop_heading {
    width: 100%;
    text-align: center;
}

.desktop_logo_section {
    display: flex;
    flex-direction: column;
    gap: 30px;
    justify-content: center;
}

.desktop_logo_section > div {
    display: flex;
    gap: 56px;
    flex-wrap: wrap;
    justify-content: center;
}

@media screen and (min-width:805px) {
    .image1 {
       margin-right: 55px;
    }
}