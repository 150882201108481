.badge{
    position: absolute;
    border-radius: 50px !important;
    width: 20px;
    height: 20px;
    padding: 4px !important;
    background-color: #77257f;
    color: white;
    margin-left: 25px;
  }
  
  .icon {
    width: 20px;
    height: 20px;
}