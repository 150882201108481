.featured_container {
    display: flex;
    gap: 10px;
    flex-direction: column;
    margin-top: 40px;
    padding-block: 20px;
}

.featured_heading {
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 35px;
    letter-spacing: 0.64px;
}

.card_section_wrapper {
    width: 100%;
    overflow-x: auto;
    display: flex;
    gap: 45px;
}