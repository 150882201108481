
 .carousel .control-next.control-arrow {
    display: none;
  }

  .carousel-root .carousel .control-dots .dot {
    outline: none !important;
    background: white !important;
    box-shadow: none !important;
    width: 12px !important;
    height: 12px !important;
  }

section .carousel .control-dots {
    position: initial !important;
    bottom: -35px !important;
  }

  .carousel.carousel-slider {
    margin: 0;
    overflow: hidden;
    display: flex;
    flex-direction: column-reverse;
    gap: 14px;
  }

  .carousel img {
    height: auto !important;
    /* object-fit: cover; */
    border-radius: 15px;
    width: 96% !important;
}