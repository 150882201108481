.mb-lm-container{
    background-color: #30313A;
    font-family: "Instrument Sans";
    padding-top: 35px;
}
.mb-lm-container > .mb-ln-qs-container{
    width:80%;
    margin: auto !important;
    background-color: #F0E8E3;
    border-radius: 25px;
    padding: 25px;
    color:  #30313A;
}

.mb-ln-qs-container > h1{
color:  #30313A;
padding-bottom: 20px;
font-size: 32px;
font-style: normal;
font-weight: 400;
line-height: 35px; /* 109.375% */
letter-spacing: -0.64px;
border-bottom: 1px solid #30313A;
margin-bottom: 25px;
}

.mb-ln-qs-container > .mb-qs-container{
    padding-bottom: 25px;
    border-bottom: 1px solid #30313A;
    margin-bottom: 25px;
}

.mb-qs-container > .mb-question{
    display: flex;
    /* justify-content: center; */
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 10px;
}

.mb-question > h5{
width: 85%;
font-size: 20px;
font-style: normal;
font-weight: 700;
line-height: 25px; /* 125% */
/* letter-spacing: -0.4px; */
}

.mb-qs-container > .mb-ans-container > p{
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: 24px; /* 133.333% */
letter-spacing: -0.36px;
}

.mb-lm-btm{
    width: 80%;
    margin: auto;
    padding-top: 50px;
}

.mb-lm-btm {
 color: #FFF;
font-family: "Instrument Sans";
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: 24px;
letter-spacing: -0.36px;
padding-bottom: 50px;
}

.des-lm-outer-container{
    background-color: #F0E8E3;
    margin-top: 120px;
    font-family: "Instrument Sans";
    color: #30313A;
    padding-bottom: 50px;
}
.des-lm-container > h1{
    color: #30313A;
    font-size: 45px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: -1.4px;
    padding-top: 70px;
    padding-bottom: 30px;
}
.des-qs-container{
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid #30313A;
}

.des-qs-container > .des-question{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 10px;
}

.des-qs-container > .des-question > p{
font-size: 28px;
font-style: normal;
font-weight: 400;
line-height: 35px; /* 109.375% */
letter-spacing: -0.64px;
}

.des-ans-container > p {
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: 24px; /* 133.333% */
letter-spacing: -0.36px;
}

.des-lm-opp-container{
    margin-top: 35px;
    /* height: 500px; */
    width: 100%;
    background-color: #30313A;
    color: #F0E8E3;
    padding: 5% 8%;
    margin-bottom: 25px;
}

.des-lm-card-container{
    /* display: flex; */
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    column-gap: 25px;
}



.des-lm-opp-container > h2{
 font-size: 42px;
font-style: normal;
font-weight: 400;
line-height: 47px; /* 111.905% */
letter-spacing: -0.84px;
margin-bottom: 30px;
}

.des-lm-card-container > .opp-card{
   /* width:340px; */
   min-height: 400px;
    border-radius: 25px;
    background-color: #EFE7E2;
    padding: 4%;
    color: #30313A;
    display: flex;
    flex-direction: column;
    height: 100%;
}

.des-lm-card-container > .opp-card > h5{
    font-family: "DM Mono";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.36px;
    margin-bottom: 20px;
    padding-left: 15px;
    padding-top: 20px;
}

.list_button_wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 291px;
    align-items: center;
    height: 100%;
    padding-bottom: 20px;
}

.des-lm-card-container > .opp-card > .list_button_wrapper > .opp-card-list li{
 font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: 24px;
letter-spacing: -0.36px;
margin-bottom: 15px;
padding-left: 0px;
}
.des_lm_btm{
    margin-top: 25px;
}
.des_lm_btm > h2{
 color:  #30313A;
padding-bottom: 20px;
font-size: 32px;
font-style: normal;
font-weight: 400;
line-height: 35px; /* 109.375% */
letter-spacing: -0.64px;
margin-bottom: 25px;
}

.des_lm_btm > .des_lm_btm_brands{
    margin-top: 25px;
    margin-bottom: 25px;
    font-family: "DM Mono";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.36px;
}

.des_lm_btm_flex{
    display: flex;
    justify-content: space-between;
    column-gap: 25px;
}
.des_lm_btm_flex > .des_lm_btm_card{
    width: 48%;
}
.des_lm_btm_card  h5{
 font-size: 20px;
font-style: normal;
font-weight: 700;
line-height: 25px; /* 125% */
letter-spacing: -0.4px;
}

.des_lm_btm_card  p{
    margin-top: 15px;
    font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: 24px;
letter-spacing: -0.36px;
}
.des_lm_btm_card > .des_lm_btm_card_banner{
    width: 100%;
    height: 235px;
}
.des_lm_btm_card_banner > img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.impact_uparrow {
    transition: transform 0.8s;
    cursor: pointer;
}