.shopNowContainer {
	padding: 0 80px;
}

.productCardContainer {
	display: flex;
	flex-direction: column;
	gap: 8px;
	/* max-width: 350px; */
	align-items: flex-start;
	margin-inline: auto;
	cursor: pointer;
	/* transition: scale 0.3s ease-in-out; */
}

/* .productCardContainer:hover {
   scale: 98%;
   transition: scale .3s;
} */

/* .shopNowCardContainer {
    max-width: 290px;
} */

.imageContainer {
	/* height: 350px;
    width: 350px; */
	position: relative;
}

/* .shopNowImageContainerClass {
    height: 290px;
    width: 290px;
} */

.imageContainer>img {
	height: 100%;
	width: 100%;
	object-fit: cover;
}

.cardContent {
	display: flex;
	flex-direction: column;
	gap: 0px;
	margin-top: 20px;
}

.itemName {
	color: var(--black-color) !important;
}

.shopNowItemName {
	/* font-size: 26px !important;
	line-height: 1.6 !important;
	word-break: break-word;
	margin: 0;
	text-transform: capitalize !important;
	font-weight: bold;
	color: var(--black-color) !important; */

	height: auto !important;
	font-size: 18px !important;
	line-height: 1.3 !important;
	color: #999 !important;
	font-style: italic !important;
	font-weight: normal !important;
	font-family: Arial, Helvetica, sans-serif !important;
	margin: 0 !important;
	text-transform: inherit !important;

}

.priceContainer {
	display: flex;
	flex-direction: column;
	/* gap: 5px; */
}

.priceContainer span {
	color: #FF0000;
}

.listPriceContainer {
	display: flex;
	gap: 5px;
	margin-bottom: 10px;
	color: #FF0000;
}

.listPriceContainer span {
	font-size: 18px;
	color: var(--black-color);
}

.listPriceContainerStrike {
	display: flex;
	gap: 5px;
	font-size: 18px;
	color: var(--red-color);
	text-decoration: line-through;
	margin: 0;
}

.listPriceValue,
.listPriceLabel {
	font-size: 16px;
	font-weight: 500;
	line-height: 22.4px;
}

.itemPriceLabel {
	font-weight: 400;
	font-size: 16px;
	line-height: 25.6px;
	color: #FF0000;
}

.itemPriceValue {
	font-weight: 400;
	font-size: 18px;
	line-height: 25.6px;
}

.donationBox {
	padding: 10px;
	border: 1px solid var(--red-color);
	position: absolute;
	display: flex;
	flex-direction: column;
	gap: 8px;
	height: 60px;
	top: 77%;
	left: 6%;
	background-color: var(--pure-white);
	display: none;
}

.donationValue {
	font-size: 18px;
	line-height: 21.6px;
	color: #000;
}

.itemRedAlert {
	color: var(--red-color);
}

@media screen and (max-width:660px) {
	.shopNowCardContainer {
		max-width: 345px;
	}

	.shopNowImageContainerClass {
		width: 100%;
		height: 345px;
	}
}