.bannerContentSection {
  position: relative;
  height: 100%;
}

.bannerImage {
  width: 100%;
  height: auto;
  object-fit: cover;
  background-color: var(--white-color);
}

.newGradientSectionClass {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.newBannerTextClass {
  justify-content: center;
  gap: 10%;
}

.absoluteGradientDiv {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.about_causes_btn {
  display: flex;
  justify-content: center;
  align-items: center;
}

.about_causes_btn button {
  background-color: #fff;
  padding: 11px 15px;
  border-radius: 40px;
  font-size: 15px;
  color: #ff0000;
  min-width: 200px;
  margin-top: 25px;
}

.param_title {
  font-weight: bold;
  font-size: 70px;
  text-transform: uppercase;
  padding: 10px;
  font-family: "alternategotno1dregular";
  letter-spacing: 4px
}