.search-page {
    margin-top: 12px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.search-page-header {
    background-color: white;
}

.in-page-navigator {
    display: flex;
    justify-content: space-around;
}

.no_result_container {
    height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.no_results_text {
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0.28px;
}

.search_content_container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.default_search_heading {
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 25px;
    letter-spacing: -0.4px;
    width: 100%;
    margin: 0;
}

@media screen and (min-width: 768px) {
    .default-search-page {
        /* width: 70vw; */
        /* margin: 0 auto; */
        min-height: 67vh;
        width: auto;
        padding-top: 190px
    }
}

@media screen and (max-width:768px) {
    .default-search-page {
        padding-top: 167px;
        padding-inline: 30px;
    }
    .default_search_heading {
        margin-bottom: 10px;
        width: auto;
        margin-bottom: 1rem;
    }
    .search-page {
        padding-top: 157px;
        /* padding-inline: 30px; */
        padding-inline: 5vw;
    }
    .search_content_container {
        flex-direction: column;
        align-items: normal;
        justify-content: normal;
    }
}