.global-header {
  display: flex;
  align-items: center;
  padding: 8px 16px 8px 4px;
}

.global-header__action-btn {
  border-width: 0;
}

.global-header__title {
  flex: 1;
  font-family: "AvenirNext";
  font-weight: 600;
  text-align: center;
  font-size: 16px;
  width: 100%;
}

@media screen and (max-width:728px) {
  .global-header__title {
    text-align: end;
    font-family: 'DM Mono';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.32px;
    width: 100%;
  }
}

