:root {
    --white: #ffffff;
    --black: #000000;
    --transparent: transparent;
    --color-lffu6uvb: #603F8B;
    --color-lffudicf: #F43AB0;
    --color-lffy4ue2: #9C9C9C;
    --primary-btn-clr: #1a385c;
}

.section6>div>h2 {
    /* font-size: 42px; */
    font-size: 36px;
    color: var(--color-lffu6uvb);
    margin-left: 20px;
    margin-top: 60px;
}

.section6>div>p {
    font-size: 20px;
    margin-left: 20px;
}

.card {
    background-color: var(--color-lffudicf);
    display: flex;
    /* grid-template-columns: 35% 60%; */
    gap: 5%;
    padding: 0px 80px;
    border-radius: 5px;
    margin-top: 40px;
    text-align: center;
}

.card>img {
    /* max-width: 35%; */
    max-width: 45%;
    margin-top: 45px;
    order: 1;
}

.card>div {
    order: 2;
    padding-bottom: 34px;
}

.card>div>h2 {
    margin-top: 75px;
    color: var(--white);
}

.card>div>p {
    margin-top: 20px;
    color: var(--white);
    text-align: center;
}

.button_container {
    display: flex;
    justify-content: center;
    margin-top: 40px;

}

.button_container>a>button {
    background-color: var(--primary-btn-clr);
    color: var(--white);
    padding: 15px 40px;
    font-size: 16px;
    border-radius: 5px;
    margin-top: 10px;
    font-weight: 700;
    width: 240px;
}

.cta {
    color: var(--color-lffy4ue2);
    font-size: 14px;
    margin-bottom: 40px;
    padding: 15px 50px
}


@media only screen and (max-width: 768px) {
    .card {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        /* height: 825px; */
        height: 692px;
        padding: 0px 10px;

    }
    .button_container>a>button {
        font-size: 14px;
    }
    .card>img {
        max-width: 70%;
        max-height: 300px;
        /* max-height: 455px; */
        order: 2;
        margin-top: 0;
    }

    .card>div {
        order: 1;
        padding-bottom: 0;
    }

    .section6>div>h2 {
        /* font-size: 42px; */
        font-size: 30px;
        /* font-size: 33px; */
        margin-left: 0px;
        margin-top: 60px;
        text-align: center;
        padding-inline: 2px;
        padding-inline: 5px;
    }

    .section6>div>p {
        /* font-size: 20px; */
        font-size: 16px;
        margin-left: 0px;
        text-align: center;
        padding-inline: 5px;
    }
    .card>div>h2 {
        font-size: 30px !important;
    }
    
    .card>div>p {
        font-size: 16px !important;
    }

}