.forgetpass_wrapper{
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.forgetpass_sec{
    width: 80%;
    height: 80%;
    display: flex;
    align-content: center;
    box-shadow: 0 25px 50px -12px rgb(0 0 0 / 0.25);
    border-radius: 25px;
}
.forgetpass_sec_left{
    width: 40%; 
}
.forgetpass_sec_left > img{
    object-fit:cover;
    width: 100%;
    height: 100%;
    border-radius: 25px 0 0 25px;
}
.forgetpass_sec_right{
    width:60%;
    height: 100%;
   display: flex;
   flex-direction: column;
}
.email_input{
    width: 80%;
    box-sizing:border-box ;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin: 0 auto;
    margin-top: 10%;
    margin-bottom: 5%;
    position: relative; 
}
.email_input input{
    width:100%;
    border: 1px solid #d3d3d3;
    padding: 10px;
}
.email_input input:focus{
    outline: none;
}
.email_input label{
    color: #5D77A7;
    font-weight: 400;
    font-size: 1rem;
    margin-bottom: 10px;
}
.recovery_link{
    width: 80%;
    margin: 0 auto;
    height: 40px;
    color: white;
    background-color:  #1C385D;
}
.pass_input{
    width: 80%;
    margin: 0 auto;
    box-sizing:border-box ;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-bottom: 5%;
    position: relative; 
    position: relative;
}
.pass_input input{
    width:100%;
    padding: 10px;
    border: 1px solid #d3d3d3;
    font-size: 0.8rem;
}
.pass_input_container {
    position: relative;
}
.pass_input label{
    color: #5D77A7;
    font-weight: 400;
    font-size: 1rem;
    margin-bottom: 10px;
}
.pass_input input:focus{
    outline: none;
}
.comp_wrapper{
    display: flex;
    flex-direction: column;
    gap: 50px;
    align-items: center;
}
.comp_wrapper h3{
    font-family: 'AvenirNext';
    font-style: normal;
    font-weight: 700;
    font-size: 31px;
    line-height: 36px;
    letter-spacing: 0.035em;
    color: #000000;
    margin-bottom: 25px;
}
.pass_input .eye{
    position: absolute;
    top: 50%;
    right: 2%;
    font-size: 1.2rem;
    transform: translateY(-50%);
    cursor: pointer;
}
.succes_login{
    width:150px;
    height: 40px;
    color: white;
    background-color:  #1C385D;
}