.carosel_container {
    position: relative;
}

::-webkit-scrollbar {
    display: none;
}

.carosel_container > img {
    width: 96%;
    margin:auto;
    border-radius: 15px;
}

.carosel_container > div {
    position: absolute;
    /* color: #FC3300; */
    top: 0;
    z-index: 1;
    width: 74%;
    padding: 10px 23px;
}
.carosel_container > div > h4 {
    font-size: 1.9rem;
    text-align: start;
}

@media screen and (min-width:412px) {
    .carosel_container > div {
        width: 67%;
    }
}

@media screen and (min-width:340px) {
    .carosel_container > div > h4 {
        font-size: 2.5rem;
    }   
}

@media screen and (min-width:435px) {
    .carosel_container > div > h4 {
        font-size: 3rem;
    }   
}

@media screen and (min-width:645px) {
    .carosel_container > div > h4 {
        font-size: 3.5rem;
    }   
}


@media screen and (min-width:500px) {
    .carosel_container > div {
        width: 65%;
        padding: 3em 2em;
    }
  
}