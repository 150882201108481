:root {
    --primary_color: #FC3300;
    --brandPrimary_color: #30313A;
    --beige: #F0E8E3;
}


.nonprofit_signup_container {
    display: flex;
    flex-direction: column;
    gap: 13px;
    color: var(--primary_color);
}

 /* nonprofit signup modal also using for brand , so changing color by adding new class */
.brand_signup {
    color: var(--brandPrimary_color);
}

.nonprofit_signup_container>input {
    font-family: Instrument Sans;
    height: 50px;
    border-radius: 5px;
    border: 2px solid var(--primary_color);
    background: rgba(255, 255, 255, 0.08);
    padding-left: 10px;
    min-width: 300px;
    font-size: 15px;
}

.brand_signup >input {
    border: 2px solid var(--brandPrimary_color);
}

.nonprofit_signup_container> p {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    text-align: center;
}

.nonprofit_signup_container> h3 {
    text-align: center;
    /* h3 */
    font-family: Instrument Sans;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 35px;
    /* 109.375% */
    letter-spacing: -0.64px;
    margin-bottom: 25px;
}

.nonprofit_signup_container>input::placeholder {
    color: var(--primary_color);
}

.brand_signup>input::placeholder {
    color: var(--brandPrimary_color);
}

.thanku_text {
    font-size: 20px !important;
    line-height: 32px !important;
    font-weight: 600 !important;
}

@media screen and (max-width:576px) {
    .nonprofit_signup_container> h3 {
        font-size: 28px;
    }
}

@media screen and (max-width:470px){
    .nonprofit_signup_container>input {
        min-width: 250px;
    }
}

@media screen and (max-width:370px){
    .nonprofit_signup_container>input {
        min-width: auto;
    }
}