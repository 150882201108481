.navbar-fixed-bottom {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: white;
    height: 60px;
    z-index: 10000;
    display: flex;
    align-items: center;
    box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.25);
}

.navbar-fixed-bottom > ul {
    margin: 0px auto;
    padding: 0px;
    list-style: none;
    text-align: center;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-around;
    margin: 0;
}

.navbar-fixed-bottom li p {
    font-size: 10px;
    margin-top: 3px;
    color: black;
    margin-bottom: 0;
}

.navbar-fixed-bottom li p.selected {
color: var(--primary-blue-clr);
}

.navbar-fixed-bottom li {
float: none;
display: inline-block;
text-align: center;
}

.navbar-fixed-bottom li img {
width: 25px;
height: 25px;
object-fit: contain;
}