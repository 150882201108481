@font-face {
  font-family: "AvenirNext";
  src: local("AvenirNext"),
    url(./fonts/avenir-next/AvenirNext-Regular.otf) format("opentype");
}

@font-face {
  font-family: "AvenirNext";
  font-weight: 600;
  src: local("AvenirNext"),
    url(./fonts/avenir-next/AvenirNext-Medium.otf) format("opentype");
}

@font-face {
  font-family: "AvenirNext";
  font-weight: 800;
  src: local("AvenirNext"),
    url(./fonts/avenir-next/AvenirNext-Bold.otf) format("opentype");
}

@font-face {
  font-family: "Metropolis";
  font-weight: 600;
  src: url(./fonts/metropolis/Metropolis-Medium.otf) format('opentype');
}

@font-face {
  font-family: "Metropolis";
  font-weight: 700;
  src: url(./fonts/metropolis/Metropolis-Bold.otf) format('opentype');
}

@font-face {
  font-family: "Metropolis";
  src: url(./fonts/metropolis/Metropolis-Light.otf) format('opentype');
}

@font-face {
  font-family: "Merriweather";
  src: url(./fonts/merriweather/Merriweather-Regular.ttf) format('opentype');
}

@font-face {
  font-family: "Merriweather";
  font-weight: 700;
  src: url(./fonts/merriweather/Merriweather-Bold.ttf) format('opentype');
}
@font-face {
  font-family: "Roboto Condensed";
  font-weight: 700;
  src: url(./fonts/Roboto_Condensed/RobotoCondensed-Bold.ttf) format('opentype');
}
@font-face {
  font-family: "Roboto Condensed";
  src: url(./fonts/Roboto_Condensed/RobotoCondensed-Regular.ttf) format('opentype');
}
@font-face {
  font-family: "Cabin";
  src: url(./fonts/Cabin/Cabin-Bold.ttf) format('opentype');
}
@font-face {
  font-family: "Cabin";
  src: url(./fonts/Cabin/Cabin-Regular.ttf) format('opentype');
}
@font-face {
  font-family: 'Instrument Sans';
  src: url(./fonts/instrument-sans/InstrumentSans-Regular.ttf) format('opentype');
}
@font-face {
  font-family: 'DM mono';
  src: url(./fonts/Dm-Mono/DMMono-Regular.ttf);
}
@font-face {
  font-family: 'DM mono';
  src: url(./fonts/Dm-Mono/DMMono-Regular.ttf);
}
@font-face {
  font-family: 'Instrument Sans Bold';
  src: url(./fonts/instrument-sans/InstrumentSans-Bold.ttf) format('opentype');
}
@font-face {
  font-family: 'Test Founders Grotesk X-Condensed Bold';
  src: url(./fonts/TestFoundersGroteskX-Condensed/test-founders-grotesk-x-condensed-bold.woff2) format('opentype');
}
@font-face {
  font-family: 'Test Founders Grotesk X-Condensed Medium';
  src: url(./fonts/TestFoundersGroteskX-Condensed/test-founders-grotesk-x-condensed-medium.woff2) format('opentype');
}
@font-face {
  font-family: 'Instrument Sans Condensed Bold';
  src: url(./fonts/instrument-sans/InstrumentSans_Condensed-Bold.ttf) format('opentype');
}

@font-face {
  font-family: 'alternategotno1dregular';
  src: url('./fonts/Alternate_Gothic/alternategotno1d-webfont.woff2') format('woff2'),
       url('./fonts/Alternate_Gothic/alternategotno1d-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

html {
  scroll-behavior: auto !important;
}

:root {
  --primary-blue-clr: #1a385c;
  --primary-btn-clr: #1a385c;
  --secondary-btn-clr:#1a385c;
  --display-app: none;
  --splash-bg: unset;

  --color-lffu6uvb: #603F8B;
  --color-lffudicf: #F43AB0;
  --color-lffy4ue2: #9C9C9C;

  --headlinefontMobile:  'Instrument Sans';
  --contentfontMobile:  'Instrument Sans';

  --para-fontsize: 22px;
  --para-fontsize-mobile: 20px;

  --test-founders-grotesk-x-condensed-bold: 'Test Founders Grotesk X-Condensed Bold';

  --red-color:#FC3300;
  --black-color: #30313A;
  --white-color: #F0E8E3;
  --pure-white: #fff;
  --off-black: #65666E;
  --off-white: rgba(48, 49, 58, 0.08);
  --line-color:rgba(48, 49, 58, 0.16);
}

@media screen and (min-width: 768px) {
  :root{
    /* --primary-blue-clr: #603F8B;
       --primary-btn-clr: #c1067c;
       --secondary-btn-clr:#c1067c; */
       --headlinefont: Roboto Condensed;
       --contentfont: 'Instrument Sans';
       --bodyfont: 'Arial', sans-serif;
       
  }
  body{
    margin-bottom: 0px;
    font-family: var(--bodyfont) !important;
  }
  h1,h2,h3{
    font-family: var(--headlinefontMobile);
  }
 

}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}


::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 0px;
  background-color: #f5f5f5;
}
::-webkit-scrollbar {
  width: 5px;
  height: 2px;
  background-color: #f5f5f5;
}
::-webkit-scrollbar-thumb {
  border-radius: 0px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #555;
}

html {
  min-height: 100vh;
  scroll-behavior: auto;
}

body {
  margin: 0px auto;
  padding: 0px;
  background: #fff;
  font-family: 'Instrument Sans';
  background: var(--splash-bg);
  overscroll-behavior: none;
  scroll-behavior: auto;
  /* margin-bottom: 70px; */
}

.MuiTypography-root {
  font-family: 'Instrument Sans' !important;
}

@media (min-width: 768px) {
  body {
    margin-bottom: 0px;
  }
}

p {
  text-align: left;
}

a {
  color: inherit;
}

a:hover {
  color: inherit
}

#App {
  overflow: hidden;
  position: relative;
  width: 100%;
}

.brandSubtitle {
  color: var(--black-color);
}

.greyBoldText {
  color: #8f8f8f !important;
  font-weight: bold;
}

.headingH2 {
  font-size: 72px !important;
  line-height: 1 !important;
  font-family: "alternategotno1dregular" !important;
  font-weight: bold;
}

.headingH3 {
  font-size: 56px !important;
  line-height: 1 !important;
  font-family: "alternategotno1dregular" !important;
  font-weight: bold;
}

@media only screen and (max-width: 768px) {
  .headingH2 {
    font-size: 52px !important;
  }
}

.sectionSubtitle {
  font-size: 24px !important;
  line-height: 1.4 !important;
}

.newTitleClass {
  font-size: 48px;
  /* font-size: 38px; */
  font-weight: 700;
  line-height: 36px;
  text-transform: uppercase;
  color: var(--red-color);
  font-family: var(--test-founders-grotesk-x-condensed-bold);
}

.swiper-pagination-bullet {
  background-color: transparent;
}

.swiper-pagination-bullet-active {
  background-color: var(--red-color);
}

.bannerBulletContainer > .swiper-pagination-bullet-active {
  background-color: var(--pure-white);
}

.splace_sec {
  width: 100%;
  background: none;
  padding: 0px;
  background: #fff;
}

.new_footer_btn {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  /* text-decoration-line: underline; */
  border: 0;
  padding: 5px 1px;
  width: fit-content;
}
.admin_preview_banner{
  width:100%;
  padding:10px;
  position: fixed;
  bottom: 0;
  /* background-color: rgb(240, 232, 227); */
}
.admin_preview_banner > p{
  text-align: center;
  color: #FC3300;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 0;
}
.para_fontsize {
  font-size: var(--para-fontsize-mobile) !important;
  line-height: 24px !important;
  letter-spacing: .6px;
  word-spacing: 2px;
}
@media screen and (min-width:768px) {
  .margin_class {
    margin-inline: 5%;
    padding-inline: 0 !important;
  }
  .para_fontsize {
    font-size: var(--para-fontsize) !important;
    line-height: 30px !important;
  }
}

@media screen and (min-width:980px) {
  .margin_class {
    margin-inline: 10%;
    padding-inline: 0;
  }
}
/* common class */
.footer-extra-container-class-F0E8E3{
  width:100%;
  background-color: #F0E8E3;
}
.footer-extra-container-class-30313A{
  width:100%;
  background-color: #30313A;
}

.container {
  /* max-width: 450px; */
  display: block;
  margin: 0px auto;
  /* margin-bottom: 13%; */
  position: relative;
  padding: 0;
}

.splace_inside {
  width: 100%;
  height: 100%;
  background-color: #fff;
  height: calc(100vh - 0px);
  overflow: auto;
  background-repeat: no-repeat;
  background-position: bottom;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.splace_inside img {
  width: 250px;
}

.slider_inside {
  width: 100%;
  background: none;
  padding: 0px;
  position: relative;
}

.slider_pics {
  width: 100%;
  background: url("./images/pic1.png");
  height: 450px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  /* border-radius: 0px 0px 25px 25px; */
}

.slider_pics2 {
  background: url("./images/pic2.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.slider_pics3 {
  background: url("./images/pic3.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.slider_content {
  width: 100%;
  background: none;
  padding: 35px 0px;
}

.slider_content p {
  text-align: center;
  font-size: 15px;
  line-height: 25px;
  font-weight: 600;
}

.slider_content ul {
  margin: 0px auto;
  padding: 0px;
  list-style: none;
  text-align: center;
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}

.slider_content li {
  float: none;
  display: inline-block;
}

.carousel-status {
  display: none;
}

.control-prev {
  display: none;
}

.carousel .control-dots {
  bottom: 30px !important;
  margin: 0px auto !important;
}

.carousel.carousel-slider .control-arrow {
  padding: 0px 10px !important;
  cursor: pointer !important;
}

.carousel.carousel-slider .control-arrow:hover {
  background: transparent !important;
}

.carousel .control-next.control-arrow:before {
  border-left: none !important;
  content: "Next";
}

.carousel .thumbs-wrapper {
  display: none;
}

.carousel .control-dots .dot {
  outline: none !important;
  background: linear-gradient(180deg, #00526b 0%, #0098b9 100%) !important;
  box-shadow: none !important;
}

.carousel .control-disabled.control-arrow {
  opacity: 1 !important;
  filter: alpha(opacity=0);
  cursor: inherit;
}

.carousel .control-next.control-arrow {
  top: 75% !important;
  color: #ffff;
}

.carousel .control-arrow:before,
.carousel.carousel-slider .control-arrow:before {
  content: "Next" !important;
}

.carousel .control-next.control-arrow:before {
  border-left: 8px solid #fff;
  background: transparent;
  /* color: #006883; */
  color: #ffff;
  margin: 0px 12px 0px 0px !important;
  font-size: 16px;
  line-height: 5px;
  padding: 0;
  border-radius: 3px;
  outline: none !important;
  font-weight: 800;
  opacity: 1 !important;
}

.carousel .control-arrow,
.carousel .control-arrow {
  opacity: 1 !important;
  filter: alpha(opacity=100);
}

a {
  text-decoration: none !important;
}

.slider_btns {
  width: 100%;
  background: none;
  padding: 0px;
  position: absolute;
  bottom: 42px;
}

.slider_btns ul {
  margin: 0px auto;
  padding: 0px;
  list-style: none;
  display: inline-block;
}

.slider_btns li {
  color: #000;
  font-size: 16px;
  text-align: left;
}

.slider_btns li a {
  color: #000;
  position: absolute;
  left: 0;
  bottom: 1px;
  z-index: 9999;
}

.slider_btns .hideSkip {
  bottom: 16px;
}

.slider_btns .showStart {
  bottom: 46px;
}

.slider_sec .carousel .control-dots {
  bottom: 44px !important;
}

a.goDashboard {
  color: #006883 !important;
  font-weight: 500;
  display: none;
  z-index: 9999;
  left: auto !important;
  right: 22px;
}

.authentic_sec {
  width: 100%;
  background: none;
  padding: 0px;
}

.auth_inside {
  position: relative;
  width: 100%;
  background: none;
}

.authen_pics {
  width: 100%;
  background: url("./images/pic4.png");
  height: 450px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 0px 0px 25px 25px;
}

.authen_content {
  width: 100%;
  background: none;
  padding: 30px 0px;
}

.authen_content h5 {
  text-align: center;
  font-size: 14px;
  margin-bottom: 15px;
  color: #00607a;
  font-weight: 600;
  text-decoration: underline !important;
  text-decoration: underline !important;
}

.authen_content h5 a {
  color: #00607a;
  text-decoration: underline !important;
}

.orSec {
  width: 100%;
  background: none;
  padding: 0px;
  text-align: center;
  font-size: 14px;
  position: relative;
  margin-bottom: 10px;
}

.orSec::before {
  content: "";
  width: 100%;
  height: 1px;
  background: #00708c;
  display: block;
  position: absolute;
  bottom: 10px;
}

.orSec span {
  background: #fff;
  color: #000;
  display: inline-block;
  position: relative;
  z-index: 9999;
  padding: 0 15px;
}

.blockBtn {
  background: var(--primary-blue-clr);
  width: 100%;
  border: none;
  color: #fff;
  border-radius: 5px;
  padding: 15px 25px;
  outline: none;
  font-weight: 700;
  font-size: 13px;
  margin-top: 15px;
}

.blockBtn-loading {
  background: var(--primary-blue-clr);
  width: 100%;
  border: none;
  color: #fff;
  border-radius: 5px;
  padding: 5px;
  outline: none;
  font-weight: 700;
  font-size: 13px;
  margin-top: 15px;
}

.blockBtn.not-allowed-btn {
  background: #e6e7ea;
  color: #a2a4aa;
}

.title_select_div {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.title_select_div img {
  z-index: 1;
  position: absolute;
  right: 25px !important;
  width: 9px;
}

.title_dropdown {
  padding: 10px;
  font-size: 15px;
  min-height: 100%;
  border: 1px solid #a2a4aa;
  border-radius: 0px;
  margin-right: 15;
  background: #fff;
  -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  appearance: none;
  margin-right: 15px;
  position: relative;
  padding-right: 25px;
}

.login_head {
  /* background: url("./images/bgpic2.png"); */
  width: 100%;
  padding: 30px 0px;
  background-size: 430px;
  background-repeat: no-repeat;
  background-position: center bottom;
}

.login_head img {
  display: block;
  margin: auto;
  width: 160px;
}

.login_body {
  padding: 10px 15px 0px;
  width: 100%;
}

.login_body h2 {
  font-size: 22px;
  text-align: center;
  font-family: "AvenirNext";
}

.login_body h3 {
  text-align: center;
  margin-bottom: 50px;
  font-size: 13px;
  padding: 5px 10px;
}

.login_body p {
  text-align: center;
  margin-bottom: 0;
  padding: 0px !important;
  font-size: 13px !important;
}

.form-group {
  margin-bottom: 20px;
}

.login_body form {
  margin-top: 25px;
}

.field_outer {
  position: relative;
  overflow: hidden;
  /* border-radius: 10px; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.field_outer .form-control {
  background: #fff;
  border: 1px solid #a2a4aa;
  font-size: 15px;
  padding: 10px;
  outline: none;
  border-radius: 0px;
  text-align: left;
  /* padding-left: 45px; */
}

.field_outer img {
  position: absolute;
  right: 15px;
}

.field_outer span {
  position: absolute;
  right: 15px;
}

.logForm h5 {
  text-align: center;
  font-size: 13px;
  color: #000;
  margin-bottom: 0px;
  font-weight: 500;
}


.stickDown {
  width: 100%;
  margin-top: 10px;
  padding: 15px 0px;
}

.stickDown h6 {
  text-align: center;
  font-size: 13px;
}

.stickDown h6 a {
  font-size: 14px;
  /* color: var(--primary-blue-clr); */
  /* font-weight: 600; */
  margin-left: 5px;
  text-decoration: underline !important;
}

.minHeight420 {
  min-height: calc(100vh - 420px);
}

.blockBtn img {
  width: 15px;
  margin-right: 5px;
}

.logForm label {
  font-size: 13px;
  text-align: left;
  margin-bottom: 0;
  color: #444;
  position: relative;
  padding-left: 35px;
  line-height: 24px;
  cursor: pointer;
}

.logForm label input[type="checkbox"] {
  width: 22px;
  height: 22px;
  /* border-radius: 5px; */
  -webkit-appearance: none;
  background: #fff;
  border: 1px solid #ccc;
  position: absolute;
  left: 0;
  top: 1px;
  outline: none !important;
}

.logForm label input[type="checkbox"]:checked:before {
  content: "";
  font-size: 0.643em;
  font-weight: 900;
  font-family: "Font Awesome 5 Free";
  display: block;
  line-height: normal;
  color: #fff;
  height: 5px;
  width: 10px;
  transform: rotate(-33deg);
  background: #fff;
  margin-top: 3px;
  margin-left: 2px;
  border-left: 2px solid #000;
  border-bottom: 2px solid #000;
}

.minHeight220 {
  min-height: calc(100vh - 220px);
}

.page_outer {
  width: 100%;
  background: none;
  background-color: var(--pure-white);
}
.page_outer_acc{
  width: 100%;
  background: none;
  padding-top: 2%;
}
.header_sec {
  background: #fff;
  padding: 15px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 3px 5px #f5f5f5;
  position: relative;
  z-index: 999;
  box-shadow: 0 8px 6px -6px rgba(0, 0, 0, 0.3);
  position: sticky;
  top: 0;
  left: 0;
}

.auction_header {
  justify-content: space-between;
  background: var(--primary-blue-clr);
  font-weight: 800;
  z-index: 9999;
}

.auction_header h3 {
  color: #fff !important;
  font-size: 18px !important;
}

.auction_header img {
  width: 24px !important;
}

.header_sec img {
  width: 20px;
}

.header_sec img.headLogo {
  width: 80px;
}

main {
  width: 100%;
  min-height: calc(100vh - 145px);
  /* padding: 0 15px; */
  padding: 1px 15px;
  /* background-color:  #FC3300 ; */
  overflow-x: hidden;
}

@media screen and (min-width: 768px) {
  main {
    /* background-color: white; */
  }
}

.homePageMain {
  padding: 0;
}

.homePageMain > section > div {
  margin: 2px 15px;
}

/* .homePageMain > div > div {
  margin: 2px 15px;
} */

.homePageMain > div > section {
  margin: 2px 15px;
}

.home_search {
  width: 100%;
  padding: 20px 20px;
  background: transparent;
}

.search_field {
  position: relative;
  background: #d2f8ff;
  padding: 0px;
  border-radius: 50px;
  overflow: hidden;
  width: 100%;
}

.search_field img {
  position: absolute;
  left: 20px;
  top: 18px;
  width: 14px;
}

.search_field input.form-control {
  height: auto;
  background: none;
  border: none;
  outline: none !important;
  padding: 15px 20px;
  border-radius: 50px;
  padding-left: 45px;
  font-weight: 600;
  color: #000;
  font-size: 13px;
}

.current_banner {
  width: 100%;
  background: none;
  padding: 0px 20px;
  padding-top: 30px;
}

.current_banner .carousel .control-next.control-arrow {
  display: none;
}

.current_banner .carousel .control-dots .dot {
  background: linear-gradient(180deg, #ffffff 0%, #ffffff 100%) !important;
  margin: 0 2px !important;
  width: 5px !important;
  height: 5px !important;
  opacity: 1 !important;
}

.current_banner .carousel .control-dots .dot.selected {
  width: 25px !important;
  border-radius: 50px;
}

.current_auction {
  padding: 30px 20px;
  width: 100%;
  background: none;
}

.auctionDiv2::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.auctionDiv2 {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.page_heading {
  width: 100%;
  background: none;
  /* padding: 0px; */
  padding-right: 20px;
  margin-bottom: 20px;
}
#page_heading_coming_soon {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  gap: 15px;
  flex-wrap: wrap;
}

.page_heading h3 {
  color: #333;
  margin-bottom: 0px;
  margin-top: 0px;
  text-align: left;
  font-size: 22px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: "AvenirNext";
  font-weight: 800;
}

.page_heading h3 span {
  float: right;
  font-size: 14px;
  font-weight: 400;
}

.page_heading h3 span a {
  color: #333;
}

.auction_card {
  width: 100%;
  background: none;
  padding: 20px 15px;
  box-shadow: 0px 4px 5px #e2e2e2;
  border-radius: 18px;
  height: 260px;
  position: relative;
}

.auction_div {
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 18px;
  overflow: hidden;
  margin-right: 15px;
  width: 100%;
}

.auction_card h5 {
  font-size: 12px !important;
  text-align: left;
  position: absolute;
  margin-top: 0;
  font-weight: 600;
  margin-bottom: 15px;
  color: #000;
  left: 20px;
  padding: 5px 8px;
  background: #a8f4c7;
  top: 20px;
  border-radius: 2px;
}

.auction_card h5 img {
  position: absolute;
  right: 0;
  top: 1px;
}

.auction_card img.center {
  width: 90px;
  height: 90px;
  display: block;
  margin: auto;
  margin-bottom: 10px;
}

.auction_card h3 {
  font-size: 13px;
  text-align: center;
  margin-top: 0;
  color: #2c2b4d;
  font-weight: 500;
  margin-bottom: 10px;
}

.auction_card h1 {
  font-size: 16px;
  text-align: center;
  margin-top: 0;
  color: #2c2b4d;
  font-weight: 800;
  margin-bottom: 0px;
}

.my_bid_page .toggle_bar {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #cccdd0;
  width: min(450px, 100vw);
  position: relative;
  padding: 20px 25px 10px 25px;
  margin-bottom: 20px;
  background-color: #fff;
}

.my_bid_page .toggle_bar .moveRight {
  animation-name: moveRight;
  animation-duration: 0.2s;
  animation-fill-mode: forwards;
}

@keyframes moveRight {
  0% {
    left: 25px;
    right: auto;
  }
  50% {
    right: 50vw;
  }
  100% {
    left: auto;
    right: 25px;
  }
}

.my_bid_page .toggle_bar .moveLeft {
  animation-name: moveLeft;
  animation-duration: 0.2s;
  animation-fill-mode: forwards;
}

@keyframes moveLeft {
  0% {
    right: 25px;
    left: auto;
  }
  50% {
    left: 50vw;
  }
  100% {
    right: auto;
    left: 25px;
  }
}

.my_bid_page .toggle_bar span {
  width: 120px;
  text-align: center;
  font-size: 15px;
}

.my_bid_page .toggle_bar .black_line {
  width: 120px;
  height: 1px;
  background-color: #000;
  position: absolute;
  bottom: 0;
  left: 25px;
}

.item_bid_container {
  min-height: 100vh;
  background-color: #f4f4f4;
}

.my_bid_page .bid_cards {
  display: flex;
  flex-direction: column;
  padding: 10px 0;
}

.item_bid_container .bid_card {
  display: flex;
  flex-direction: column;
  width: 100%;
  display: flex;
  font-size: 16.5px;
  background-color: white;
  color: #00526b;
  border-radius: 8px;
  margin-bottom: 19px;
  box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)
}

.my_bid_page .bid_card .bid_row {
  display: flex;
  padding: 10px;
  justify-content: space-between;
  border: 1px solid #e8e6e6;
}

.my_bid_page .bid_card .bid_row div:nth-child(1) {
  margin-right: 10px;
  font-weight: 600;
  display: flex;
}

.my_bid_page .bid_card img {
  max-height: 125px;
  width: 35%;
}

.my_bid_page .bid_card h3 {
  font-size: 15px;
  text-align: center;
}

.my_bid_page .bid_card .info {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 5px;
}

.my_bid_page .bid_card .info span {
  font-size: 12px;
  color: #4a4848;
}

.my_bid_page .bid_card p {
  background: #a8f4c7;
  border-radius: 2px;
  font-size: 12px;
  font-weight: 600;
  padding: 4px 7px;
  text-align: center;
  margin-bottom: 0;
}

@media screen and (min-width: 768px) {
  .item_bid_container {
    padding: 0 10px;
    min-height: 63vh;
    max-width: 65vw;
    margin: 0 auto;
    margin-top: 20px;
    background-color: white;
  }

  .my_bid_page .bid_card .bid_row {
    padding: 20px;
  }

  .item_bid_container .bid_card {
    color: var(--primary-blue-clr);
  }
}

.viewAllText,
.viewAllText:hover {
  color: var(--primary-blue-clr) !important;
  font-weight: 600;
}

.padR {
  padding-right: 5px !important;
}

.padL {
  padding-left: 5px !important;
}

.footer_sec {
  width: 100%;
  background: #fff;
  padding: 15px 30px;
  position: fixed;
  z-index: 2;
  border-radius: 5px 5px 0px 0px;
  bottom: 0;
  display: none;
  box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.25);
}

.footerLink {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.footer_sec li p {
  font-size: 10px;
  margin-top: 3px;
  color: black;
}

.footer_sec li p.selected {
  color: var(--primary-blue-clr);
}

.footer_sec ul {
  margin: 0px auto;
  padding: 0px;
  list-style: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer_sec li {
  float: none;
  display: inline-block;
  text-align: center;
}

.footer_sec li img {
  width: 20px;
  height: 20px;
  object-fit: contain;
}

.categories_banner {
  width: 100%;
  background: none;
  padding: 0px 20px 30px;
}

.categories_banner .carousel .control-dots .dot {
  background: #e9e9e9 !important;
  margin: 0 4px !important;
  width: 12px !important;
  height: 12px !important;
  opacity: 1 !important;
  border-radius: 2px !important;
}

.categories_banner .carousel.carousel-slider {
  overflow: visible;
}

.categories_banner .carousel .control-dots {
  bottom: -35px !important;
}

.categories_banner .carousel .control-dots .dot.selected,
.categories_banner .carousel .control-dots .dot:hover {
  opacity: 1;
  filter: alpha(opacity=100);
  background: #00647e !important;
}

.categories_banner .carousel .control-next.control-arrow:before {
  border-left: 8px solid #fff !important;
  content: "" !important;
}

.categories_banner .carousel .control-arrow:before,
.categories_banner .carousel.carousel-slider .control-arrow:before {
  content: "" !important;
}

.categories_banner .carousel .control-disabled.control-arrow {
  display: block !important;
  left: 7px;
  top: -22px;
}

.categories_banner .carousel .control-prev.control-arrow:before {
  border-right: 8px solid #fff !important;
}

.categories_banner .control-prev {
  display: block !important;
}

.categories_banner .carousel .control-next.control-arrow {
  top: -22px !important;
  right: 50px;
}

.categories_banner .carousel .control-prev.control-arrow:before {
  border-right: 8px solid #fff;
  width: 40px;
  height: 40px;
  background-color: #fff;
  border-radius: 50%;
  position: absolute;
  z-index: 9999;
  background-image: url("./images/ico12.png");
  background-repeat: no-repeat;
  background-position: 12px 2px;
  background-size: 13px;
  display: none !important;
}

.categories_banner .carousel .control-next.control-arrow:before {
  border-left: 8px solid #fff;
  width: 40px;
  height: 40px;
  background: #fff;
  border-radius: 50%;
  position: absolute;
  z-index: 9999;
  background-image: url("./images/ico13.png");
  background-repeat: no-repeat;
  background-position: 7px 2px;
  background-size: 13px;
  display: none !important;
}

.categories_sec {
  padding: 30px 20px 0px;
  width: 100%;
  background: none;
}

.categories_list {
  width: 100%;
  background: none;
  margin-top: 30px;
}

.categories_list ul {
  margin: 0px auto;
  padding: 0px;
  list-style: none;
  text-align: left;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
}

.categories_list li {
  float: none;
  display: inline-block;
  margin-bottom: 30px;
  width: 25%;
}

.categories_list li img {
  display: block;
  margin: auto;
  width: 80px;
}

.categories_list li h6 {
  margin-bottom: 0px;
  margin-top: 10px;
  text-align: center;
  font-weight: 400;
  color: #333;
  font-size: 14px;
}

.browseby_sec {
  padding: 30px 20px;
  width: 100%;
  background: none;
}

.header_sec h3 {
  margin-bottom: 0;
  font-size: 16px;
  /* font-weight: 600; */
  text-align: center;
  color: #333;
}

.auction_banner {
  width: min(100vw, 450px);
  background: none;
  padding: 0px;
  position: relative;
  left: -15px;
  margin: 15px;
}

.time_left {
  position: absolute;
  bottom: 20px;
  left: 20px;
  z-index: 9;
  background: #fcd2bb;
  border-radius: 2px;
  padding: 5px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.time_left img {
  width: 15px;
  height: 15px;
  margin-right: 5px;
}

.time_left h6 {
  font-size: 13px;
  margin: 0;
}

.auction_banner .carousel .control-dots .dot {
  width: 13px !important;
  height: 13px !important;
  margin: 0px 4px;
}

.auction_banner .carousel .control-dots {
  bottom: 55px !important;
}

.auction_banner .carousel .control-dots .dot.selected {
  opacity: 1;
  filter: alpha(opacity=100);
  background: linear-gradient(180deg, #00526b 0%, #0098b9 100%) !important;
}

.auction_closes {
  width: 100%;
  background: #fff;
  padding: 0px 15px;
}

.closes_inside {
  padding: 15px 35px;
  background: none;
  width: 100%;
  box-shadow: 0px 3px 4px 0px #00000017;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  /* margin-top: -50px; */
  z-index: 99;
  background: #fff;
}

.closes_countdown {
  width: 45%;
  background: none;
  padding: 0;
}

.closes_countdown h6 {
  font-size: 11px;
  font-weight: 700;
  margin-bottom: 0;
  text-align: left;
  position: relative;
  padding-left: 30px;
  color: #054f61;
}

.closes_countdown h6 img {
  position: absolute;
  left: 0;
  width: 20px;
  margin-top: 3px;
}

.closes_countdown h6 span {
  display: block;
  font-size: 11px;
}

.auction_banner .carousel .control-next.control-arrow {
  display: none;
}

.closes_countdown p {
  margin-bottom: 0;
  font-size: 9px;
  margin-top: 10px;
  color: #777;
  text-align: left;
}

.auction_banner .carousel .control-dots .dot {
  background: linear-gradient(180deg, #ffffff 0%, #ffffff 100%) !important;
  opacity: 1 !important;
}

.closes_lists {
  width: 55%;
  background: none;
  padding: 0px;
  border-left: 1px solid #d3d3d3;
  padding-left: 40px;
}

.closes_lists ul {
  margin: 0px auto;
  padding: 0px;
  list-style: none;
  text-align: left;
}

.closes_lists li {
  float: none;
  display: block;
  text-align: left;
  font-size: 11px;
  font-weight: 600;
  line-height: 21px;
  color: #054f61;
  text-decoration: underline;
}

.closes_lists li a {
  color: #054f61;
  text-decoration: underline !important;
}

.auction_society {
  width: 100%;
  background: none;
  padding: 25px 20px;
  position: relative;
}

.auction_society h3 {
  text-align: left;
  font-size: 18px;
  font-weight: 600;
  color: #1d1617;
  margin-bottom: 10px;
  font-family: "AvenirNext";
}

.auction_society h6 {
  text-align: left;
  font-size: 11px;
  color: #a5a5a5;
  margin-bottom: 3px;
}

.auction_society p {
  margin-bottom: 0;
  text-align: left;
  font-size: 12px;
  color: #222;
}

.auction_society button {
  background: #fff;
  margin-top: 10px;
  color: #fff;
  font-size: 11px;
  padding: 10px 30px;
  border-radius: 3px;
  font-weight: 600;
  outline: none !important;
  border: 2px solid var(--primary-btn-clr);
  color: var(--primary-btn-clr);
}

.stylized-underlined-link {
  position: absolute;
  top: 20px;
  right: 0;
  /* background-color: var(--primary-blue-clr);
  color: #fff;
  border-radius: 100%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center; */
  text-decoration: underline 1px solid rgba(0, 0, 0, 0.2) !important;
  color: #0d0a0a;
  font-size: 12px;
  font-weight: 600;
}

.auction_links {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.auction_links p {
  margin-bottom: 0;
}

.auction_links p a {
  font-size: 12px;
  font-weight: 600;
  color: #0d0a0a;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.ourlots_sec {
  width: 100%;
  background: none;
  padding: 0px 20px 25px;
}

.ourlots_sec h4 {
  text-align: left;
  font-size: 15px;
  color: #000;
  font-weight: 600;
  margin-bottom: 3px;
}

.lots_card {
  box-sizing: border-box;
  background: #fffcfb;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  border-radius: 10px;
  padding-left: 10px;
  flex: 1 1;
  display: flex;
  align-items: center;
  /* font-family: "AvenirNext"; */
  font-style: normal;
  font-weight: 600;
  min-height: 150px;
  margin-bottom: 10px;
  margin-top: 10px;
}

.lots_card h6 {
  /* text-align: left;
  font-size: 18px;
  font-weight: 600;
  color: #444;
  font-family: "AvenirNext";
  padding-left: 10px; */
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.28px;
  font-family: 'Instrument Sans';
}
.lots_card .banner{
  background: #f2f2f2;
  /* width: 120px; */
  /* height: 120px; */
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-radius: 5px;

  /* new styles */
  width: 147px;
  height: 147px;
  border-radius: 25px;
}


/* .lots_card p {
  margin-bottom: 0;
  text-align: left;
  font-size: 13px;
  font-weight: 300;
} */
.lots_card .price_details{
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding: 0 10px; */
}
.lots_card p span {
  font-weight: 600;
}

.lots_card .img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.search_card_price {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.search_card_price > .price_text {
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.36px;
  font-family: Instrument Sans;
}

.search_card_price > .price {
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.36px;
  font-family: Instrument Sans;
}

.search_card_price > .list_price_text {
  color: var(--charcoal-50, rgba(48, 49, 58, 0.50));
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.36px;
  text-decoration: line-through;
  font-family: Instrument Sans;
}

.quantity_left_text {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; 
}

@media screen and (max-width:400px) {
  .search_card_price {
    align-items: flex-start;
    flex-direction: column;
  }
}

.aboutpics {
  width: 100%;
  background: none;
  padding: 0px;
  position: relative;
}

.aboutpics .causeDetails {
  position: absolute;
  top: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #fff;
  width: 100%;
}

.overlay-pics {
  background: rgba(25, 25, 27, 0.73);
}

.aboutpics .causeDetails h4 {
  font-size: 20px;
  font-family: "AvenirNext";
}

.aboutpics .causeDetails p {
  font-size: 15px;
  margin-bottom: 0;
}

.about_content h5 {
  font-size: 17px;
  font-weight: 600;
}

.about_content p {
  font-size: 14px;
}

.aboutpics img {
  width: min(100vw, 450px);
  display: block;
  margin: auto;
  height: 250px;
  object-fit: cover;
  position: relative;
  left: -15px;
}

.about_content {
  width: 100%;
  background: none;
  padding: 25px 20px;
}

.about_content h4 {
  text-align: left;
  font-size: 16px;
  margin-bottom: 10px;
  color: #111;
  font-weight: 600;
}

.about_content p {
  /* margin-bottom: 0px; */
  text-align: justify;
  font-size: 12px;
  color: #0d0a0a;
}

.about_content iframe {
  margin-top: 20px;
}

.donate_link {
  border-radius: 5px;
  border: 2px solid var(--primary-blue-clr);
  border-radius: 5px;
  color: var(--primary-blue-clr);
  padding: 12px 20px;
  font-size: 13px;
  background-color: #fff;
  font-weight: 600;
}

.news_link {
  border-radius: 5px;
  border-radius: 5px;
  color: #fff;
  padding: 12px 20px;
  font-size: 13px;
  background-color: var(--primary-blue-clr);
  font-weight: 600;
}

.return_link {
  font-size: 13px;
  color: #0d0a0a;
  text-decoration: underline !important;
  font-weight: 600;
}

.iframe_pics {
  width: 100%;
  background: none;
  padding: 15px 0px;
}

.iframe_pics ul {
  margin: 0px auto;
  padding: 0px;
  list-style: none;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.iframe_pics li {
  float: none;
  display: inline-block;
}

.iframe_pics li img {
  display: block;
  margin: auto;
  width: 100px;
}

.about_content button {
  display: block;
  margin-top: 10px;
  background: linear-gradient(180deg, #00526b 0%, #0098b9 100%);
  width: 100%;
  border: none;
  color: #fff;
  padding: 18px 20px;
  outline: none;
  border-radius: 15px;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 13px;
}

.auct_exp {
  padding: 30px 10px;
}

.auct_exp .closes_lists {
  padding: 0;
  border: none;
  width: 100%;
}

.auct_exp ul {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.lot_navigator {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.nav_btn {
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.lot_no {
  font-size: 16px;
  /* font-family: "AvenirNext"; */
  font-weight: 800;
}

.lot_details h3 {
  font-size: 17px;
  /* font-family: "AvenirNext"; */
  line-height: 25px;
  letter-spacing: 0.035em;
}

.lot_details .reserve_met {
  font-size: 12px;
  color: #fff;
  background: #0d0a0a;
  border-radius: 15px;
  display: inline-block;
  padding: 5px 15px;
  margin: 10px 0;
}

.lot_stuff_row {
  /* display: flex;
  justify-content: space-between; */
  margin-bottom: 6px;
  margin-top: 6px;
  /* flex-wrap: wrap;
  row-gap: 10px; */
}

.lot_stuff_row p {
  /* font-size: 14px; */
  margin: 0;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.36px;
}

.lot_stuff_row h3 {
  font-family: 'Arial Bold';
  margin: 0;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.36px;
}

.lot_details button {
  background: #fff;
  margin-top: 10px;
  color: #fff;
  font-size: 12px;
  padding: 10px 32px;
  border-radius: 3px;
  font-weight: 600;
  outline: none !important;
  border: 2px solid var(--primary-btn-clr);
  color: var(--primary-btn-clr);
}

.lot_details button {
  padding: 2px 10px;
  border-radius: 25px;
  margin-top: 0 !important;
}

.lot_page .auction_banner {
  width: 100%;
  margin-top: 15px;
  /* left: 0; */
}

.lot_page .carousel.carousel-slider {
  overflow: visible;
}

.lot_page .carousel .control-dots {
  /* bottom: -40px !important; */
  bottom: 7px !important;
}

.lot_page .carousel .control-dots .dot {
  /* border: 0.5px solid black; */
  width: 8px !important;
  height: 8px !important;
  /* margin: 0 6px; */
  margin: 0 15px;
}

.lot_page .carousel .control-dots .dot.selected {
  background: var(--primary-blue-clr) !important;
}

.lot_page .accordion {
  border-left: 1px solid #eee;
  border-right: 1px solid #eee;
  border-top: 1px solid #eee;
  border-radius: 8px;
}

.lot_page .accordion-button::after {
  display: none;
}

.lot_page .accordion-body {
  padding: 10px;
  font-size: 15px;
}

.lot_page .accordion-button:not(.collapsed) img {
  transform: rotate(-180deg);
}

.lot_page .accordion-item {
  border: none;
  border-bottom: 1px solid #eee;
}

.lot_page .accordion-button {
  padding: 15px 10px;
  border: none;
  outline: none;
  box-shadow: none;
  color: #0d0a0a;
  font-weight: 600;
  font-size: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.lot_page .accordion-button:not(.collapsed) {
  color: #0d0a0a !important;
  font-weight: 600 !important;
  background: #fff;
}

.lot_contact {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 30px 10px 100px 10px;
}

.lot_contact p {
  margin: 0;
}

.lot_contact img {
  height: 25px;
}

.bid_conf_page .header_sec img {
  width: 14px !important;
}

.bid_conf_page .num_days {
  margin: 20px auto;
  display: flex;
  justify-content: center;
}

.bid_conf_page .num_days p {
  font-size: 13px;
  margin: 0;
  font-weight: 600;
  border: 1px solid var(--primary-blue-clr);
  border-radius: 6px;
  padding: 5px 18px;
}

.bid_conf_page .bid_img {
  text-align: center;
  margin-bottom: 30px;
}

.bid_conf_page .lot_details h3 {
  margin-bottom: 40px;
}

.bid_conf_page .bid_img img {
  width: 60%;
}

.bid_conf_page .max_bid_inp {
  background-color: #e6e7ea;
  width: 100vw;
  position: relative;
  left: -15px;
  padding: 15px;
  border-top: 0.5px solid black;
  border-bottom: 0.5px solid black;
}

.bid_conf_page .max_bid_inp > p {
  font-size: 13px;
  color: var(--primary-blue-clr);
  font-weight: 600;
  margin-bottom: 5px;
}

.bid_conf_page .max_bid_inp input {
  width: 100%;
  background: none;
  outline: none;
  border: none;
  padding: 0;
  padding-left: 5px;
}

.bid_conf_page .max_bid_input_field {
  display: flex;
  align-items: center;
  font-size: 16px;
  border-bottom: 1px solid #4a4848;
}

.max_bid_input_field p {
  margin: 0;
}

.bid_conf_page .links {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  margin-bottom: 90px;
}

.bid_conf_page .links a {
  color: var(--primary-blue-clr);
  margin: 2px 0;
  font-size: 14px;
}

.congrats_page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.congrats_page .bid_img img {
  width: 60%;
  margin: 10px 0 40px 0;
}

.congrats_page .heading {
  font-size: 18px;
  color: var(--primary-blue-clr);
  font-weight: 600;
}

.congrats_page .links a {
  display: inline-block;
  width: 100%;
  border: 2px solid var(--primary-btn-clr);
  margin-top: 15px;
  font-size: 14px;
  color: #fff;
  background: var(--primary-btn-clr);
  border-radius: 5px;
  padding: 10px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.45);
}

.acc_page .header_sec {
  box-shadow: none;
}

.acc_page .user_info {
  color: #fff;
  background-color: var(--primary-blue-clr);
  /* padding: 15px; */
  padding-top: 30px;
  /* box-shadow: 0px 2px 7px -1px rgba(0, 0, 0, 0.63); */
}

.acc_page .user_info h3 {
  font-size: 20px;
  font-family: "AvenirNext";
}

.acc_page .user_info p {
  font-size: 14px;
  margin-bottom: 10px;
  margin-top: 12px;
}

.acc_page .acc_links {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}

.acc_page .acc_links a {
  padding: 15px 0;
  border-bottom: 0.5px solid #4a484897;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 15px;
  color: #000;
}

.acc_page .acc_links .logout_option {
  padding: 15px 0;
  border-bottom: 1px solid #F0E8E3;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  color: #F0E8E3;
  cursor: pointer;
}

.acc_page .acc_links img {
  height: 13px;
  margin-right: 3px;
}

.auctionMainPage .auction_card h5 {
  font-size: 14px !important;
}

.auctionMainPage .auction_card {
  box-shadow: 1px 1px 4px 2px rgba(123, 116, 116, 0.9);
}

.auctionMainPage .header {
  position: sticky;
  top: 0;
  z-index: 9999;
  background: #fff;
}

.auctionMainPage .header div:nth-child(2) {
  display: flex;
  justify-content: space-between;
  padding: 20px 15px 0 15px;
}

.auctionMainPage main {
  padding: 0px 24px 20px 24px;
}

.slider_sec .carousel .control-dots .dot {
  width: 12px !important;
  height: 12px !important;
  margin: 0 3px !important;
}

.overview_btn {
  width: 100%;
  background: none;
  padding: 0px 0px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.overview_btn button {
  display: inline-block;
  width: 90px;
  font-size: 7px;
  padding: 10px 5px;
  border-radius: 5px;
  margin-top: 0px;
}

.overview_slider {
  width: 100%;
  background: url("./images/banner7.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
  padding: 35px 60px;
  min-height: 250px;
}

.overview_slider img {
  max-width: 130px;
  height: auto;
  margin-bottom: 45px;
}

.overview_slider h1 {
  color: #fff;
  font-weight: 700;
  font-size: 27px;
  text-align: center;
  margin-bottom: 10px;
}

.overview_slider h6 {
  color: #fff;
  text-align: center;
  font-size: 13px;
  font-weight: 400;
}

.aboutpics .carousel .control-dots .dot {
  width: 12px !important;
  height: 12px !important;
  margin: 0 3px !important;
  background: #fff !important;
  opacity: 1 !important;
}

.aboutpics .carousel .control-dots .dot.selected,
.aboutpics .carousel .control-dots .dot:hover {
  opacity: 1;
  filter: alpha(opacity=100);
  background: linear-gradient(180deg, #00526b 0%, #0098b9 100%) !important;
}

.aboutpics .carousel.carousel-slider .control-arrow {
  display: none !important;
}

.overview_feature {
  width: 100%;
  background: none;
  padding: 0px;
  position: relative;
}

.feature_overBanner {
  width: 100%;
  background: url("./images/bannerCard1.png");
  padding: 30px 20px;
  background-repeat: no-repeat;
  background-size: cover;
}

.feature_banner2 {
  background: url("./images/bannerCard2.png") !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
}

.feature_banner3 {
  background: url("./images/bannerCard3.png") !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
}

.feature_banner4 {
  background: url("./images/bannerCard4.png") !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
}

.feature_overBanner h1 {
  margin-bottom: 0;
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  text-align: left;
}

.feature_overContent {
  width: 100%;
  background: none;
  padding: 20px 15px;
}

.feature_overContent ul {
  margin: 0px auto;
  padding: 0px;
  list-style: none;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.feature_overContent li {
  float: none;
  display: inline-block;
}

.feature_overContent li img {
  width: 100%;
  display: block;
  margin: auto;
  margin-bottom: 20px;
}

.feature_overContent li h5 {
  text-align: left;
  font-size: 13px;
  margin-bottom: 5px;
  font-weight: 600;
}

.feature_overContent li h5 a {
  text-decoration: none;
  color: #000;
}

.feature_overContent li p {
  text-align: left;
  font-size: 13px;
  margin-bottom: 5px;
  font-weight: 400;
}

.feature_overContent li h4 {
  text-align: left;
  font-size: 13px;
  margin-bottom: 5px;
  font-weight: 700;
  color: #111;
}

.auction_lots .closes_inside h1 {
  margin-bottom: 0;
  font-size: 17px;
  font-weight: 600;
  text-align: left;
}

.auction_lots .closes_inside {
  padding: 15px 25px;
  margin-top: -30px;
  min-height: 70px;
}

.auction_lots .carousel .control-dots {
  bottom: 40px !important;
  margin: 0px auto !important;
}

.lots_section {
  width: 100%;
  background: none;
  padding: 30px 20px;
  position: relative;
}

.lots_parisdata {
  width: 100%;
  background: none;
  padding: 0px;
}

.lots_parisdata h3 {
  text-align: left;
  font-size: 17px;
  font-weight: 600;
  color: #333;
  margin-bottom: 10px;
}

.lots_parisdata p {
  text-align: left;
  font-size: 13px;
  color: #969ca3;
  margin-bottom: 2px;
  font-weight: 500;
}

.lots_parisdata ul {
  margin: 0px auto;
  padding: 0px;
  list-style: none;
  text-align: left;
}

.lots_parisdata li {
  float: none;
  display: block;
  font-size: 13px;
  color: #828282;
  font-weight: 300;
  line-height: 20px;
}

.lots_parisdata button {
  background: linear-gradient(180deg, #0292aa 0%, #01709b 100%);
  margin-top: 10px;
  border: none;
  color: #fff;
  font-size: 12px;
  padding: 8px 27px;
  border-radius: 3px;
  font-weight: 600;
  outline: none !important;
}

.go_home button {
  background: var(--primary-btn-clr);
  margin-top: 20px;
  border: none;
  color: #fff;
  font-size: 12px;
  padding: 8px 27px;
  border-radius: 3px;
  font-weight: 600;
  height: 36px;
  border-radius: 6px;
  width: 40%;
  outline: none !important;
}

.lots_descriptions {
  width: 100%;
  background: none;
  padding: 30px 0px 20px;
}

.lots_descriptions h2 {
  text-align: left;
  font-size: 17px;
  font-weight: 600;
  color: #222;
  margin-bottom: 10px;
}

.lots_descriptions p {
  text-align: justify;
  font-size: 13px;
  color: #969ca3;
  margin-bottom: 2px;
  font-weight: 400;
}

.about_supporting {
  width: 100%;
  background: none;
  padding: 15px 15px;
  box-shadow: 0px 0px 4px 0px #00000024;
  border-radius: 5px;
}

.about_supporting h4 {
  text-align: left;
  font-size: 16px;
  font-weight: 500;
  color: #333;
  margin-bottom: 15px;
}

.about_supporting ul {
  margin: 0px auto;
  padding: 0px;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.about_supporting li {
  float: none;
  display: inline-block;
  width: 30%;
  text-align: center;
}

.about_supporting li img {
  display: block;
  margin: auto;
  width: 25px;
  height: 25px;
  margin-bottom: 20px;
  object-fit: contain;
}

.about_supporting li button {
  background: none;
  border: 1px solid #00526b;
  font-size: 12px;
  padding: 4px 15px;
  border-radius: 50px;
  font-weight: 600;
  color: #00526b;
  width: 100%;
}

button.placeBid {
  width: 90%;
  margin-top: 20px;
  background: linear-gradient(180deg, #00526b 0%, #0098b9 100%);
  border: none;
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  padding: 18px 20px;
  border-radius: 20px;
  outline: none !important;
}

.bid_banner {
  width: 100%;
  background: url("./images/bid_banner.svg");
  padding: 25px 20px;
  margin-top: 7px;
  background-repeat: no-repeat;
  background-size: cover;
}

.bid_banner h1 {
  margin: 0px;
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  color: #fff;
}

.bid_content {
  width: 100%;
  background: none;
  padding: 30px 20px;
}

.bid_content ul {
  margin: 0px auto;
  padding: 0px;
  list-style: none;
  margin-bottom: 20px;
}

.bid_content li {
  float: none;
  display: block;
  text-align: left;
  font-size: 14px;
  line-height: 30px;
  color: #888;
}

.bid_content li span {
  color: #000;
  margin-left: 10px;
  font-weight: 500;
}

.bid_content label {
  display: block;
  margin-bottom: 10px;
  font-size: 15px;
  font-weight: 600;
}

.bid_content .form-control {
  background: #f7f8f8;
  border: 1px solid #ececec;
  border-radius: 10px;
  height: auto;
  padding: 13px 20px;
  outline: none !important;
  font-size: 14px;
  box-shadow: none !important;
}

.bid_content button {
  margin-top: 0px;
}

.bid_history {
  width: 100%;
  background: none;
  padding: 5px 20px 25px;
}

.bid_history ul {
  margin: 0px;
  padding: 0;
  list-style: none;
}

.bid_history li {
  float: none;
  display: block;
  font-size: 14px;
  font-weight: 600;
  line-height: 26px;
  color: #00526b;
  text-decoration: underline;
}

.bid_views {
  width: 100%;
  background: none;
  padding: 30px 20px;
}

.bid_views h4 {
  text-align: center;
  font-size: 16px;
  margin-bottom: 10px;
  color: #232323;
  font-weight: 600;
}

.bid_views h3 {
  text-align: center;
  font-size: 19px;
  margin-bottom: 10px;
  color: #232323;
  font-weight: 600;
}

.bid_views p {
  text-align: center;
  font-size: 13px;
  margin-bottom: 10px;
  color: #464646;
  font-weight: 400;
}

.bid_views img.viewPic {
  display: block;
  margin: auto;
  margin-top: 25px;
  margin-bottom: 20px;
  width: 165px;
}

.followus_sec {
  width: 100%;
  background: none;
  padding: 20px 20px;
  box-shadow: 0px 1px 4px 0px #00000017;
  border-radius: 10px;
  margin-top: 20px;
}

.followus_sec ul {
  margin: 0px;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.followus_sec li {
  float: none;
  display: inline-block;
  text-align: center;
}

.followus_sec li img {
  width: 30px;
}

.followus_sec h6 {
  text-align: center;
  font-size: 13px;
  font-weight: 700;
  margin-bottom: 25px;
}

.billing_info_sec {
  width: 100%;
  background: none;
  padding: 35px 20px;
}

.billing_info_sec h4 {
  text-align: left;
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: 600;
  color: #333;
}

.billing_info_sec p {
  font-size: 13px;
  text-align: justify;
  font-weight: 400;
  color: #666;
  line-height: 23px;
}

.weaccept {
  width: 100%;
  background: none;
  padding: 15px 15px;
  box-shadow: 0px 0px 4px 0px #80808024;
  border-radius: 5px;
  margin-bottom: 20px;
}

.weaccept ul {
  margin: 0px;
  padding: 0px;
  list-style: none;
  text-align: left;
}

.weaccept li {
  float: none;
  display: inline-block;
  margin-right: 15px;
}

.weaccept li img {
  width: 34px;
  height: 25px;
  object-fit: cover;
}

.weaccept h6 {
  text-align: left;
  font-size: 14px;
  margin-bottom: 10px;
  color: #444;
}

.billing_info_sec label {
  display: block;
  margin-bottom: 5px;
  font-size: 13px;
  font-weight: 500;
}

.billing_info_sec .form-control {
  height: auto;
  background: #f7f8f8;
  border: 1px solid #ebefef;
  padding: 11px 20px;
  text-align: left;
  font-size: 14px;
  border-radius: 10px;
  outline: none;
  box-shadow: none;
}

.billing_info_sec .form-group {
  margin-bottom: 10px;
}

.billing_address {
  width: 100%;
  background: none;
  padding: 25px 0px 20px;
}

.billing_address h5 {
  text-align: left;
  font-size: 15px;
  font-weight: 600;
  color: #333;
  margin-bottom: 6px;
}

.billing_address p {
  margin-bottom: 0;
  text-align: left;
}

.confirmation_sec {
  width: 100%;
  background: url("./images/confirm_banner.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  min-height: calc(100vh - 0px);
  position: relative;
  padding: 40px 20px;
  display: flex;
  flex-wrap: wrap;
}

.confirmation_sec img {
  display: block;
  margin: auto;
  width: 115px;
  margin-bottom: 25px;
}

.confirmation_sec h3 {
  text-align: center;
  color: #ffff;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 10px;
}

.success_data {
  width: 100%;
}

.confirmation_sec h4 {
  text-align: center;
  color: #ffff;
  font-weight: 700;
  font-size: 20px;
  margin-bottom: 10px;
}

.confirmation_sec p {
  text-align: center;
  color: #ffff;
  font-weight: 400;
  font-size: 17px;
  margin-bottom: 25px;
}

.confirmation_sec h6 {
  text-align: center;
  color: #ffff;
  font-weight: 400;
  font-size: 15px;
  margin-bottom: 30px;
}

.riturn_auction {
  width: 100%;
  background: none;
  padding: 25px 0px 0px;
  display: flex;
  flex-direction: column-reverse;
}

.browse_slider {
  width: 100%;
  background: none;
  padding: 0px;
}

.browse_slider ul {
  margin: 0px auto;
  padding: 0px;
  list-style: none;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.browse_slider li {
  float: none;
  display: inline-block;
}

.browse_slider li img {
  width: 100%;
  display: block;
  margin: 0px auto;
}

.browse_slider li h4 {
  margin-bottom: 0px;
  margin-top: 13px;
  font-size: 13px;
  text-decoration: underline;
  color: #00526b;
  text-align: center;
  font-weight: 700;
}

.category_container {
  display: flex;
  overflow-y: hidden;
  overflow-x: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  margin-bottom: 30px;
  gap: 20px;
  position: relative;
}

.category_container::-webkit-scrollbar {
  display: none;
}

.category_container img {
  display: block;
  margin: auto;
  /* width: 95px; */
  width: 100px;
}

.category_container h6 {
  margin-bottom: 0px;
  margin-top: 10px;
  text-align: center;
  font-weight: 600;
  color: #333;
  font-size: 13px;
}

/* .cause_container {
  display: flex;
  overflow-y: hidden;
  overflow-x: scroll;
  -ms-overflow-style: none; /* IE and Edge */
   /* scrollbar-width: none; Firefox 
}  */

/* 
.cause_container::-webkit-scrollbar {
  display: none;
} */

/* .cause_container img{
    width: 100%;
    display: block;
    margin: 0px auto;
} */

.cause_container h4 {
  margin-bottom: 0px;
  margin-top: 13px;
  font-size: 13px;
  text-decoration: underline;
  color: #00526b;
  text-align: center;
  font-weight: 700;
}

.cancel_transparent {
  background: transparent !important;
  border: 2px solid #007896 !important;
  color: #006b86 !important;
  padding: 16px 20px !important;
}

.home_search label {
  display: block;
  margin-bottom: 10px;
  font-weight: 500;
  font-size: 14px;
}

.auction_slider {
  width: 100%;
  background: none;
  padding: 0px;
  position: relative;
  margin-bottom: 25px;
  margin-top: 15px;
}

.auction_slider a.goDashboard {
  display: block !important;
}

.auction_slider .control-next {
  opacity: 0 !important;
}

.auction_slider .slider_btns li a {
  position: relative;
  font-weight: 600;
  font-size: 13px;
  display: flex;
  align-items: center;
}

.slider_btns ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.auction_slider .slider_btns li a img {
  position: relative;
  top: 2px;
  margin: 0 3px;
}

.auction_slider .slider_btns {
  bottom: -6px;
}

.auction_slider .carousel .slider-wrapper.axis-horizontal .slider .slide {
  position: relative;
  top: -4px;
  font-size: 13px;
  font-weight: 600;
}

.auction_slider .carousel.carousel-slider .control-arrow {
  display: block !important;
  opacity: 0 !important;
  z-index: 9999999;
  width: 105px;
}

.bigImg {
  outline: none;
  position: relative;
  border-radius: 18px;
  margin: 15px 15px;
  margin-left: 0px;
  height: 380px;
}

.bigImg:last-child,
.auction_div:last-child {
  margin-right: 0;
}

.homePage .imgLabel {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 20px;
  text-align: left;
  /* background: linear-gradient(
    180deg,
    rgba(216, 208, 208, 0) 0%,
    rgba(109, 105, 105, 0.11) 37.22%,
    rgba(0, 0, 0, 0.63) 63.76%,
    #000000 100% */
  /* ); */
  border-radius: 9px;
  color: #fff;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
}

.homePage .imgLabel .imgHeading {
  font-family: "AvenirNext";
  font-weight: 800;
  font-size: 16px;
  background-color: #1a385c;
  padding: 6px 14px;
  border-radius: 5px;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
}

.homePage .imgLabel .imgDesc {
  font-size: 14px;
  margin-bottom: 0;
  text-align: left;
  background-color: white;
  border: 1px solid #1a385c;
  font-weight: 600;
  margin-top: 10px;
  padding: 4px 10px;
  border-radius: 4px;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
}

.buy_now_div {
  position: relative;
}

.spec_auc_div {
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  margin: 5px 0px 40px 0px;
  min-width: 65%;
}

.spec_auc_div .imgLabel {
  text-align: center;
  align-items: center;
}

.buy_now_div img,
.spec_auc_div img {
  width: 100%;
}

.bigImg img {
  width: 100%;
  min-height: 100%;
  max-height: 100%;
}

.closing_btn {
  position: absolute;
  bottom: 25px;
  left: 0;
  right: 0;
  margin: auto;
  background: #ffffff;
  border: 1px solid #ffffff;
  color: #000;
  font-size: 12px;
  font-weight: 500;
  padding: 8px 15px;
  border-radius: 5px;
  outline: none !important;
  min-width: 150px;
}

.custom_accordian {
  width: 100%;
  background: none;
  padding: 10px 0px;
  position: relative;
}

.custom_accordian p.accordian_data {
  margin-bottom: 0;
  text-align: justify;
  font-size: 12px;
  color: #444;
  font-weight: 400;
  border-bottom: 1px solid #d2d2d2;
  padding-bottom: 15px;
  margin-bottom: 15px;
}

h4.accordian_btn {
  cursor: pointer;
  position: relative;
}

h4.accordian_btn img {
  float: right;
  width: 15px;
  margin-top: 5px;
  opacity: 0.5;
}

@media (min-width: 576px){
  .container {
    max-width: none;
  }
}

@media screen and (min-width: 300px) and (max-width: 767px) {
  .categories_list li {
    width: 25%;
    margin-bottom: 15px;
  }

  .splace_inside img {
    width: 160px;
  }
  .slider_pics {
    height: 100vh;
    /* height: calc(100vh - 137px); */
    background-size: contain;
  }
  .slider_content {
    padding: 20px 0px;
  }
  .slider_content p {
    font-size: 13px;
    line-height: 24px;
    padding: 0 35px;
  }

  .scrollData {
    width: 100%;
    /* max-height: 215px; */
    overflow: auto;
  }
  .scrollCategory {
    max-height: 320px;
    overflow: auto;
    width: 100%;
    padding-right: 31px;
  }
  .scrollHomedata {
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .authen_pics {
    height: 410px;
    background-size: cover;
  }
  .authen_content {
    padding: 20px 0px;
  }
  .blockBtn {
    margin-top: 5px;
    padding: 12px;
    font-size: 14px;
    font-weight: 600;
  }
  .blockBtn-loading {
    margin-top: 5px;
    padding: 5px;
    font-size: 14px;
    font-weight: 600;
  }
  .authen_content h5 {
    margin-bottom: 10px;
    font-size: 13px;
  }

  .logForm label {
    padding-left: 30px;
    font-size: 12px;
    line-height: 20px;
  }
  .logForm label input[type="checkbox"] {
    width: 20px;
    height: 20px;
    /* border-radius: 3px; */
  }
  .logForm label input[type="checkbox"]:checked:before {
    margin-top: 5px;
    margin-left: 4px;
  }

  .stickDown h6 a {
    font-size: 12px;
  }
  .stickDown {
    margin-top: 20px;
  }

  .header_sec img.headLogo {
    width: 60px;
  }
  .header_sec {
    padding: 10px 15px;
    height: 65px;
  }
  .home_search {
    padding: 15px 0px;
  }
  .current_banner {
    padding: 0px;
  }

  .current_auction {
    padding: 20px 0px;
  }
  .page_heading h3 span {
    font-size: 14px;
  }
  .page_heading h3 {
    /* font-size: 14px; */
    font-size: 22px;
  }
  .auction_card h5 {
    font-size: 10px;
  }
  .auction_card h5 img {
    width: 11px !important;
  }

  .auction_card img.center {
    width: 65px;
    height: 65px;
  }
  .auction_card h3 {
    font-size: 10px;
  }
  .auction_card h1 {
    font-size: 13px;
  }

  /* .auction_card {
    margin-bottom: 20px;
  } */
  .search_field img {
    top: 14px;
    width: 12px;
  }
  .search_field input.form-control {
    padding: 12px 20px;
    font-size: 11px;
    padding-left: 45px;
  }
  .footer_sec li img {
    width: 20px;
    height: 20px;
    object-fit: contain;
  }
  .footer_sec {
    padding: 10px 10px;
    top: calc(100vh - 50px) !important;
    display: block;
  }
  /* .header_sec img {
    width: 15px;
  } */

  .categories_banner {
    padding: 5px 0px 30px;
  }
  .categories_banner .carousel .control-dots .dot {
    width: 10px !important;
    height: 10px !important;
  }
  .categories_sec {
    padding: 10px 0px 10px;
  }
  .categories_list {
    margin-top: 15px;
  }

  .categories_list li img {
    width: 50px;
  }
  .categories_list li h6 {
    font-size: 11px;
    margin-top: 5px;
  }
  .browseby_sec {
    padding: 25px 0px;
  }
  .browse_slider li {
    padding: 0px 5px;
  }

  .browse_slider li h4 {
    margin-bottom: 0px;
    margin-top: 10px;
    font-size: 11px;
  }
  .auction_closes {
    padding: 0px 5px;
  }
  .closes_inside {
    padding: 15px 10px;
  }
  .closes_lists {
    padding-left: 20px !important;
  }
  .closes_lists li {
    font-size: 10px;
    line-height: 18px;
  }

  .closes_countdown {
    padding-left: 10px;
  }
  .auction_society {
    padding: 20px 0px;
  }
  .ourlots_sec {
    padding: 0px 0px 20px;
  }
  .auction_society h3 {
    font-size: 16px;
    margin-bottom: 6px;
  }
  .auction_society p {
    font-size: 13px;
  }
  .auction_society button {
    font-size: 10px;
  }

  .ourlots_sec h4 {
    font-size: 12px;
  }
  

  .aboutpics img {
    height: 185px;
  }
  .about_content {
    padding: 15px 0px;
  }
  .about_content h4 {
    font-size: 14px;
  }
  .about_content p {
    font-size: 13px;
  }

  .iframe_pics li img {
    width: 90px;
  }
  .about_content button {
    padding: 15px 20px;
    font-size: 11px;
    border-radius: 10px;
  }
  .header_sec h3 {
    font-size: 14px;
  }
  .overview_slider {
    min-height: 185px;
  }
  .auction_lots .closes_inside h1 {
    font-size: 15px;
  }
  .auction_lots .closes_inside {
    padding: 15px 15px;
  }

  .lots_section {
    padding: 20px 0px;
  }
  .lots_parisdata button {
    margin-top: 15px;
    padding: 7px 20px;
    font-size: 10px;
  }
  .lots_descriptions h2 {
    font-size: 15px;
  }
  .lots_descriptions p {
    font-size: 11px;
  }
  .about_supporting li button {
    font-size: 9px;
    padding: 5px 5px;
  }
  .about_supporting li img {
    margin-bottom: 10px;
  }

  button.placeBid {
    font-size: 11px;
    font-weight: 700;
    padding: 15px 20px;
    border-radius: 10px;
  }
  .lots_parisdata li {
    font-size: 11px;
    font-weight: 200;
  }
  .lots_parisdata p {
    font-size: 12px;
    font-weight: 400;
  }
  .bid_banner {
    margin-top: 7px;
    padding: 15px 20px;
  }
  .bid_banner h1 {
    font-size: 12px;
  }
  .bid_content {
    padding: 15px 0px;
  }

  .bid_content li {
    font-size: 12px;
    line-height: 22px;
  }
  .bid_content label {
    margin-bottom: 8px;
    font-size: 12px;
  }
  .bid_content .form-control {
    padding: 12px 15px;
    font-size: 11px;
  }
  .bid_history {
    padding: 5px 0px 0px;
  }
  .bid_history li {
    font-size: 11px;
    line-height: 25px;
  }

  main {
    min-height: calc(100vh - 110px);
  }
  .billing_info_sec {
    padding: 20px 0px;
  }
  .billing_info_sec h4 {
    font-size: 16px;
  }
  .billing_info_sec p {
    font-size: 12px;
    line-height: 16px;
  }
  .weaccept h6 {
    font-size: 13px;
  }
  .weaccept {
    padding: 10px 15px;
  }

  .billing_info_sec label {
    font-size: 11px;
  }
  .billing_info_sec .form-control {
    padding: 8px 15px;
    text-align: left;
    font-size: 14px;
    border-radius: 5px;
  }
  .billing_address {
    padding: 10px 0px 10px;
  }
  .billing_address h5 {
    font-size: 13px;
  }
  .cancel_transparent {
    padding: 10px 20px !important;
  }
  .carousel img {
    min-height: 185px !important;
    /* min-height: 221px !important; */
    /* object-fit: cover; */
    border-radius: 15px;
    width: 96% !important;
  }
}

@media screen and (max-width:768px) {
  .logForm label input[type="checkbox"]:checked:before {
    /* margin-top: 5px;
    margin-left: 4px; */
    height: 5px;
    width: 9px;
    margin-top: 2px;
    margin-left: 1px;
  }
  .acc_page .user_info {
    padding: 15px;
}
}

@media screen and (max-width: 299px) {
  .footer_sec {
    display: block;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .footer_sec {
    display: block;
  }
  .carousel img {
    height: 250px;
    object-fit: cover;
  }
}

/* @media screen and (min-width: 768px) {
  #App {
    display: var(--display-app) !important;
  }
} */

.error-label {
  font-size: 10px;
  color: red !important;
  margin-left: 5px;
  padding-left: 0 !important;
}

.error-field {
  border-color: red !important;
  background-color: #ff363629 !important;
}

.error_msg {
  color: #ff3636e3 !important;
}

.success_response_msg {
  color: #00b300 !important;
  margin-top: 30px;
}

.reg_verify_text {
  margin-top: 15px;
  background: #00b30012;
  color: #00b300;
  border-radius: 4px;
  border: 0.5px solid;
  padding: 10px 12px !important;
  text-align: left;
  font-size: 12px;
}

.error_response_msg {
  color: #ff3636e3 !important;
  margin-top: 30px;
  font-size: 12px;
}

.lot_footer {
  top: calc(100vh - 60px) !important;
  display: flex;
  /* flex-direction: column; */
  justify-content: center;
  z-index: 9999;
}

.lot_footer_one {
  /* top: calc(100vh - 110px) !important; */
}

.lot_footer > div {
  color: #fff;
  background: var(--primary-blue-clr);
  padding: 10px;
  width: 100%;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  border-radius: 5px;
}

.bid_conf_page_v2 .header_sec img {
  width: 24px !important;
}

.lot_footer.dontAllow > div {
  background-color: #e6e7ea;
  color: #a2a4aa;
}

@keyframes blink {
  50% {
    color: transparent;
  }
}
.loader__dot {
  animation: 1s blink infinite;
  font-size: 20px;
}
.loader__dot:nth-child(2) {
  animation-delay: 250ms;
}
.loader__dot:nth-child(3) {
  animation-delay: 500ms;
}

.splace_loader {
  font-size: 50px !important;
}

.faq_text_container {
  overflow-y: scroll;
  overflow-x: hidden;
  height: 50vh;
}
.faq_text_container::-webkit-scrollbar {
  width: 0 !important;
}
.faq_text_container {
  overflow: -moz-scrollbars-none;
}

.auction_page .carousel.carousel-slider {
  overflow: visible;
}

.auction_page .carousel .control-dots {
  bottom: -25px !important;
}

.auction_page .carousel .control-dots .dot {
  border: 0.5px solid black;
  width: 8px !important;
  height: 8px !important;
  margin: 0 6px;
}

.auction_page .carousel .control-dots .dot.selected {
  background: var(--primary-blue-clr) !important;
}

.auction_page .auction_banner {
  width: 100%;
  left: 0;
  margin: 0;
}

.auction_page .carousel .slide img {
  left: 0;
}

.auction_page .aboutpics img {
  left: 0;
  opacity: 60%;
}

.auction_page .image-wrapper {
  background: black;
}

.about-cause-page .aboutpics {
  background-color: black;
}

.about-cause-page .aboutpics img {
  opacity: 60%;
  width: 100%;
  left: 0;
}

.about-cause-page-banner {
  width: 100vw;
  padding: 0px;
  padding-top: 150px;
  position: relative;
}

.about-cause-page-banner .carousel .control-dots {
  bottom: 0px !important;
}

.theme_button {
  background-color: #ff0000;
  color: #fff;
  border-radius: 25px;
  font-weight: bold;
  font-family: "alternategotno1dregular";
  font-size: 32px !important;
  line-height: 1;
  padding: 12px 40px;
  min-width: 200px;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.3px;
}

@media screen and (min-width: 768px) {
  .congrats_page .container {
    width: 75vw;
    margin: 0 auto;
    margin-bottom: 80px;
  }

  .congrats_page .container .bid_img {
    width: 50% !important;
    margin: 0 auto;
  }
  .acc-container{
    width: 65%;
    padding:6% 0;
  }
  .about-cause-page-banner {
    padding-top: 0;
  }
}

/* The customCheckbox */
.customCheckbox {
  display: block;
  position: relative;
  padding-left: 26px;
  margin-bottom: 10px;
  cursor: pointer;
  font-size: 14px;
  line-height: 1.2;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #8f8f8f;
  font-weight: 500;
}

.customCheckbox:hover {
  color: #FF0000;
}

.customCheckbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.customCheckbox .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 18px;
  width: 18px;
  background-color: white;
  border-radius: 6px;
  border: 2px solid #8F8F8F;
}

.customCheckbox:hover input ~ .checkmark,
.customCheckbox input:checked ~ .checkmark {
  background-color: #FF0000;
  border-color: #FF0000;
}

.accordionSep {
  border-color: rgb(134, 133, 133, 0.3);
}

.Mui-expanded[aria-expanded="true"] span {
  font-weight: 700;
}

.customAccordion {
  padding-top: 20px;
}