.sub_header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 12px;
    /* position: sticky; */
    gap: 9px;
    /* z-index: 1000; */
    z-index: 10;
    margin-top: 50px;
}

.sub_header>p {
    text-align: center;
    font-family: 'DM Mono';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.32px;
    margin: 0;
}
.sub_header>span {
    cursor: pointer;
   }


@media screen and (max-width: 768px) {
    .sub_header {
        width: 100%;
        /* position: fixed; */
        margin-top: 10px;
    }
    .sub_header>p {
        margin: auto 16px;
    }
}

  
@media screen and (min-width:768px) {
    .sub_header {
        margin-inline: 5%;
        padding-inline: 0 !important;
        justify-content: start;
    }
    .sub_header>span {
        margin-left: -42px;
        color: red !important;
       }
}
@media screen and (min-width:980px) {
    .sub_header {
      margin-inline: 10%;
      padding-inline: 0;
    }
  }