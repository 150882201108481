.categoryCardContainer {
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: flex-start;
    align-items: center;
}

.categoryImageContainer {
    height: 180px;
    width: 180px;
    /* height: 225px;
    width: 187.5px; */
    /* height: 125px;
    width: 200px; */
    border-radius: 25px;
}

.categoryImage {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 25px;
}

.categoryCardBottom {
    max-width: 180px;
}

.categoryCardBottom > p {
    font-size: 16px;
    font-weight: 600;
    text-align: center;
}

@media screen and (max-width:768px) {
    .categoryImageContainer {
        height: 140px;
        width: 140px;
    }
    .categoryCardBottom {
        max-width: 140px;
    }
}