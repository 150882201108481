:root {
  --primary-blue-clr: #1a385c;
  --charcol: #30313A;
}

.browse-card {
  background: #EFE9E4;
  /* box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1); */
  /* border-radius: 10px; */
  padding-left: 10px;

  flex: 1;
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: 600;
  min-height: 150px;
 
}

.browse-card .banner {
  width: 147px;
  height: 147px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-radius: 25px;
}

.browse-card .banner img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.browse-card-details {
  padding: 0 10px;
  flex: 1;
  width: 100%;
}

.browse-card .heading {
  font-size: 14px;
  line-height: 20px;
  color: #30313A;
  font-weight: 700;
  flex: 1;
  align-items: center;
  margin-bottom: 15px;
  height: 40px;
  overflow: hidden; 
}

.browse-card button {
  box-shadow: 0px 4px 10px rgba(26, 184, 99, 0.1);
  border-radius: 5px;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  color: #ffffff;
  height: 26px;
}

.browse-card .action-footer__buy {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.browse-card .action-footer__buy .details {
  flex: 1;
  display: flex;
  align-items: baseline;
  margin-right: 4px;
}

.browse-card .action-footer__buy .price {
  /* font-size: 13px; */
  line-height: 13px;
  font-weight: 800;
}

.browse-card .action-footer__buy .currency {
  font-size: 6px;
  line-height: 6px;
}

.browse-card .action-footer__buy .buy-action {
  flex: 1;
  background: var(--primary-blue-clr);
}

.browse-card .action-footer__bid {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.browse-card .action-footer__bid .details {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  align-items: baseline;
  margin-bottom: 6px;
  flex-wrap: wrap;
  column-gap: 5px;
  row-gap: 8px;
  font-size:18px;
  font-weight: 700;
}

.details {
  /* display: flex; */
  column-gap: 5px;
  /* flex-direction: row-reverse; */
  width: 100%;
  /* justify-content:start; */
  display: grid;
  grid-template-columns: 1fr 3fr;
}

.red_details {
    display: flex;
    column-gap: 5px;
}

.browse-card .action-footer__bid .bid-text {
  /* font-weight: 600;
  font-size: 16px; */
  line-height: 10px;
  margin-right: 4px;
  margin-top: 6px;
  width: 80%;
}

.browse-card .action-footer__bid .price {
  font-family: 'Instrument Sans Bold';
 font-weight: 800;
  /* font-size: 20px; */
  line-height: 13px;
  width: 20%;
}

.bid-btn-container button {
  padding: 15px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.browse-card .action-footer__bid .bid-action {
  background: white;
  color: var(--primary-blue-clr);
  border-color: var(--primary-blue-clr);
  border-width: 2px;
}

.browse-card .action-footer__bid .buy-now-btn {
  background: var(--primary-blue-clr);
}

.browse-card img {
  width: 100%;
  height: 40vw;
  object-fit: cover;
  border-radius: 7px;
}

.bid-btn-container {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
}

.btn-adjacent {
  width: 48%;
  font-size: '0.9rem';
}

.line-through {
  /* text-decoration: underline; /*line-through;*/
  /* text-underline-offset: -45%;
  /* text-decoration-skip-ink: none; */
  text-decoration: line-through;
}

.browse-card .action-footer__bid .price .line-through {
  margin-bottom: 4px;
  font-weight: 600;
  font-size: 20px;
}

.action-footer__bid .red-text{
  color: rgb(210, 0, 0);
}

.action-footer__bid .gray-text{
  color: rgb(162, 159, 159);
  font-weight: 800;
}

.min_height_class {
  min-height: 30px;
}

@media screen and (min-width: 768px) {
  .browse-card {
    flex-direction: column;
    margin: 0 60px;
    padding-left: 0;
    box-shadow: none;
    border-radius: 0;
    border: 1px solid #d3d3d3;
    width: 80%;
    border-radius: 9px;
    overflow: hidden;
    justify-self: center;
    cursor: pointer;
  }
  .browse-card .banner {
    width: 100%;
    height: 200px;
    border-radius: 0;
    border-bottom: 1px solid #d3d3d3;
    background: #f6f6f6;
  }
  .browse-card .banner img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  .browse-card .heading {
    text-align: left;
    margin-top: 10px;
    font-size: 0.9rem;
    font-weight: 700;
    height: 60px;
    overflow: hidden;
  }
  .browse-card-details {
    width: 100%;
  }
  .action-footer__bid {
    font-size: 0.9rem;
  }
  .browse-card .action-footer__bid .details {
    flex-direction: row;
    justify-content: space-between;

  }
  .browse-card .action-footer__bid .bid-text {
    width: 80%;
    font-size: 15px;
  }
  .browse-card .action-footer__bid .price {
    font-size: 17px;
    width: 20%;
  }
}

@media screen and (max-width: 768px) {
  .browse-card {
    padding-bottom: 30px;
    border-bottom:1px solid #30313A ;
    row-gap: 30px;
    column-gap: 25px;
  }
  .details {
    max-width: 318px;
  }
  .price {
    min-width: 79px;
  }
  .store_item_price {
    min-width: 58px;
  }
}

@media screen and (min-width: 722px) {
  .browse-card {
    margin: 0;
  }
}

@media screen and (min-width: 768px) {
  .action-footer__bid {
    /* font-size: 0.7rem;
    padding: 13px 0; */
    color: var(--charcol);
    /* font-family: Instrument Sans; */
    font-size: 18px;
    /* font-size: 16px; */
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0.46px;
    display: flex;
    flex-direction: column;
    /* gap: 4px */
  }

  .browse-card .action-footer__bid .bid-text {
    font-size: 14px;
    width: 80%;
  }
  .browse-card .action-footer__bid .price {
    font-size: 16px;
  }
}

@media screen and (max-width: 1680px) {
  .browse-card {
    width: 100%;
  }
}

@media screen and (max-width: 362px) {
  .price {
    min-width: 69px;
  }
  .store_item_price {
    min-width: 44px;
  }
}

@media screen and (max-width:295px) {
  .details {
    display: flex;
    flex-direction: column;
  }
}

/* styles for new desktop card */

.desk_browse_card_header {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.desk_browse_card_heading {
  font-family: 'Instrument Sans Bold';
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.36px;
}

.red_alert {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  margin-top: 10px;
}