.dfooter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #F4F4F4;
    position: relative;
    bottom: 0;
    width: 100%;
}

.dfooter_wrapper {
    width: 85vw;
    margin: 0 auto;
    padding: 20px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.dfooter_wrapper > div {
    display: flex;
    gap: 40px;
    font-weight: 600;
}

.dfooter_wrapper > div div {
    cursor: pointer;
}

.dfooter_wrapper a {
    color: inherit;
}

.dfooter_wrapper a:hover {
    color: #505050;
}

.dfooter_icons {
    gap: 20px !important;
}

.dfooter_icons > img {
    width: 18px;
    height: 18px;
    object-fit: contain;
    cursor: pointer;
}