.createNonProfiteContainer {
    background-color: var(--pure-white);
    min-height: 100vh;
}

.pageMain {
    background-color: var(--pure-white);
    padding-inline: 5vw;
    /* padding-bottom: 100px; */
    padding-top: 200px;
    display: flex;
    flex-direction: column;
    gap: 70px;
}

.pageTitle {
    color: var(--black-color);
    font-size: 46px;
    font-weight: 400;
    line-height: 52px;
}

.createForm {
    width: 60vw;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 20px;
}

.formInputsSection {
    width: 100%;
    display: flex;
    gap: 20px;
    justify-content: space-between;
}


@media screen and (max-width:768px) {
    .pageTitle {
        font-size: 46px;
    }
    
    .createForm {
        width: 100%;
    }

    .formInputsSection {
        flex-direction: column;
        gap: 20px;
    }
}