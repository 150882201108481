.donation-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  gap: 20px;
  background-color: #EFF0F3;
  padding: 80px;
}

.background-image {
  background-image: url('../../../../icons/nonProfit02.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  /* width: 100%;
    height: 300px; */
}


.left-content {
  flex: 1;
  text-align: left;
  margin-bottom: 40px;
}

.left-content h1 {
  font-size: 64px;
  text-align: left;
  font-weight: bold;
  font-family: 'alternategotno1dregular';
  line-height: 72px;
  letter-spacing: 0.5px;
  margin-bottom: 15px;
  color: black;
}

.left-content p {
  line-height: 1.2;
  margin-bottom: 20px;
  color: black;
  font-Size: 28px;
  padding: 5px;
}


.cta-button {
  background-color: #FF0000;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  border-radius: 20px;
  transition: background-color 0.3s ease;
}

.cta-button:hover {
  background-color: darkred;
}

.right-content .card {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: 5px solid #ACACAC;
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.2);
  border-radius: 50px !important;
  padding: 65px;
}

.card-title {
  font-weight: bold;
  margin-bottom: 15px;
  color: black;
  font-family: 'alternategotno1dregular';
  font-size: 66px;
  line-height: 66px;
  text-align: center;
}

.shop-button {
  background-color: var(--red-color);
  font-Weight: bold;
  font-family: 'alternategotno1dregular';
  font-size: 2rem;
  border-Radius: 40px;
  min-width: 190px;
  padding: 5px 20px;
  color: #ffffff;
  transition: background-color 0.3s ease;
  margin-top: 30px;
}

.shop-button:hover {
  background-color: darkred;
}

.btn-holder {
  margin-top: 40px;
}

.right-content {
  float: right;
  max-width: 440px;
}

@media (max-width: 1300px) {
  .right-content .card {
    padding: 50px;
  }
}

@media (max-width: 980px) {
  .right-content .card {
    padding: 40px;
  }

  .right-content .card-title {
    font-size: 55px;
    line-height: 60px;
  }
}

@media (max-width: 899px) {

  .card-title {
    font-size: 40px;
    line-height: 46px;
    letter-spacing: 0.5px;
  }

  .right-content .card {
    padding: 60px;
  }

  .right-content {
    max-width: 400px;
    margin: auto;
    float: none;
  }
}

@media (max-width: 768px) {

  .donation-section {
    padding: 40px;
  }

  .left-content h1 {
    font-size: 60px;
    line-height: 65px;
    letter-spacing: 0.5px;
  }
}

@media (max-width: 500px) {

  .right-content .card {
    padding: 20px;
    border-radius: 30px !important;
    max-width: 300px;
    margin: auto;
  }

  .right-content .card-title {
    font-size: 40px;
    line-height: 35px;
  }
}