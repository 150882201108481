.auction_society {
    padding: 20px 6px 0;
}

.auction_society a {
    position: inherit;
}

.auction_links {
    padding: 6px;
}

.auction_links {
    padding: 6px;
}

.auction-society__heading {
    display: flex;
    justify-content: space-between;
    font-size: 1.1rem;
}

.ticker_container p {
    font-weight: 700;
}

.ticker_container {
    border-radius: 5px !important;
    padding: 20px 10px;
    background-color: rgb(26, 56, 92);
    margin-bottom: 15px;
    color: #ffffff;
}

.ticker_container div {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
}

.ticker_container div>div {
    display: flex;
    flex-direction: column;
}

.achieved {
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 0;
}

.goal {
    margin-bottom: 0;
    /* color: #1a385c; */
    font-size: 13px;
}

.percentage {
    /* color: #d6c08a; */
    font-size: 20px;

}

.percentage_text {
    /* color: #1a385c; */
    font-size: 13px;
}

.auction_links_button {
    font-size: 12px;
    font-weight: 600;
    color: #0d0a0a;
    border-bottom: 1px solid #00000033
}

.copy_url {
    display: grid;
    grid-template-columns: 90% 10%;
    align-items: center;
    border: 1px solid #767676;
    margin-top: 5px;

}

.copy_url>input {
    align-items: center;
    border: none;
    outline: none;
}

.copy_url>div {
    border: none;
}

.copy_url>input:focus {
    border: none;
    outline: none;
}

.about_event_container {
    display: grid;
    grid-template-columns: 90% 10%;
}

.share_button_container {
    display: flex;
    justify-content: center;
}

.mp-banner-container {
    height: 360px;
    width: 100%;
}
.mp-event-banner-contianer{
    padding:5px 6vw ;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.mp-banner-container .mp-banner-text {
    width: 100%;
    height: 100%;
    padding: 20vh 5vw 5vw 5vw;
    /* padding: 10vh 5vw 5vw 5vw; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    z-index: 9;
    position: relative;

}
.mp-banner-container .mp-banner-text-event-auction-and-buynow {
    width: 100%;
    height: 100%;
    padding: 10vh 5vw 5vw 5vw;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    z-index: 9;
    position: relative;

}
.mp-banner-container .mp-banner-text-box-gradient {
    /* background: linear-gradient(180deg, #30313A 0%, rgba(217, 217, 217, 0.00) 190%);
    mix-blend-mode: hard-light; */
    background: linear-gradient(180deg, #30313aad 0%, rgba(217, 217, 217, 0.00) 190%);
    mix-blend-mode: normal;
}
.mp-banner-text-box-gradient {
    /* background: linear-gradient(180deg, #30313A 0%, rgba(217, 217, 217, 0.00) 190%);
    mix-blend-mode: hard-light; */
    background: linear-gradient(180deg, #30313aad 0%, rgba(217, 217, 217, 0.00) 190%);
    mix-blend-mode: normal;
}

.mp-banner-text .heading {
    /* font-size: 2.5rem; */
    padding-bottom: 10px;
    border-bottom: 2px solid black;
    margin-top: 15px;
    font-size: 42px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.84px;    
}

.mp-banner-text .sub-heading {
    /* font-size: 25px; */
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 35px;
    letter-spacing: -0.64px;    
}

.sub-text {
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.36px;
    margin-top: 12px;
}

.mobile_info_container {
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-block: 28px;
    width: 100%;
    /* min-height: 180px; */
    justify-content: space-between;
}

.mobile_auction_title {
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 38px;
    letter-spacing: -0.64px;
}

.mobile_auction_ends {
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.36px;
}

.info_buttons {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    /* text-decoration-line: underline; */
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.info_btn {
    width: fit-content;
    padding: 4px 1px;
}

.overlay_white_gradient {
    background: linear-gradient(90deg, rgb(240 232 227 / 23%) 15%, rgba(217, 217, 217, 0.00) 100%);
}

.event_custom_box {
    /* background: wheat; */
    padding: 40px 40px;
    /* width: 80%; */
    border-radius: 25px;
}

.mt_50 {
    margin-top: 50px;
}

@media screen and (min-width: 768px) {
    .browse-upper-banner {
        height: 380px;
        position: relative;
    }

    .browse-upper-banner-img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .browse-upper-banner-content {
        position: absolute;
        bottom: 0;
        right: 0;
        padding: 10px 30px;
        background-color: rgba(255, 255, 255, 0.2);
        ;
        color: white;
        backdrop-filter: blur(5px)
    }

    .browse-upper-banner-content-overview {
        display: flex;
        align-items: center;
        gap: 20px;
    }

    .browse-upper-banner-content-overview>div:nth-child(1) {
        font-size: 1.4rem;
        font-weight: 600;
        text-transform: uppercase;
    }

    .browse-upper-banner-content-overview>div:nth-child(2) {
        font-size: 0.8rem;
        font-weight: 600;
        text-transform: uppercase;
    }

    .browse-upper-banner-content-btns {
        display: flex;
        gap: 20px;
        margin-top: 15px;
    }

    .browse-upper-banner-content-btns>button {
        font-size: 0.8rem;
        border: 1px solid white;
        color: white;
        padding: 5px 0;
        flex: 1;
        font-weight: 600;
    }

    .auction-society__heading h3 {
        font-size: 1.8rem !important;
    }

    .auction_links_button {
        font-size: 14px;
        font-weight: 600;
        color: #0d0a0a;
        border-bottom: none;
        border: 1px solid #d3d3d3;
        padding: 7px 15px;
        cursor: pointer;
        transition: 0.2s;
        border-radius: 5px;
    }

    .auction_links_button:hover {
        background-color: var(--primary-blue-clr);
        color: white;
        border: 1px solid var(--primary-blue-clr);
    }

    .auction_society_container {
        box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
        padding: 14px 10px;
    }


    .ticker_container {
        /* width: 90%; */
        margin: 0 auto;
        background-color: rgb(26, 56, 92);
        color: white;
        /* border: 1px solid #d3d3d3; */
        padding: 60px !important;
    }


    /* styles for desktop design */

    .mobile_info_container {
        gap: 20px;
        width: 66%;
    }
    
    .mobile_auction_title {
        font-size: 48px;
        line-height: 65px;
        letter-spacing: 1px;
    }

    .mobile_auction_ends {
        font-size: 23px;
        line-height: 30px;
        letter-spacing: 0.46px;
    }

    .info_buttons {
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.36px;
    }

    .shop_now_white_gradient {
        background: linear-gradient(180deg, rgba(240, 232, 227, 0.90) 0%, rgba(217, 217, 217, 0.00) 100%);
    }

}


/* styles for desktop upperhalfs */

.mp_desktop_banner_container {
    width: 100%;
    /* padding-inline: 30px; */
}

.mp_desktop_banner_main {
    /* height: 85vh; */
    /* height: 70vh; */
    height: 50vh;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    /* min-height: 50vh; */
}

.mp_desktop_gradient_section {
    width: 100%;
    height: 100%;
    /* padding-top: 7%;
    padding-bottom: 3%; */
    position: relative;
}
.mp_desktop_gradient_section_buynow_and_auction {
    width: 100%;
    height: 100%;
    padding-top: 3%;
    padding-bottom: 3%;
    position: relative;
}

.mp_desktop_banner_text {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100%;
    /* margin-inline: 3% !important; */
    width: 60%;
}

.mp_desktop_heading {
    /* font-size: 70px; */
    font-size: 72px;
    font-style: normal;
    font-weight: 400;
    /* line-height: 75px; */
    line-height: 65px;
    letter-spacing: 1.4px;
}


.mp_desktop_about_container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.mp_desktop_sub_heading {
    /* font-size: 50px; */
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: 55px;
    letter-spacing: 1px;
}

.mp_desktop_gradient_section > .custom_padding_class {
    /* padding: 30px !important; */
    padding: 20px !important;
    padding-left: 30px !important;
    padding-right: 30px !important;
}

.mp_desktop_sub_text {
    /* font-size: 23px; */
    font-style: normal;
    font-weight: 400;
    /* line-height: 30px; */
    letter-spacing: -0.46px;
    line-height: 21px;
    font-size: 17px;
}

.desktop_event_card_content_main {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.mp-banner-text_wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 20px;
}

.custom_box_mobile {
    padding: 30px;
    gap: 20px;
}

@media screen and (max-width:1300px) {
    /* .mp_desktop_gradient_section {
        padding-top: 11%;
    } */
    .mp_desktop_gradient_section_buynow_and_auction{
        padding-top: 3%;
    }
    .box_padding_class {
        padding-top: 13% !important;
    }
}

@media screen and (max-width:1100px) {
    .mp_desktop_heading {
        font-size: 53px;
    }
    .event_custom_box {
        /* background: wheat; */
        width: 100%;
    }
    .box_padding_class {
        padding-top: 17% !important;
    }
}

@media screen and (max-width:980px) {
    .mp_desktop_about_container {
        gap: 0px;
    }
    .box_padding_class {
        padding-top: 13% !important;
    }
    /* .custom_padding_class {
        width: 100%;
    } */
}

@media screen and (min-width:980px) {
    .mp_desktop_banner_text {
       /* margin-inline: 8% !important; */
}

/* for desktop new designs */
.mobile_info_container {
    width: 50%;
}
}