.section4a>div>.p1 {
    /* font-size: 42px; */
    font-size: 36px;
    color: var(--color-lffu6uvb);
    margin-left: 20px;
    margin-top: 60px;
    /* font-weight: 600; */
    font-weight: 700;
}

.section4a>div>.p2 {
    font-size: 20px;
    margin-left: 20px;
    font-weight: 400;
}

.section4a>div>.p3 {
    font-size: 16px;
    color: var(--color-lffy4ue2);
    margin-left: 20px;
    /* margin-top: 50px; */
}

.section4a>div>.p3>span {
    color: var(--color-lffudicf);

}

.event_carosel {
    display: flex;
    flex-direction: row;
    overflow-x: auto;
    gap: 25px;
    padding: 0 20px;
    padding-top: 15px;
    overflow-y: visible;
}

.event_carosel::-webkit-scrollbar {
    display: none;
}

.event_carosel> .image_container {
    max-width: 380px;
    min-width: 380px;
    /* height: 200px; */
    border-radius: 5px;
    overflow-y: visible;
}
.event_carosel> .image_container>img {
    max-width:100%;
    width: 100%;
    height: 180px;
    object-fit: cover;
    border-radius: 5px;
    transition: transform 0.2s;
}
.event_carosel> .image_container>img:hover{
    transform: scale(1.05);
    cursor: pointer;
}
.event_carosel> .image_container> .event_details {
    margin-top: 5px;
    text-align: center;
}
.event_carosel> .image_container> .event_details>div>strong {
  font-size: 22px;
}




@media (min-width:767px) and (max-width:980px)  {
    .event_carosel {
        width: 96%;
        
    }
}

.click_all_events:hover{
    cursor: pointer;
    text-decoration: underline;
}

.more_button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 180px;
}

@media screen  and (max-width:768px) {
    .section4a>div>.p1 {
        text-align: center;
        margin-left: 0px;
        font-size: 30px;
    }
    
    .section4a>div>.p3 {
        text-align: center;
        margin-left: 0px;
        font-size: 14px !important;
    }
}