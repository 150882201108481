
.custom-tabs {
  display: flex;
  flex-direction: column;
}

.grid-tabs-container {
  height: calc(100vh - 0px);
  /* margin-top: 5px; */
  background-color: #FAFAFA;
}

.tabs-sidebar {
  padding: 0 30px !important;
  border-bottom: 3px solid #dedede;
  border-top: 3px solid #dedede;
  height: 100%;
}

.tabs-sidebar div {
  flex-direction: column;
  align-items: baseline;
  /* border-right: 1px solid #b9b9b9; */
}

.custom-tabs {
  display: flex;
  flex-direction: column;
  align-items: start;
}

.grid-tabs-content {
  background-color: #f8f8f8;
  overflow-y: auto;
}

/* SideBarTab.css */
.tab {
  color: rgba(0, 0, 0, 0.54);
  background-color: transparent;
  font-weight: bold !important;
  opacity: 0.8;
  width: 100%;
  margin: 5px !important;
  border-radius: 25px !important;
  text-align: left !important;
  align-items: start !important;
  border: 1px solid black !important;
}

.tab-active {
  color: #ff0000 !important;
  background-color: #fedada !important;
  width: 100%;
  font-weight: bold;
  border-radius: 25px !important;
  text-align: left !important;
  align-items: start !important;
  border: 1px solid black !important;
}

div[role="tablist"]+span {
  display: none !important;
}

.labels {
  margin-left: 5px;
  text-transform: capitalize;
}

.tab-content svg {
  min-width: 25px;
  max-width: 25px;
}

.name-div {
  position: relative;
    width: 100%;
    height: 100px;
    background-Color: #FAFAFA;
    display: flex;
    align-Items: center;
    padding-Left: 20px;
    border-bottom: 2px solid #DEDEDE !important;
    border-top: 2px solid #DEDEDE;
}

.name-div h1 {
  font-Size: 30px;
  font-Weight: bold; 
  font-Family: Arial Black sans-serif;
  color: black;
  text-Transform: uppercase;   
  margin-Left:85px;
}

@media only screen and (min-width: 900px) {
  .tabs-sidebar {
    padding: 0 30px !important;
    border-bottom: none;
    border-top: none;
    border-right: 3px solid #dedede;
  }
}

@media only screen and (max-width: 899px) {
  .grid-tabs-container {
    height: auto;
    margin-top: 20px;
  }
}