.forgot_password {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  padding: 34px 14px;
  gap: 24px;
  background-color: #30313A;
}

.forgot_password img {
  height: 60px;
  /* margin-bottom: 94px; */
  margin-top: 40px;
}


.forgot_password>button {
  padding: 12px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: -0.24px;
  color: #FFFFFF;
  flex: none;
  font-family: 'AvenirNext';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  background: #173B83;
  border: 2px solid #173B83;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.45);
  border-radius: 5px;
  cursor: pointer;
}

.back_wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.back_wrapper>.back {
  display: flex;
  align-items: center;
  gap: 5px;
  justify-content: center;
  font-family: 'AvenirNext';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
}


/* SendResetLink */
.comp_wrapper {
  display: flex;
  flex-direction: column;
  gap: 50px;
  align-items: center;
}

.comp_wrapper>h3 {
  font-family: 'Instrument Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 31px;
  line-height: 36px;
  letter-spacing: 0.035em;
  color: #000000;
  margin-bottom: 25px;
}

.wrapper_top {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 7px;
}

.wrapper_top>h3 {
  font-family: 'Instrument Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  letter-spacing: 0.035em;
  color: #000000;
  margin: 0;
}

.wrapper_top>p {
  font-family: 'AvenirNext';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  text-align: center;
  letter-spacing: -0.24px;
  color: #4A4848;
  margin: 0 0 20px !important;
}

.comp_forgot_input {
  flex-grow: 1;
  width: 100% !important;
}

.comp_forgot_input>label {
  font-family: 'Instrument Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.24px;
}

/* ResetPassword */
.comp_reset_password_input {
  display: flex;
  flex-direction: column;
  gap: 0px;
  align-items: center;
  width: 100%;
}

.extra-class {
  width: 85%;
  margin: auto;
  background-color: #F0E8E3;
  border-radius: 25px;
  /* height: 200px; */
  padding: 20px;
  color: #30313A;
  font-family: "Instrument Sans";
}

.reset-email-input {
  width: 100%;
  height: 38px;
  border-radius: 5px;
  padding-left: 15px;
  border: 1px solid #30313A;
  font-size: 12px;
  font-weight: 400;
  font-family: "Instrument Sans";
  background-color: #F0E8E3;
}

.reset-email-input:focus {
  outline: none;
}

.wrapper_top>.reset-link-heading {
  font-family: "Instrument Sans";
  font-weight: 400;
  font-size: 25px;
  line-height: 35px;
  margin-bottom: 10px;
  text-align: left;
}

.wrapper_top>.reset-link-desc {
  font-family: "Instrument Sans";
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  margin-bottom: 20px;
  text-align: left;
}

.forgot_password>.new-btn-reset-link {
  width: 85%;
  font-family: "Instrument Sans";
  margin: auto;
  color: #F0E8E3;
  border: 2px solid #F0E8E3;
  background-color: #30313A;
  height: 38px;
  border-radius: 25px;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 600;
  margin-top: 20px;
  margin-bottom: 0;
  /* letter-spacing: 15%; */
}

.forget-pass-alert {
  width: 85%;
  margin: auto;
}

.field_outer {
  position: relative;
  overflow: hidden;
  /* border-radius: 10px; */
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin: 20px 0; */
}

.input-icon {
  position: absolute;
  left: 15px;
  top: 12px;
  color: #DEDEDE;
  font-size: 16px;
}

.login-form-container .heading {
  text-align: center;
  font-size: 60px !important;
  font-weight: bold;
  line-height: 1;
  font-family: "alternategotno1dregular";
}

.login-form-control {
  background-color: white;
  width: 100%;
  padding: 12px 15px 12px 45px;
  border: 1px solid #ccc;
  border-radius: 25px;
  font-size: 14px;
  /* outline: none; */
  color: #888;
  margin: 0 !important;
  height: 55px !important;
}

.login-blockBtn.not-allowed-btn {
  background: #ff0000;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "alternategotno1dregular";
  font-weight: bold;
  font-size: 35px;
  padding: 30px;
  border-radius: 300px;
  text-transform: capitalize;
  margin: 0 0 15px;
}

@media only screen and (min-width: 768px) {
  .reset-link-container {
    height: auto;
    overflow: hidden;
  }

  .extra-class {
    max-width: 450px;
    margin-top: 35px;
    padding: 30px 35px;
  }

  .reset-email-input {
    height: 45px;
    font-size: 15px;
    margin-top: 15px;
  }

  .wrapper_top>.reset-link-heading {
    font-size: 32px;
  }

  .wrapper_top>.reset-link-desc {
    text-align: center;
    font-size: 15px;
  }

  .new-btn-reset-link {
    max-width: 200px;
    margin-bottom: 35px;
  }

  .forget-pass-alert-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .forget-pass-alert {
    max-width: 400px;
    margin: 0px;
  }

  .forgot_password img {
    height: 60px;
    /* margin-bottom: 94px; */
    margin-top: 10px;
  }
}

@media (max-width: 768px) {
  
  .reset-email-input {
    margin-bottom: 10px !important;
  }

  .login-form-container .heading {
    font-size: 40px !important;
  }

  .login-blockBtn.not-allowed-btn {
    font-size: 27px;
    padding: 26px;
  }
}