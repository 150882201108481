.num_days {
    margin: 0 !important;
    padding: 20px 0;
}

@media screen and (min-width: 768px) {
    .dpage_outer_bid {
        min-height: 100vh;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
    }
    .dbidpage_container {
        display: flex;
        max-width: 85vw;
        margin: 0 auto;
        justify-content: space-between;
        align-items: center;
        gap: 40px;
    }

    .bid_img {
        width: 50% !important;
        margin-bottom: 0 !important;
    }

    .dlotdetails_container {
        width: 50% !important;
        overflow-x: hidden;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        border: 1px solid #d3d3d3;
        padding: 30px 20px;
    }

    .max_bid_inp {
        background-color: transparent !important;
        width: 100% !important;
        border: none !important;
    }

    .lot_details {
        margin-bottom: 0 !important;
    }

    .dlinks {
        max-width: 85vw;
        margin: 0 auto;
        font-size: 1.6rem;
        font-weight: 600;
    }

    .dlinks > div {
        margin: 5px 0;
        display: inline-block;
        width: fit-content;
    }

    .dlinks > div:hover {
        cursor: pointer;
        text-decoration: underline;
    }
}