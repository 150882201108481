.dlog_in_wrapper{
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.dlogin_sec{
  width: 80%;
  height: 90%;
  display: flex;
  align-content: center;
  box-shadow: 0 25px 50px -12px rgb(0 0 0 / 0.25);
  border-radius: 25px;
}
.dlogin_sec_left{
    width: 40%; 
}
.dlogin_sec_left > img{
    object-fit:cover;
    width: 100%;
    height: 100%;
    border-radius: 25px 0 0 25px;
}
.dlogin_sec_right{
    width:60%;
    height: 100%;
    padding-top: 2%;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;

   
}
.dform_container{
width: 100%;
margin-top: 5%;
padding-left: 20%;
}
.dform_container .form_controll{
    box-sizing:border-box ;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-bottom: 5%;
}
.dform_container .form_controll  label{
    color: #5D77A7;
    font-weight: 400;
    font-size: 1rem;
}
.dform_container .form_controll  input{
     width:80%;
     padding: 10px;
     font-weight: 600;
     border: 1px solid #d3d3d3;
}
.dform_container .form_controll  input:focus{
    outline: none;
}
.dform_container .form_controll .eye{
   position: absolute;
   right: 22%;
   top: 50%;
   transform: translateY(-50%);
   cursor: pointer;
}
.dform_container .login{
  width: 80%;
  color: white;
  border-radius: 5px;
  font-size: 0.9rem;
  font-weight: 600;
  padding: 10px 0;
  background-color:  #1C385D;
}
.dform_container .login_loading{
  width: 80%;
  color: white;
  border-radius: 5px;
  font-size: 0.9rem;
  font-weight: 600;
  padding: 3px 0;
  background-color:  #1C385D;
}
.form_controll_input {
    position: relative;
}
.dform_container .login_not_allowed{
    width: 80%;
    height: 40px;
    color: white;
    background-color: lightgray;
}
.err_msg{
    color: #ff3636e3 !important;
    text-align: center;
}
.success_msg{
    color: #00b300 !important;
    text-align: center;
}