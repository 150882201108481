.about_donor{
    padding-top: 200px;
    padding-bottom: 80px;
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.about_donor>img{
    max-width: 150px;
    max-height: 150px;
}
.ad_content>h1{
    font-size: 32px;
}
.ad_content>p{
    font-size: 18px;
}

@media screen and (max-width:768px) {
    .about_donor{
        padding-inline: 6%;
        padding-top: 150px;
    }
    
}