.dcategories_card {
    flex-shrink: 0;
    max-width: 287px;
    height: 220px;
    border-bottom: 1px solid #d3d3d3;
    cursor: pointer;
}

.dcategories_card_img {
    width: 100%;
    height: 170px;
}

.dcategories_card_img > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.dcategories_card_head {
    text-align: center;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.2rem;
    font-weight: 700;
}

/* styles for new desktop card */

.desktop_card_container {
    display: flex;
    flex-direction: column;
    gap: 31px;
    cursor: pointer;
    max-width: 113px;
}

.desktop_image_container {
    max-width: 113px;
    width: 113px;
    max-height: 265px;
    height: 265px;
    border-radius: 25px;
}

.desktop_image_container > img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 25px;
}

.desktop_image_title {
    color: #000;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; 
    letter-spacing: 0.36px;
}