.main_section {
    padding-block: 118px;
    background-size: cover;
    background-position: top;
    background-repeat: no-repeat;
    height:400px;
}

.container {
    display: flex;
    flex-direction: column;
    gap: 26px;
    justify-content: flex-start;
    /* color:"#FC3300" */
}

.container > h3 {
    font-size: 50px;
    font-style: normal;
    font-weight: 400;
    line-height: 55px; 
    letter-spacing: 0px;
}

.title_break {
    display: none;
}

@media screen and (max-width:768px) {
    .main_section {
        background-position: center right;
    }
}
 
@media screen and (max-width:546px) {
    .title_break {
        display: block;
    }
}