.countdown_container {
  font-family: Arial, sans-serif;
  display: flex;
  align-items: center;
  margin: 0 auto;
  background-color: #000;
  color: white;
  padding: 20px;
  justify-content: center;
  gap: 40px;
}

.countdown_header {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.countdown_timer {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  color: #FFF;
  font-family: 'alternategotno1dregular';
  gap: 10px;
}

.countdown_item {
  display: flex;
  flex-direction: column;
  font-weight: 700;
  font-size: 48px;
  line-height: 38px;
  align-items: start;
}

.countdown_value {
  font-weight: bold;
}

.days_value {
  color: #FF0000;
  font-family: 'alternategotno1dregular';
  font-size: 60px;
}

.countdown_label {
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  font-family: 'Courier New', Courier, monospace;
  margin-left: 3.5px;
}

.countdown_footer {
  font-size: 1rem;
  margin-top: 10px;
}

.discountPercentage {
  font-size: 1.3rem;
  font-weight: bold;
  margin-top: 10px;
  width: 20%;
  text-align: center;
}

.discountPercentageMobile {
  display: none;
}

@media (max-width: 899px) {
  .discountPercentageMobile {
    display: flex;
    font-size: 1.2rem;
    font-weight: bold;
    text-align: center;
    margin: 10px 0;
  }

  .discountPercentage {
    display: none;
  }

  .countdown_container {
    padding: 10px;
    gap: 10px;
    flex-direction: column;
  }

  .days_value {
    font-size: 40px;
  }

  .countdown_item {
    font-size: 40px;
  }

}

@media (max-width: 600px) {
  .countdown_header {
    font-size: 1.5rem;
  }

  .countdown_timer {
    display: flex;
    justify-content: space-evenly;
  }

}