.dsection {
    max-width: 1250px;
    margin: 30px auto;
    padding: 0 20px;
}



.dcurrent_events>h3 {
    font-weight: 600;
    /* font-size: 42px; */
    font-size: 36px;
    color: var(--color-lffu6uvb);
    /* margin-bottom: 50px; */
    margin-bottom: 35px;
}

.dbrowse_cards {
    display: flex;
    flex-direction: row;
    overflow-x: auto;
    flex-wrap: nowrap;
    gap: 30px;
}

.dbrowse_cards::-webkit-scrollbar {
    display: none;
}

.mobile_main_heading {
    font-size: 32px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 35px;
    letter-spacing: -0.64px;
}

.mobile_heading {
    font-size: 22px !important;
    font-style: normal;
    font-weight: 800 !important;
    line-height: 25px;
    letter-spacing: -0.4px;
}

.card_title {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    text-align: center;
    margin-top: 12px;
}

/* styles for new desktop design */

.desktop_section {
    /* padding-top: 80px; */
    width: 100%;
    padding-top: 30px;
    padding-bottom: 10px;
}

.desktop_container {
    display: flex;
    flex-direction: column;
    gap: 35px;
}

.desktop_section_heading {
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 35px; 
    letter-spacing: 0.64px;
}

.desktop_content {
    display: flex;
    flex-direction: column;
    gap: 22px;
}

.desktop_heading {
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 25px;
    /* letter-spacing: 0.4px; */
}

.desktop_card_wrapper {
    min-width: 100%;
    max-width: 100%;
    overflow-x: auto;
    display: flex;
    gap: 30px;
}