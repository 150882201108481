.section {
    padding-inline: 30px;
    padding-top: 37px;
    padding-bottom: 24px;
}

.container {
    display: flex;
    flex-direction: column;
    gap: 34px;
}

/* .container>img {
    max-width: 60px;
    max-height: 50px;
} */

.image_container {
    max-width: 60px;
    max-height: 50px;
}

.icons_container {
    display: flex;
    flex-direction: column;
    gap: 11px;
}

.links {
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    /* letter-spacing: 0.36px; */
    /* cursor: pointer; */
}

.social_icons {
    display: flex;
    gap: 36px;
}

.bottom_links_container {
    display: flex;
    flex-direction: column;
    gap:8px;
}

.bottom_links {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    padding: 2px 1px;
    width: fit-content;
}

.copy_right {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
}

/* styles for deskto footer */

.desktop_bottom_section {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.desktop_bottom_first_section {
    display: flex;
    gap: 28px;
    align-items: center;
}

@media screen and (min-width:768px) {
    .bottom_links_container {
        flex-direction: row;
        align-items: center;
        gap: 28px;
    }
    .section {
        padding-inline: 0px;
    }
    .social_icons {
        align-items: center;
    }
}