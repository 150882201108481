.item_bid_container {
    padding: 90px 0;
    /* padding-inline: 30px; */
    min-height: calc(100vh - 7vh);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.bid_history_mobile_container {
    display: flex;
    flex-direction: column;
    gap: 40px;
}

.headings_container {
    display: flex;
    flex-direction: column;
    gap: 3px;
}

.heading {
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 25px;
    letter-spacing: 0.4px;
}

.item_name {
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 35px;
    /* 109.375% */
    letter-spacing: 0.64px;
    margin-top: 25px;
}

.bids_container {
    display: flex;
    flex-direction: column;
    gap: 45px;
}

.bidder_amount {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.top_section {
    display: flex;
    justify-content: space-between;
}

.content_left {
    display: flex;
    flex-direction: column;
    gap: 3px;
}

.bid_data_heading {
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.36px;
}

.bid_data {
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.36px;
}

.bid_date {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: .2px;
}

@media screen and (max-width:768px) {
    .item_bid_container {
        padding-inline: 30px;
    }
}