.purchase-container {
  padding: 40px 0 60px;
  overflow: auto;
  max-height: 82vh;
}

.purchase-container h1 {
  font-weight: bold;
  font-family: "alternategotno1dregular";
  color: #000;
  margin-bottom: 10px;
  font-size: 2.5rem;
  letter-spacing: 0.5px;
}

.order-number {
  /* font-weight: bold; */
  font-family: "alternategotno1dregular";
  color: #ff0000;
  margin-bottom: 10px !important;
}

.brand-image {
  max-width: 100px;
  max-height: 110px;
  border-radius: 10px;
}

.brand-image img {
  width: 100%;
  border-radius: 12px;
}

.history-data {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.brand-status {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.purchase-history {
  padding: 20px;
  border: 1px solid #e5e1e1;
  border-radius: 15px;
  background-color: #ffffff;
}

.brand-name {
  display: flex;
  gap: 10px;
}

.brand-details h5 {
  font-weight: bold;
  font-family: "alternategotno1dregular";
  margin: 0;
  font-size: 25px;
  letter-spacing: 0.5px;
}

.brand-details p {
  font-weight: bold;
  margin-bottom: 20px !important;
  color: #8F8F8F;
}

.brand-details {
  max-width: 300px;
}

.brand-status h4 {
  font-family: "alternategotno1dregular";
  color: #ff0000;
}

.brand-status p {
  font-family: "alternategotno1dregular";
  color: #8F8F8F;
}

.brand-status {
  text-align: right;
}

.red-color {
  color: #ff0000;
}

@media only screen and (min-width: 900px) {
  .purchase-container {
    padding: 40px 50px 60px;
    overflow: auto;
    max-height: 82vh;
  }

  .history-data {
    flex-direction: row;
  }

  .brand-status {
    display: inline-block;
  }
}

.purchase-container__history {
  overflow: auto;
  max-height: 82vh;
  padding: 0;
}