.site-status-container {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.site-status-content {
    max-width: 1200px;
    margin: 20px auto;
    font-weight: 600;
    text-align: center;
}