:root {
    --primary_color: #FC3300;
    --beige: #F0E8E3;
}


.email_campign_signup_container {
    display: flex;
    flex-direction: column;
    gap: 13px;
    color: var(--primary_color);
}



.email_campign_signup_container>input {
    height: 50px;
    border-radius: 5px;
    border: 2px solid var(--primary_color);
    background: rgba(255, 255, 255, 0.08);
    padding-left: 10px;
}



.email_campign_signup_container> p {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
}

.email_campign_signup_container> h3 {
    text-align: center;
    /* h3 */
    font-family: Instrument Sans;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 35px;
    /* 109.375% */
    letter-spacing: -0.64px;
}

.email_campign_signup_container>input::placeholder {
    color: var(--primary_color);
}

.thanku_text {
    font-size: 20px !important;
    line-height: 32px !important;
    font-weight: 600 !important;
    text-align: center
}