
.dsection {
    max-width: 1250px;
    margin: 30px auto;
    padding: 0 20px;
}

.dcurrent_events_container {
    background-color: #F4F4F4;
    padding: 30px 10px;
    /* margin: 70px 0; */
}

.dcurrent_events > h3 {
    font-weight: 600;
    /* font-size: 42px; */
    font-size: 36px;
    color: var(--color-lffu6uvb);
    /* margin-bottom: 50px; */
    margin-bottom: 35px;
}

.dcurrent_cards {
    display: flex;
    gap: 30px;
    flex-wrap: nowrap;
    overflow-x: auto;
    padding-bottom: 13px;
}

.dcurrent_cards::-webkit-scrollbar {
    display: none;
}

.dcurrent_card {
    flex-shrink: 0;
    width: 240px;
    height: 500px;
    /* height: 100%; */
}


.dcurrent_card_img {
    height: 75%;
    width: 100%;
}

.dcurrent_card_img > img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.dcurrent_card_content {
    height: 25%;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: center;
}

.dcurrent_card_about {
    /* padding: 10px; */
    padding: 1px 1px;
    font-size: 0.9rem;
    text-align: center;
}

.dcurrent_card_btn {
    background-color: var(--primary-btn-clr);
    color: var(--white);
    padding: 10px 50px;
    border-radius: 30px;
    cursor: pointer;
}

.label_container{
    display: flex;
    justify-content: center;
    align-items: end;
    
}
.label_container button{
    position: absolute;
    text-align: center;
    color: white;
    font-size: 14px;
    background-color: #1a385c;
    margin-bottom: 15px;
    border-radius: 5px;
    font-weight: 500;
    padding: 10px;
}