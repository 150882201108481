
.dfooter_form {
    background-color: #F4F4F4;
    padding-bottom: 30px;
    border-bottom: 1px solid #b6b6b6;
    /* margin-top: 50px; */
}

.dfooter_form_head {
    font-size: 2rem;
    font-weight: 600;
    text-align: center;
    padding: 30px 0;
    border-bottom: 1px solid #b6b6b6;
}

.dfooter_form_content {
    max-width: 1000px;
    margin: auto;
}

.dfooter_form_inputs {
    margin-top: 30px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 20px;
    row-gap: 50px;
}

.dfooter_form_input {
    border-bottom: 1px solid #d3d3d3;
}

.dfooter_form_input > div {
    font-weight: 600;
}

.dfooter_form_input > input {
    width: 100%;
    border: none;
    background-color: transparent;
    margin-bottom: 10px;
    outline: none;
    margin-top: 5px;
}

.dfooter_form_condition {
    margin-top: 30px;
    font-size: 0.8rem;
}

.dfooter_sub {
    margin: 20px 0;
    text-align: center;
}

.dfooter_sub > button {
    border: 1px solid #d3d3d3;
    padding: 10px 30px;
    display: inline-block;
    border-radius: 30px;
    cursor: pointer;
    background-color:  var(--primary-btn-clr);
    color:white
}