.login-container {
    min-height: 100vh;
    background-color: #30313A;
    padding-bottom: 50px;
    text-align: center;
}

.login-logo {
    padding-top: 12%;
    width: 100%;
    height: 100px;
    margin-bottom: 60px;
    text-align: center;
}

.login-logo img {
    width: 80%;
}

.login-signup {
    margin-top: 20px;
    color: #8F8F8F;
    margin-Bottom: "0"

}

.login-signup h6 {
    font-size: 18px;
    font-weight: bold;
    text-align: center;
}

.login-signup h5 a {
    font-size: 18px;
    font-weight: bold;
    color: #8F8F8F;
}

.login-form-container {
    text-align: left;
    background-color: #F0E8E3;
    width: 85%;
    border-radius: 40px;
    margin: auto;
    padding: 20px;
    color: #30313A;
    margin-bottom: 25px;
    padding-bottom: 35px;
}

.login-blockBtn {
    width: 100%;
    height: 40px;
    border: 2px solid #F0E8E3;
    color: #F0E8E3;
    border-radius: 20px;
    outline: none;
    font-weight: 600;
    font-size: 16px;
    margin: auto;
}

.login-blockBtn.not-allowed-btn {
    background: #ff0000;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "alternategotno1dregular";
    font-weight: bold;
    font-size: 35px;
    padding: 30px;
    border-radius: 300px;

}

/* .login-blockBtn.not-allowed-btn{
    background: #e6e7ea;
    color: #a2a4aa;
} */

.login-blockBtn-loading {
    width: 85%;
    height: 40px;
    border: none;
    color: #fff;
    border-radius: 20px;
    padding: 5px;
    outline: none;
    font-weight: 700;
    font-size: 13px;
    margin-top: 15px;
}

.login-form-container>.heading {
    text-align: center;
    font-size: 60px !important;
    font-weight: bold;
    line-height: 35px;
    font-family: "alternategotno1dregular";

}

.login-form-container>.sub-heading {
    text-align: center;
    margin-bottom: 35px;
    font-size: 13px;
}

@media only screen and (min-width: 380px) and (max-width: 768px) {
    .login-container {
        padding-bottom: 0;
    }

    .login-logo {
        padding-top: 20%;
    }

    .login-form-container {
        margin-top: 40%;
    }
}

@media only screen and (min-width: 768px) {
    .login-container {
        text-align: center;
    }

    .login-logo {
        max-width: 400px;
        height: 100px;
        margin: auto;
        height: 50px;
        padding-top: 2%;
        padding-bottom: 100px;
    }

    .login-logo img {
        width: 100%;

    }

    .login-form-container {
        max-width: 450px;
        text-align: center;
        padding: 80px 45px;
        padding: 30px;
        margin-top: 70px;
        margin-bottom: 50px;
    }

    .login-form-container p {
        text-align: center;
        /* padding-bottom: 15px; */
    }

    .login-form-container>.heading {
        text-align: center;
        font-size: 38px;
        padding-bottom: 15px;
    }

    .login-form-container>.sub-heading {
        font-size: 16px;
    }

    .login-form-control {
        height: 55px;
        /* margin-top: 10px; */
        /* margin-bottom: 5px; */
        font-size: 16px;
    }

    .field_outer {
        margin-bottom: 10px;
    }

    /* .login-blockBtn{
        max-width: 190px;
    } */
    .login-blockBtn-loading {
        max-width: 190px;
    }
}


.field_outer {
    display: flex;
    flex-direction: column;
}

.field_outer svg path {
    fill: #DEDEDE;
}

.input-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
}

.input-icon {
    position: absolute;
    left: 15px;
    top: 12px;
    color: #DEDEDE;
    font-size: 16px;
}

.login-form-control {
    width: 100%;
    padding: 12px 15px 12px 45px;
    border: 1px solid #ccc;
    border-radius: 25px;
    font-size: 14px;
    /* outline: none; */
    color: #888;
}

.login-form-control::placeholder {
    color: #ccc;
    font-weight: 500;
    text-transform: uppercase;
}

