:root {
  --primaryColor:#F0E8E3;
}

.auction-overview__title,
.auction-overview__heading {
  font-family: "Instrument Sans";
  font-weight: 600;
  font-size: 32px;
  margin-bottom: 12px;
}

.auction-overview img {
  margin-top: 15px;
  width: 100%;
  margin-bottom: 32px;
}

.auction-overview__heading {
  margin-bottom: 8px;
  font-size: medium
}

.dheading-aboutevent {
  height: 100px;
  background-color: #EFEFEF;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dabout_event_container {
  width: 80%;
  margin: auto;
  margin-top: 25px;
}

.dbanner {
  width: 100%;
  height: 400px;
}

.dbanner_img {
  object-fit: cover;
  width: 100%;
  height: 90%;
  border: 1px solid rgb(243, 241, 241);
}

.devent_date_container {
  box-sizing: border-box;
  width: 100%;
  height: 10%;
  padding: 0 2%;
  background-color: #1C385D;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
}



.devent_details {
  margin-top: 25px;
}

.mobile_overview_container {
  padding: 175px 30px 10vw 30px;
  display: flex;
  flex-direction: column;
  gap: 33px;
}

.mobile_card_container {
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
}

.mobile_card_container>img {
  /* max-width: 65%;
  min-height: 100px; */
  min-height: 80px;
  max-width: 137px;
  object-fit: cover;
}

.mobile_card_container>h1 {
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 39px;
  /* letter-spacing: 0.64px; */
}

.mobile_card_container>p {
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* letter-spacing: 0.36px; */
}

.about_auction_wrapper {
  display: flex;
  flex-direction: column;
  gap: 23px;
}

.about_auction_wrapper>h5 {
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
  /* letter-spacing: 0.4px; */
}

.about_auction_wrapper>p {
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.36px;
}

.about_auction_wrapper>p>p>span {
     color: var(--primaryColor) !important;
}

@media screen and (min-width:768px) {
  .mobile_overview_container {
    padding-top: 200px;
  }
}

@media screen and (min-width:980px) {
  .mobile_overview_container {
    max-width: 55%;
  }
}