.verify_email {
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 26px;
}
.verify_email > h3 {
    font-family: 'Instrument Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 31px;
    line-height: 36px;
    letter-spacing: 0.035em;
    color: #000000;
    text-align: center;
}
.success_verify {
    padding: 0 !important;
    display: flex;
    flex-direction: column;
    gap: 26px;
    align-items: center;
    justify-content: center;
}
.success_verify > h3 {
    font-style: normal;
    font-weight: 700;
    font-size: 31px;
    line-height: 36px;
    letter-spacing: 0.035em;
    color: #000000;
    text-align: center;
}
.success_verify > button {
    width: 100%;
    padding: 12px 0;
    letter-spacing: -0.24px;
    color: #FFFFFF;
    flex: none;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    background: #173B83;
    border: 2px solid #173B83;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.45);
    border-radius: 5px;
    cursor: pointer;
}

.ve_success{
    height: 100vh;
    width: 100vw;
    background-color:#F0E8E3 ;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color:#30313A ;
    padding: 35px;
}

.ve_success > div > h3{
    font-size:32px ;
    font-weight: 400;
    margin-bottom:20px ;
}

.ve_success > div > p{
    font-size:18px;
    font-weight: 400;
}

.ve_logo{
    display: none;
}

@media screen and (min-width:768px) {
    .ve_success {
        justify-content: center;
    }
    .ve_logo {
        display: block;
        position: fixed;
        top: 40px;
    }
    .ve_success > div{
        width: 325px;
    }
}