.bannerContentContainer {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 70%;
}

.bannerHeading {
    font-size: 72px;
    font-style: normal;
    font-weight: 400;
    line-height: 65px;
    letter-spacing: 1.4px;
}
.bannerHeadingColor {
    color: var(--black-color);
}

.bannerHeadingColorForBrandAndStore {
    color: var(--pure-white)
}

.bannerUnderLine {
    width: 100%;
    border: 1px solid var(--black-color);
}
.bannerUnderLineForBrnadsAndStore {
    width: 100%;
    border: 1px solid var(--pure-white);
}

@media screen and (max-width:767px) {
    .bannerContentContainer {
        width: 100%;
        height: 100%;
        justify-content: center;
    }
    .bannerHeading {
        font-size: 42px;
        font-weight: 600;
        letter-spacing: 0;
        line-height:32px;
    }
}