.register-main{
  min-height: 100vh;
  background-color: #FF0000;
}
.field_outer svg path {
  fill: #DEDEDE;
}

.register-container{
    min-height: 100%;
    background-color: #FF0000;
    font-family: "Instrument Sans";
    /* padding-bottom: 50px; */
    text-align: center;
    display: flex;
    flex-direction: column;
    /* gap: 38px; */
    padding-block: 35px;
}
.register-logo{
    padding-top: 5%;
    width: 100%;
    /* height: 100px; */
    margin-bottom: 30px;
    text-align: center;
}
.register-logo  img{
    width: 80%;
}
.register-form-container{
    text-align: left;
    background-color: #F0E8E3;
    width: 85%;
    border-radius: 25px;
    margin: auto;
    padding: 20px;
    color:#FF0000;
    margin-bottom: 25px;
}
.register-form-container .create-account{
  text-align: center;
  margin-bottom: 20px;
  font-family: 'alternategotno1dregular';
}
.register-form-container > .sub-heading{
  font-size: 12px;
  margin-bottom: 22px !important;
}
.register-form-control-name{
  width: 100%;
  height:45px ;
  padding-left: 10px;
  border:1px solid black;
  background-color: white;
  font-size: 14px;
  border-radius: 40px;
}
.register-form-control-name::placeholder{
  color: #ccc;
  font-weight: 500;
  text-transform: uppercase;
}
.register-form-control{
  width: 100%;
  padding: 12px 15px 12px 45px;
  border: 1px solid #ccc;
  border:1px solid black;
  border-radius: 25px;
  font-size: 14px;
  /* outline: none; */
  color: #888;
}
.register-form-control::placeholder{
  color: #ccc;
  font-weight: 500;
  text-transform: uppercase;
}

#emailUpadte , #emailPolicy{
  height: 14px;
  width: 14px;
  top: 5px;
    color:#FF0000;
    border: 1px solid #FF0000;
}

.register-block-btn {
  width: 100%;
  height: 50px; 
  border-radius: 25px; 
  border: 2px solid #FF0000; 
  color: #FF0000 !important; 
  background-color: white !important;
  font-weight: bold; 
  font-size: 28px; 
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "alternategotno1dregular"; 
  padding: 10px 20px; 
  letter-spacing: 1px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.register-block-btn.not-allowed-btn {
    background: #e6e7ea;
    color: #a2a4aa;
    border: 1px solid #FF0000;
  }

  .register-blockBtn-loading {
    /* background: var(--primary-blue-clr); */
    width: 100%;
    height: 40px;
    border: none;
    color: #fff;
    border-radius: 20px;
    padding: 5px;
    outline: none;
    font-weight: 700;
    font-size: 13px;
    margin-top: 15px;
  }

  .register_subheading {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
}
.stickDown h6 span {
  font-size: 12px;
  margin-inline: 2px;
  text-decoration: underline !important;
  text-decoration-color: white;
  cursor: pointer;
}

.required_text {
  font-size: 13px;
  color: #FF0000;
  position: relative;
  margin-top: 15px;

}

.register-form-container .pw-error-label{
  font-size: 12px;
  text-align: left;
}

.common_font {
  font-size: 17px !important;
}

.error_register {
  text-align: center !important;
  /* padding-bottom: 15px; */
}

.logForm {
  margin-bottom: 25px;
}

  @media only screen and (max-width: 380px) {
    .register-container{
        height: 100%;
    }
  }

  @media only screen and (min-width: 768px) {
    .required_text {
      top: 10px;
    }
    .register-container{
      gap: 10px;
    }
    .register-logo{
      max-width: 400px;
      margin: auto;
      height: fit-content;
      padding-top: 0px;
      /* padding-bottom: 100px; */
    }
    .register-logo  img{
      width: 100%;
      height: 75%;
      object-fit: contain;
    }
    .register-form-container{
      margin-top: 50px;
      max-width: 500px;
      padding: 50px;
      text-align: center;
      position: relative;
    }
    .register-form-container  p{
      text-align: justify;
      /* padding-bottom: 15px; */
    }
    .register-form-container .create-account{
      text-align: center;
      font-size: 60px;
      font-weight: 700;
    }
    .register-form-container > .sub-heading{
      font-size: 16px;
      margin-bottom: 22px;
    }
    .register-form-control{
      height: 45px;
      font-size: 16px;
    }
 
  }

  @media screen and (max-width:500px) {
    .common_font {
      font-size: 15px !important;
    }
  }