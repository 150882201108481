:root {
    --primar-color: #30313A;
}

.faq_main {
    padding: 15px 25px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-bottom: 150px;
}
.faq_block > h4 {
    margin: 25px 0;
    font-weight: 800;
}
.faq_block > ol {
    padding: 0 15px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    font-weight: 300;
}
.faq_mini_wraper > h5 {
    margin-bottom: 15px;
}
.faq_mini_wraper > p {
    margin-top: 30px;
}
.faq_mini_wraper > ul {
    margin-left: 60px;
    margin-top: 30px;
}


.return_and_refund_font_bold {
    font-weight: 700;
}
.faq-container{
    height:fit-content;
    background-color: var(--pure-white);
    padding: 5vw;
    padding-top: 155px;
    color: var(--primar-color);
    /* font-family: "Instrument Sans"; */
}

.fq-header{
    font-size: 42px;
    font-weight: 400;
    line-height: 52px;
    text-align: justify;
    margin-bottom: 25px;
}
.q-container{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}
.q-container > p{
    font-size: 32px;
    line-height: 35px;
    font-weight: 400;  
}
.faq-common-style{
    font-size: 18px;
    line-height: 24px;
    font-weight: 400;  
    margin-bottom: 15px;
}
@media screen and (min-width: 768px) {
    .faq_main {
        width: 85vw;
        margin: 0 auto;
    }
    .faq-container {
        padding-top: 180px;
    }
    .fq-header{
        font-size: 70px;
        font-style: normal;
        line-height: 75px;
        letter-spacing: 1.4px;
        margin-bottom: 33px;
    }
}