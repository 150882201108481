.dnavbar {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #d3d3d3;
    padding: 0 50px;
    align-items: center;
    position: relative;
    z-index: 100;
}

.dlogo {
    width: 200px;
    height: 70px;
    object-fit: contain;
    margin: 0 10px;
}

.dbrand {
    font-size: 1.8rem;
    font-weight: 700;
    font-family: "Roboto Condensed";
}

.dnav_links {
    display: flex;
    align-items: center;
    list-style: none;
    gap: 2rem;
    margin: 0;
    font-size: 0.9rem;
    font-weight: 600;
}

@media screen and (max-width: 768px) {
    .dnavbar {
        margin-bottom: 15px;
    }
    .dnav_links {
        display: none;
    }
    .dbrand {
        padding-top: 23px !important;
        padding-left: 23px;
        font-size: 1.5rem;
    }

    .dlogo {
        width: 150px;
        height: 45px;
    }
}

.dnav_links > li > a {
    color: inherit;
}

.dnav_account {
    cursor: pointer;
}
