/* font-family: 'Cabin', sans-serif;
font-family: 'Roboto Condensed', sans-serif; */

.container {
    /* max-width: 1170px;
    margin: 0 auto; */
    padding: 0;
    position: relative;
    font-family: 'Cabin', sans-serif;
    min-height:100vh;
}

.nav_container {
    max-width: 1170px;
    margin: 0 auto;
}

.hero_section {
    height: 45vh;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1;
}

.main_section {
    z-index: 2;
    position: relative;
}

.hero_image {
    background-repeat: repeat;
    background-size: cover;
    background-image: url("https://assets.cdn.filesafe.space/gDomeI4d9kjTwwlhShAw/media/642235f704a5d51703b78f47.jpeg");
    background-position: 50%;
    height: 100%;
}

.hero_content {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
    flex-direction: column;
    color: white;
}

.hero_content > h1 {
    font-size: 3.5rem;
    font-weight: bolder !important;
    font-family: 'Roboto Condensed', sans-serif;
}

.hero_content > p {
    font-size: 1.2rem;
}

.hero_labels_container {
    display: flex;
    margin-top: 30px;
    gap: 30px;
}

.hero_labels_container > div {
    border: 2px solid white;
    border-radius: 10px;
    padding: 7px 0;
    display: flex;
    justify-content: center;
    width: 200px;
    font-size: 1.2rem;
    font-family: 'Roboto Condensed', sans-serif;
}

.main_form {
    position: relative;
    z-index: 5;
}

.main_form_box {
    max-width: 960px;
    margin-bottom: 50px;
    background-color: white;
    box-shadow: 0 1px 5px #0003;
    border-radius: 10px;
    margin: 50px auto;
    margin-top: 45px;
    padding: 45px 0;
}

.main_form_box .heading {
    text-align: center;
    color: #603E8B;
}

.main_form_box .heading > h1 {
    font-size: 2.2rem;
    font-weight: 700;
    font-family: 'Roboto Condensed', sans-serif;
}

.main_form_box .content {
    display: flex;
    width: 85%;
    margin: 0 auto;
}

.main_form_box .content > div {
    flex: 1;
}

.content_elements {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 20px;
}

.list_element {
    display: flex;
    gap: 8px;
}

.list_element_head {
    font-size: 1.3rem;
    font-weight: 600;
}

.list_element_info {
    font-size: 0.86rem;
    font-weight: 400;
    color: #9C9C9C;
}

.reference_container {
    margin: 0 auto;
    width: fit-content;
    margin-bottom: 60px;
}

.reference_container > ol > li {
    font-size: 0.9rem;
    line-height: 23px;
    color: #9C9C9C;
}

.loader_container {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media screen and (max-width: 768px) {
    .hero_content {
        margin-top: 0;
        padding-top: 80px;
    }
}

@media screen and (max-width: 690px) {
    .hero_labels_container {
        flex-direction: column;
    }

    .hero_labels_container > div {
        width: 270px;
    }
    
    .main_form_box .content {
        flex-direction: column;
        width: 90%;
    }

    .content_elements {
        margin-top: 20px;
    }
}

.footer_wrapper {
    position: absolute;
    /* bottom: 0; */
    top:105%;
    width: 100%; 
}

.iframe_container {
    display:flex;
    flex-direction: column;
    flex: 1;
    gap: 20px;
    margin-top: 29px;
    align-items: center;
}

.contact_input {
    width: 92%;
    /* border:none;
    padding: 15px 17px; */
    border-radius: 8px;
    border: 1px none #FFFFFF;
    background-color: #f7f7f7;
    padding: 15px 20px 15px 20px;
    box-shadow: 0px 0px 0px 0px #FFFFFF;
    font-family: roboto;
    font-size: 15.5px;
    color: #000000FF;
}

.contact_input:focus{
    outline: none;
}

.button_wrapper {
    width: 92%;
    display: flex;
    justify-content: flex-start;
}

.form_button {
    /* background-color: rgb(244, 58, 176);
    padding: 15px 40px;
    font-size: 16px;
    border-radius: 5px;
    font-weight: 700;
    width: 92%; */
    color: var(--white);
    background-color: rgb(244, 58, 176);
    border: 0px none rgb(255, 255, 255);
    border-radius: 5px;
    padding: 13px 40px;
    min-width: 50%;
    white-space: normal;
    box-shadow: rgb(255, 255, 255) 0px 0px 0px 0px;
    font-size: 16px;
    font-weight: 600;
    font-family: roboto;
}

.error {
    color:red;
    font-size: 14px;
}