.auth_s_container {
    background-image: url("../../images/bg_loading_05.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 85vh;
    display: flex;
    align-items: flex-end;
}

.auth_s_content {
    width: 100%;
    text-align: center;
    padding-bottom: 5%;
}

.auth_s_content > div {
    margin: 15px 0;
    font-weight: 600;
}

.auth_s_content span {
    background-color: white;
    border-radius:8px;
    border-style:solid;
    border-width:2px;
    border-color:white;
    box-shadow: 0px 0px 25px white;

}

.auth_heading h1{
    font-weight: 800;
}

.browse_as_guest{
    text-align: right;
    text-decoration: underline;
    margin-top: 10px;
    font-size: 14px;
    padding-right: 5px;
}

.auth_s_content a {
    color: inherit;
    text-decoration: underline !important;
}