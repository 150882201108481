.product-card {

  padding: 20px 80px 0px 80px;
  display: flex;
}

.product-image-section {
  max-width: 600px;
}

.product-title {
  font-size: 50px;
  font-weight: bold;
  font-family: "alternategotno1dregular";
  margin: 0;
}

.product-subtitle {
  font-size: 18px;
  color: #555;
  margin-bottom: 25px;
}

.price {
  font-size: 16px;
  margin-bottom: 70px !important;
}

.prices {
  font-weight: bold;
  color: #ff0000;
}

.donation {
  font-size: 18px;
  color: #000;
}

.size-guide {
  margin-bottom: 20px;
  color: black;
  font-weight: bold;
  font-size: 20px;
  margin-top: 50px;
}

.description {
  margin-bottom: 20px;
  color: black;
  font-weight: bold;
  font-size: 20px;
}

.description-text {
  color: black;
  font-size: 18px;
}

.size-guide ul {
  list-style: disc;
  padding: 0;
  margin-left: 40px;
  font-size: 14px;
  color: #000;
}

.margin-bottom {
  margin-bottom: 20px;
}

.nike-partnership {
  margin-bottom: 20px;
  color: #ff0000;
  text-align: center;
  font-weight: bold;
}

.partnership-highlight {
  font-size: 16px;
  margin-bottom: 10px;
  text-align: center;
  color: #ff0000;
}

.action-buttons {
  display: flex;
  /* gap: 10px; */
  margin-bottom: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 350px;
}

.add-to-bag {
  padding: 14px 20px;
  font-size: 18px;
  background-color: black;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 32px;
  width: 100%;
}

.add-to-bag:hover,
.add-to-bag:focus {
  background-color: #ff0000;
}

.add-to-wishlist {
  padding: 14px 20px;
  font-size: 18px;
  color: black;
  border: 2px solid black;
  cursor: pointer;
  border-radius: 5px;
  width: 100%;
  border-radius: 32px;
}

.size-options {
  display: flex;
  gap: 10px;
  margin-top: 30px;
  margin-bottom: 30px;
}

.size {
  padding: 8px 20px;
  border: 2px solid #000;
  cursor: pointer;
  width: 64px;
  border-radius: 32px;
}

.size:hover {
  border-color: black;
}

.main-slider {
  margin: 0 0 30px;
  border-radius: 12%;
  overflow: hidden;
}

.thumb-slider {
  max-width: 470px;
}

.thumb-slider .slick-track {
  display: flex;
  gap: 20px;
}

.thumb-slider .slick-slide {
  margin: 0 15px 0 0;
  border-radius: 15%;
}

.thumb-slider .slick-slide img {
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 25%;
}

.main-slider .slick-prev:hover,
.main-slider .slick-prev:focus,
.main-slider .slick-prev {
  background: url(../../../../images/arrow-left.png) no-repeat;
  background-size: 100% 100%;
  width: 30px;
  height: 50px;
  left: 40px;
  z-index: 99;
}

.main-slider .slick-prev:before {
  display: none;
}

.main-slider .slick-next:hover,
.main-slider .slick-next:focus,
.main-slider .slick-next {
  background: url(../../../../images/arrow-next.png) no-repeat;
  background-size: 100% 100%;
  width: 30px;
  height: 50px;
  right: 40px;
  z-index: 99;
}

.main-slider .slick-next:before {
  display: none;
}

.select-size {
  background-color: #000;
  color: white;
}

.unselect-size {
  background-color: transparent;
  color: black;
}

.slick-dots {
  bottom: -2px !important;
}