.main {
    min-height: 100vh;
    padding-inline: 30px;
    padding-block: 74px;
    display: flex;
    flex-direction: column;
    gap: 46px;
    max-width:500px;
    margin-inline: auto;
}
.logo_container {
    width: 100%;
    /* height: 90px; */
}
.content_container {
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.heading {
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 35px; /* 109.375% */
    letter-spacing: 0.64px;  
    width:80%;  
}
.sub_heading {
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 133.333% */
    letter-spacing: 0.36px;  
    width:80%    
}
.amount {
    display: flex;
    gap: 15px;
}
.price {
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.36px;   
    margin: 0; 
}
.buttons_container {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

@media screen and (max-width:369px) {
    .amount {
        flex-direction: column-reverse;
    }
    .heading {
        width:100%;  
    }
    .sub_heading {  
        width:100%    
    }
}

@media screen and (min-width:768px) {
    .buttons_container {
        flex-direction: row;
        gap: 20px;
    }
    .main {
        gap: 90px;;
    }
}