:root {
    --primary-background-color: #f0e8e3
}
.main_container{
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    background-color: var(--primary-background-color);
    
}

.sub_container{
    display: flex;
    width:100%;
    /* flex-grow: 1; */
    height: 100%;
}
.bg_logo{
    /* width: 200px; */
    width: 71%;
    height: 59vh;
}

.coming_soon{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    text-align: center;
}

.coming_soon h3 {
    font-size: 35px;
    /* color: var(--color-lffu6uvb); */
    font-weight: 600;
}
.coming_soon > div{
    height: 85%;
}
.coming_soon > div>img{
    height: 90%;
    flex-grow: 1;
}

@media (max-width: 1110px){
    .bg_logo{
        /* width: 120px; */
        width: 85%;
        height: auto;
    }
   
}

@media (max-width: 768px){
   
    .sub_container{
        height: 73%;
    }
    .coming_soon h3 {
        font-size: 25px;
    }
    .bg_logo{
        /* width: 120px; */
        width: 95%;
        height: auto;
    }
   
}