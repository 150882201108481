.register_outer_container {
    min-height: 100vh;
    min-width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
}

.form_controller p {
    margin: 0;
}

.placeholder_v {
    height: 60px;
    width: 100%;
    background: transparent;
}

.register_container {
    width: 80%;
    min-height: 90vh;
    box-shadow: 10px 10px 15px -7px rgba(179, 179, 179, 1);
    display: grid;
    grid-template-columns: 40% 60%;
    border-radius: 25px;
}

.register_image {
    object-fit:cover;
    /* width: 100%; */
    height: 100%;
    max-height: 100%;
    max-width: 100%;
    border-radius: 25px 0 0 25px;
}

.right{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.right_header {
    text-align: center !important;
    width: 70%;
    margin: 5px auto;
    padding-top: 4%;
}
.reg_verify_text {
    margin: 15px 0 20px 0;
    background: #00b30012;
    color: #00b300;
    border-radius: 4px;
    border: 0.5px solid;
    padding: 5px 12px !important;
    text-align: left;
    font-size: 12px;
  }

.right_headers_h2 {
    font-weight: 500 !important;
    font-size: 23px !important;
    margin-top: 1%;
}

.right_headers_h3 {
    font-weight: 400 !important;
    font-size: 15px !important;
    margin-top: 2%;
}

.form_container {
    display: flex;
    flex-direction: column;
    width: 80%;
    margin: auto;
}
.name_input{
    display: grid;
    grid-template-columns: 48% 48%;
    gap: 4%;
}
.form_controller {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-bottom: 10px;
}
.form_controller input:focus {
    outline: none;
} 

.form_controller label {
    color: #5D77A7;
    font-weight: 600;
    font-size: 0.8rem;
}

.form_controller input {
    width: 100%;
    padding: 8px 10px;
    border: 1px solid #d3d3d3;
    font-size: 0.9rem;
}

.error_input{
    background-color: #ffdfdf !important;
    /* color: #ffdfdf !important; */
}


.password input {
    display: flex;
    flex-direction: column;
    width: 100%;
    border: 1px solid #d3d3d3;
    padding: 8px 10px;
    font-size: 0.9rem;
}

.password div .eye {
    position: absolute;
    right: 3%;
    top: 50%;
    transform: translateY(-50%);
}

.modal{
    padding: 0 2%;    
}

.modal h5{
    text-align: center;
    margin-top: 10%;
}

.modal button{
    color: white;
    background-color: #1a385c;
    font-size: 18px;
    height: 40px;
    margin-top: 50px;
    width: 100%;
}