.new_footer_container {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding-block: 29px;
}

.new_footer_btn {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    /* text-decoration-line: underline; */
    border: 0;
    padding: 5px 1px;
    width: fit-content;
}
.footer_need_help{
    font-size: 18px;
    font-weight: 700;
}

.need_help_mf{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}