.cardWrapper {
    display: flex;
    gap: 10px;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
}

.cardContainer {
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: flex-start;
    margin-inline: auto;
    max-width: 419px;
    width: 300px;
}

/* .cardTitle {
} */

.imageContainer {
    /* height: 400px; */
    /* height: 300px; */
    height: 350px;
    width: 100%;
}

.imageContainer > img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.linkBox {
    padding-block: 10px;
    border-bottom: 1px solid var(--red-color);
}

.link {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.linkText {
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    color: var(--red-color);
}

.arrow {
    color: var(--red-color) !important;
}


@media screen and (max-width:1300px) {
    .cardWrapper {
        gap: 40px;
    }
}

@media screen and (max-width:450px) {
    .cardContainer {
        width: 100%;
    }
    .imageContainer {
        height: 400px;
    }
}