.marketmaindiv {
  display: flex;
  padding: 80px;
  background-color: #eff0f3;
  justify-content: space-between;
}

.top_title {
  font-size: 64px;
  text-align: left;
  font-weight: bold;
  line-height: 72px;
  font-family: 'alternategotno1dregular';
  letter-spacing: 0.5px;
}
.bottom_text {
  text-align: left;
  font-size: 20px;
  margin: 15px 0;
  font-size: 26px;
  line-height: 1.2;
}
.market_btn_div {
  display: flex;
  justify-content: left;
  margin-top: 40px;
}
.bottom_btn {
  border-Radius: 40px;
  min-width: 250px;
  padding: 7px 20px;
  background-Color: #f24423;
  font-Weight: bold;
  font-family: 'alternategotno1dregular';
  font-size: 2rem;
  color: #ffffff;
}

.right_main {
  max-width: 420px;
}

.right_main img {
  width: 100%;
}

.right_box_main {
  display: flex;
  justify-content: end;
  align-items: center;
}
.right_box {
  margin-top: 30px;
  width: 40vw;
  height: 380px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}
.right_box div {
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3px;
}

.expense_percentage {
  color: #f24423;
  font-weight: bold;
  align-items: center;
  font-family: 'alternategotno1dregular';
  font-size: 56px;
  line-height: 40px;
}

.market_checked_img {
  height: 70px;
  width: 70px;
  margin-bottom: 24px;
}
.exp_text{
  font-weight: bold;
  font-family: 'alternategotno1dregular';
  font-size: 30px;
}

@media (max-width: 899px) {
  .marketmaindiv {
    padding: 40px;
  }

  .top_title {
    font-size: 60px;
    line-height: 65px;
    letter-spacing: 0.5px;
  }

  .right_box {
    width: 80vw;
  }

  .right_box_main {
    margin-top: 30px;
    justify-content: start;
  }

  .right_main {
    margin: auto;
  }

}