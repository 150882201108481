.featuredContainer {
    display: flex;
    flex-direction: column;
    gap: 70px;
}

.featuredHeader {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.featuredHeading {
    width: min-content;
}

.featuredPara {
    font-size: 20px;
    line-height: 32px;
    font-weight: 400;
    max-width: 358px;
}

@media screen and (max-width:768px) {
    .featuredContainer {
        gap: 40px;
    }
    .featuredHeader {
        flex-direction: column;
        gap: 20px;
    }
}