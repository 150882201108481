.eventDetailsSection {
    padding-top: 30px;
    padding-bottom: 30px;
    background-color: var(--pure-white);
}

.eventDetailsMarginContainer {
    padding-inline: 80px;
}

.textCenter {
    text-align: center;
}

.auctionTitle {
    text-align: center;
}

.info_btn_class {
    text-transform: capitalize;
    border-bottom: 1px solid var(--black-color);
    color: var(--black-color);
}

.marginBottom0 {
    margin-bottom: 0;
}

.completed_alert {
    font-size: 22px;
    font-weight: 600;
    color: var(--red-color);
}

@media screen and (max-width:768px) {
    .eventDetailsSection {
        padding-top: 20px;
        padding-bottom: 20px;
    }
    .eventDetailsMarginContainer {
        padding-inline: 5vw;
    }
}