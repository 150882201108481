.browse-page-containers {
    padding: 5vw 5vw;
    padding-bottom: 25vw;
}

.event-page-lower-part {
    display: flex;
    flex-direction: column;
    /* gap: 28px; */
}

.image {
    max-width: 90vw;
}

.image-container {
    display: flex;
    overflow-x: auto;
    height: fit-content;
    position: relative;
    gap: 10px;
}

.image-container::-webkit-scrollbar {
    display: none;
}

.sub-category-images {
    width: 100px !important;
    height: 100px !important;
    margin: 10px;
    margin-bottom: 0;
    border-radius: 20px;
    object-fit: cover;
}

.sub-category>h6 {
    margin-bottom: 0px;
    margin-top: 10px;
    text-align: center;
    font-weight: 600;
    color: #333;
    font-size: 13px;
}

.extra_class>div {
    margin: 5vw;
}

.mobile_browse_items_container {
    margin: 6vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 17px;
}

.mobile_browse_items_container>p {
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 25px;
    letter-spacing: 0.4px;
}

.buttons_container {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

/* /// for category */

.browse_category_main_container {
    display: flex;
    flex-direction: column;
    /* padding-top: 5vw; */
}

.browse_category_section {
    display: flex;
    flex-direction: column;
}

.custom_category_container {
    margin: 6vw;
}

.custom_category_heading {
    font-size: 21px;
    font-style: normal;
    font-weight: 700;
    line-height: 25px;
    letter-spacing: -0.4px;
}

.to_top_btn_container {
    display: flex;
    justify-content: flex-start;
    /* margin: 6vw; */
}

.to_top_btn {
    padding: 2px;
    background: none;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    margin: 6vw;
}


@media screen and (min-width: 768px) {
    .browse-page-containers {
        margin-bottom: 100px;
        max-width: 1200px;
        margin: 30px auto;
        padding: 0 20px;
    }
    .custom_category_heading{
        font-size: 20px;
    }

    .dbuy-lower-items-container {
        display: flex;
        align-content: center;
        justify-content: space-between;
        margin-top: 50px;
    }

    .dbuy-lower-items-container>div {
        width: 40%;
        box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
    }

    .deventbrowse_cards {
        padding: 0;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        row-gap: 60px;
        column-gap: 20px;
    }

    .dbuy-lower-items-container .page_heading {
        border: 1px solid #d3d3d3;
        padding: 10px 0;
        margin-bottom: 0 !important;
    }

    .dbuy-lower-items-container .page_heading>h3 {
        font-weight: 600;
        font-size: 1.1rem;
        padding: 7px 20px;
        text-transform: uppercase;
    }

    /* for new desktop designs */

    .browse_category_main_container {
        padding-top: 0;
    }
    .custom_category_container {
        margin-inline: 0;
        margin-block: 39px;
        display: flex;
        flex-direction: column;
        gap: 14px;
    }
    .sub-category {
        gap: 31px !important;
    }
}

@media screen and (min-width: 1200px) {
    .browse-page-containers {
        width: 1250px;
    }
}

@media screen and (max-width: 925px) {
    .deventbrowse_cards {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media screen and (max-width:768px) {
    .image-container {
        margin-top: 20px;
    }
}

/* new desktop event design */

.desktop_browse_section_main_container {
    padding-block: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.desktop_browse_buttons_wrapper {
    display: flex;
    flex-direction: column;
    gap: 47px;
    width: 100%;
}