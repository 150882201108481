.boundlessCausesContainer {
    display: flex;
    gap: 70px;
}

.causesLeft {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    width: 380px;
    gap: 70px;
}

.causeTitle {
    width: min-content;
}

.paraPart {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.para {
    font-size: 20px;
    font-weight: 400;
    line-height: 32px;
}

.causesRight {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 40px;
}

.singleGrid {
    grid-template-columns: 1fr;
}

.featuredCauseImageContainer {
    /* width: 300px;
    height: 300px; */
    width: 380px;
    height: 380px;
    position: relative;
    cursor: pointer;
    transition: scale 0.3s ease-in-out;
}
.featuredCauseImageContainer:hover {
  scale: 98%; 
  transition: scale 0.3s;
}

.bannerImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.causeLogo {
    height: 86px;
    width: 86px;
    position: absolute;
    border-radius: 50%;
    background-color: var(--pure-white);
    top: 24px;
    left: 24px;
}

@media screen and (min-width:1500px){
    .causesLeft {
        width: 480px;
    }
    .featuredCauseImageContainer {
        width: 480px;
        height: 480px;
    }
}

@media screen and (min-width:1750px){
    .causesLeft {
        width: 580px;
    }
    .featuredCauseImageContainer {
        width: 580px;
        height: 580px;
    }
}

@media screen and (max-width:1189px) {
    .boundlessCausesContainer {
        flex-direction: column;
        gap: 32px;
    }
    .causesLeft {
        width: 100%;
        gap: 70px;
    }
    .featuredCauseImageContainer {
        width: 100%;
        height: 350px;
    }
}

@media screen and (max-width:768px) {
    .featuredCauseImageContainer {
        /* width: 260px; */
        width: 100%;
        height: 260px;
    }
    .causeLogo {
        height: 76px;
        width: 76px;
    }
    .causesLeft {
        gap: 32px;
    }
}

@media screen and (max-width:600px) {
    .featuredCauseImageContainer {
        /* width: 200px; */
        width: 100%;
        height: 200px;
    }
    .causeLogo {
        height: 70px;
        width: 70px;
    }
}

@media screen and (max-width:430px) {
    .causesRight {
        gap: 24px;
    }
    .featuredCauseImageContainer {
        width: 100%;
        height: 150px;
    }
    .causeLogo {
        height: 46px;
        width: 46px;
        top: 10px;
        left: 10px;
    }
}

.fullwidthImage {
    width: 100%;
}