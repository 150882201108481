h2 {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.checkbox-group {
  margin-bottom: 15px;
}

.checkbox-group label {
  font-size: 1rem;
  display: flex;
  align-items: center;
}

.checkbox-group input {
  margin-right: 10px;
  width: 28.99px;
}

.dropdown-group {
  margin-top: 20px;
}

.dropdown-group label {
  display: block;
  margin-bottom: 5px;
  font-size: 1rem;
}

.dropdown-group select {
  /* width: 100%; */
  padding: 8px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.purchase-history {
  min-height: 169.698px;
}
.purchase-history .checkbox-group {
  display: flex;
  /* justify-content: space-between; */
}

.purchase-history .dropdown-group {
  background-color: white;
  display: flex;
  justify-content: space-between;
}

.contact-method {
  width: 100px;
  background-color: #ffffff;
}

.pref h4 {
  font-weight: bold;
  border-top: 1px solid #e3d6d6;
  padding-top: 20px;
}

.email-h {
  font-weight: bold;
  padding-top: 20px;
}
