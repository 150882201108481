.alert-login-signup{
  font-size: 18px;
  font-weight: 400;
  font-family: 'Instrument Sans';
}

.redirect-to-login{
  text-decoration: underline;
  color: black;
}

.redirect-to-signup{
  text-decoration: underline;
  color: black;
}

.new_buttons_container {
  display: flex;
  justify-content: space-between;
  gap: 16px;
  margin-top: 20px;

}

@media screen and (max-width:295px) {
  .new_buttons_container {
    gap: 0;
    margin-top: 10px;
    flex-direction: column;
  }
}

.new_each_button {
  width: 100%;
}

@media screen and (min-width:480px) {
  .bid_class {
    justify-content: flex-end;
  }
  .new_each_button>button {
    max-width: fit-content ;
  }
  .new_each_button {
    width: fit-content;
    flex-direction: column;
    gap: 0;
    margin-top: 10px;
  }
}