.quanatity_change_btn {
  background-color: var(--secondary-btn-clr);
  color: white;
  margin-right: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px;
}

.product-title {
  font-size: 50px;
  font-weight: bold;
  font-family: "alternategotno1dregular";
  margin: 0;
}

.product_name_section>.market_place_name {
  font-family: 'Arial';
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 35px;
  letter-spacing: -0.64px;
  word-break: break-all;
}

.product_name {
  color: #8f8f8f;
  font-style: italic;
  font-size: 16px;
  margin-bottom: 30px !important;
}

.product_short_desc {
  font-family: 'Arial';
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
}

.lot_stuff_row>.time_left_text {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  text-align: center;
}

.quanatity_change_btn {
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  background-color: var(--shady-white);
  margin-right: 0 !important;
  padding: 0 !important;
}

.lot_stuff_row>.select_qty_text {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  text-align: right;
  /* 150% */
}

.lot_stuff_price_quantity {
  display: flex;
  justify-content: space-between;
}

.lot_stuff_quantity_controls {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.lot_details_header {
  display: flex;
  justify-content: space-between;
  align-items: start;
  /* gap: 10px; */
}

.product_name_section {
  width: 90%;
  margin-bottom: 30px;
}

.favouritesTooltip {
  font-size: 16px;
  line-height: 19px;
  font-weight: 400;
}

.reserve-not-met-text {
  display: flex;
  align-items: center;
}

.info-icon {
  font-size: 1.2rem !important;
  margin-left: 5px !important;
}

.discount-percentage {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.discount-percentage>span {
  background-color: #000;
  color: white;
  padding: 2px 5px;
  border-radius: 3px;
  font-size: 14px;
  /* height: fit-content; */
}

.small-count-down-container {
  display: flex;
  justify-content: center;
}

@media screen and (max-width:768px) {
  .info-icon {
    margin-bottom: 3px
  }
}

/* new design for desktop */


@media screen and (max-width:1060px) {
  .auction_lot_stuff_row {
    flex-direction: column;
  }
}


@media screen and (min-width:768px) {
  .lot_stuff_row>.time_left_text {
    text-align: left;
  }

  .auction_lot_stuff_row {
    display: flex;
    justify-content: space-between;
  }
}