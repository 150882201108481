.np-container{
    height:fit-content;
    background-color: var(--pure-white);
    padding: 5vw;
    padding-top: 135px;
    color: var(--red-color);
}

.np-heading{
    font-size: 46px;
    font-weight: 400;
    line-height: 52px;
    text-align: justify;
}

.np-sub-heading{
    font-size: 32px;
    font-weight: 500;
    line-height: 35px;
}
.np-subtext{
    font-weight: 400;
    line-height: 24px;
    font-size: 20px;
}

.common-style{
    font-size: 20px;
     font-weight: 400;
      margin: 20px 0;
      line-height: 24px;
}

.d_np_signup{
    width:100%;
    height: auto;
    background-color: var(--red-color);
    color: var(--white-color);
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    margin-bottom: 50px;
    padding: 25px;
}
.d_np_signup > h3{
    padding-bottom: 5px;
    font-size:26px;
    text-align: center;

}
.d_np_signup > button{
    width: 90px;
    height: 30px;
    border: 1px solid var(--white-color);
    border-radius: 20px;
    color: var(--white-color);
}

.para_list {
    display: flex;
    gap: 6px;
}

@media screen and (min-width:768px) {
    
   .d_np_signup{
        /* height: 250px; */
        padding: auto;
    }
   .d_np_signup > h3{
        /* font-size:42px; */
        font-size:32px;
        padding-bottom: 25px;
    }
    .np-container{
        /* width: 100%; */
        padding: 0;
        padding-top: 170px;
    }
    .np-heading{
        padding-bottom: 35px;
        font-size: 72px;
    }
    .np-sub-heading{
        font-size: 42px;
        line-height: 50px;
    }

    .d_np_signup{
        width:100%;
        /* height: 250px; */
        background-color: var(--red-color);
        color: var(--white-color);
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 50px;
        margin-bottom: 50px;
    }
    .d_np_signup > p{
        padding-bottom: 20px;
        font-size:42px;
    }
    .d_np_signup > button{
        width: 115px;
        height: 38px;
        border: 1px solid var(--white-color);
        border-radius: 20px;
        color: var(--white-color);
    }
    .chart > .chart-head{
        height: 110px;
        color: whitesmoke;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 0px;
        background-color: black;
    }
    .chart-head > p{
        font-size: 18px;
        font-weight: 400;
    }
    .para_list {
        gap: 2px;
    }
}