.sortScreen {
    background-color: var(--pure-white);
    padding: 32px 24px;
    display: flex;
    flex-direction: column;
    gap: 32px;
    height: 100%;
    overflow-y: auto;
}

.sortScreenHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.filterHeading {
    color: var(--black-color) !important;
}

.sortContainer {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.sortSection {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.sortGroup {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.sortName {
    font-size: 14px;
    font-weight: 600;
    line-height: 17.08px;
    color: var(--black-color);
    text-transform: uppercase;
}

.textTransformCapitalize {
    text-transform: capitalize;
}

.groupName {
    font-size: 14px;
    font-weight: 600;
    line-height: 17.08px;
    color: #30313a9c;
    text-transform: uppercase;
}

.squareRadio input[type="radio"] {
    opacity: 0;
    position: absolute;
    width: 0;
    height: 0;
}

.squareRadio input[type="checkbox"] {
    opacity: 0;
    position: absolute;
    width: 0;
    height: 0;
}

.squareRadio .customRadio {
    display: inline-block;
    position: relative;
    width: 18px;
    height: 18px;
    background-color: var(--pure-white);
    border: 2px solid var(--black-color);
    border-radius: 0;
    vertical-align: middle;
    cursor: pointer;
}


.squareRadio input[type="radio"]:checked + .customRadio::before {
    content: '';
    display: block;
    background-color: var(--black-color);
    position: absolute;
    width: 10px;
    height: 10px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-sizing: border-box;
}

.squareRadio input[type="checkbox"]:checked + .customRadio::before {
    content: '';
    display: block;
    background-color: var(--black-color);
    position: absolute;
    width: 10px;
    height: 10px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-sizing: border-box;
}