.favouriteButtonContainer {
    position: absolute;
    top: 2px;
    right: 3px;
}

@media screen and (max-width:767px) {
    .favouriteButtonContainer {
        top: 10px;
        right: 10px;
    }
}