/* :root {
    --font-family: 'Instrument Sans' ;
} */
.cart-page-font {
    font-family: Arial;
}

.alert1 {
    padding-bottom: 10px;
}

.alert1>span {
    margin-left: 5px;
}

.cart-card-container {
    width: 100%;
    /* border-bottom: 1px solid #30313A; */
    /* border-radius: 20px; */
    /* margin: 0 auto; */
    /* padding-bottom: 15px; */
    /* margin-bottom: 20px; */
    display: flex;
    overflow: hidden;
    gap: 15px;
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid #DEDEDE;
}

.cart-card-img_container {
    /* width: 120px; */
    width: 130px;
    height: 130px;
    padding: 0;
    position: relative;
}

.cart-card-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
    cursor: pointer;
}

.cart-card-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 0;
    justify-content: flex-start;
    gap: 3px;

}

.cart-card-content>h1 {
    font-family: 'Arial';
    font-size: 25px;
    font-weight: bold;
    margin-bottom: 15px;
}

.cart-card-content>h3 {
    font-family: 'alternategotno1dregular';
    font-size: 20px;
    font-weight: 600;
    padding-top: 15px;
}

.cart-card-content>p {
    /* font-family: "AvenirNext"; */
    font-size: 12px;
    font-weight: 500;
    line-height: 12px;
    text-align: left;
    height: 85px;
    overflow: hidden;
}

.cart-card-qty_price {
    font-size: 15px;
    font-weight: 600;
}

.cart-card-remove_price {
    display: flex;
    justify-content: end;
    align-items: start;
    gap: 10px;
}

.cart-card-qty_price .remove-btn {
    color: grey;
    box-sizing: border-box;
    padding: 0.5rem;
    border: none;

}

.no-items {
    display: flex;
    font-size: 20px;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.cart-card-qty_price .increment_btn {
    display: inline-block;
    background-color: var(--shady-white);
    color: white;
    margin-right: 5px;
    text-align: center;
    margin-left: 8px;
}

.mobile_quantity_wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
    padding: 5px 2px;
}

.mobile_quantity_wrapper>.increment_btn {
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    background-color: var(--shady-white);
    margin-right: 0 !important;
    padding: 0 !important;
    cursor: pointer;
}

.increment_btn:disabled {
    background-color: gray;
}

.quantity_text {
    text-align: right;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.28px;
}

.summary_container {
    width: 100%
}

.mobile_cart_checkout_buttons_wrapper {
    display: flex;
    justify-content: space-between;
    column-gap: 50px;
}

.bag-container-empty {
    width: 500px;
    margin: auto;
}

.cart-outer-container {
    background-color: #FAFAFA;
    /* padding-bottom: 250px; */
    /* padding-bottom: 40px; */
    min-height: 100vh;
}

.need_help {
    text-align: left;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: -0.36px;
}

.contact_us {
    text-align: left;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    margin-bottom: 50px;
}

.cart_subtotal_heading {
    font-size: 30px;
    font-style: normal;
    font-weight: bold;
    line-height: 24px;
    letter-spacing: 0.5px;
    position: relative;
    color: #FF0000;
    text-transform: uppercase;
    font-family: 'alternategotno1dregular';
}

.tooltip-container {
    background-color: rgb(97, 97, 97);
    color: white;
    width: 100%;
    position: absolute;
    top: -60px;
    left: 27%;
    padding: 5px;
    border-radius: 5px;
}

.tooltip-container>p {
    font-size: 12px;
    margin: 0px;
    line-height: 18px;
}

.order_headings {
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 25px;
    letter-spacing: -0.4px;
}

.bag-card-price {
    text-align: right;
    font-size: 30px;
    font-style: normal;
    font-weight: bold;
    color: #FF0000;
    line-height: 24px;
    letter-spacing: 0.5px;
    font-family: 'alternategotno1dregular';
}

.cart-card-remove_price>h1 {
    /* display: flex;
        justify-content: space-between;
        align-items: center;  */
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.36px;

}

.cart-card-remove_price>p {
    /* display: flex;
        justify-content: space-between;
        align-items: center;  */
    color: var(--red-color);
    font-weight: 500;
}

.shipping_payment_container {
    display: flex;
    flex-direction: row;
    gap: 35px;
}

.emptyContainerSoldItems {
    padding-top: 30px;
    width: 66%;
    padding-bottom: 50px;
}

.bag-order-summary {
    display: flex;
    justify-content: space-between;
    align-items: end;
    gap: 50px;
}

.order-details {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 32px;
}

.cart-outer-container .card {
    padding: 32px !important;
    justify-self: normal;
    margin-bottom: 40px;
    background-color: white;
    border-radius: 15px !important;
}

@media screen and (max-width:768px) {

    .bag-order-summary {
        flex-direction: column;
        align-items: start;
    }

    .cart-outer-container .card {
        padding: 20px !important;
    }

    .emptyContainerSoldItems {
        width: 100%;
        padding-top: 20px;
        padding-bottom: 0px;
    }

    .need_help {
        text-align: center;
    }

    .cart_subtotal_heading {
        font-size: 18px;
    }

    .order-details  h4 {
        font-size: 18px !important;
    }

    .order-details p {
        font-size: 16px !important;
    }

    .cart-card-remove_price h5 {
        font-size: 18px !important;
    }

    /* .cart-outer-container{
        padding-bottom: 30px;
    } */
    .cart-card-container {
        width: 100%;
        border: none;
        margin-bottom: 0;
        gap: 10px;
    }

    .cart-card-img_container {
        /* padding: 0; */
        border-radius: 25px;
    }

    .cart-card-img {
        border-radius: 25px;
    }

    .cart-card-content {
        justify-content: flex-start;
        gap: 3px;
        font-family: Arial;
    }

    .cart-card-content>h1 {
        font-family: Arial;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px;
        letter-spacing: -0.28px;
    }

    .cart-card-content>h3 {
        font-family: Arial;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px;
        letter-spacing: -0.28px;
    }

    .cart-card-content>p {
        /* font-family: "AvenirNext"; */
        font-size: 12px;
        font-weight: 500;
        line-height: 12px;
        text-align: left;
        height: 85px;
        overflow: hidden;
    }

    .cart-card-remove_price>h1 {
        /* display: flex;
        justify-content: space-between;
        align-items: center;  */
        font-size: 18px;

    }



    .summary_container {
        margin-top: 31px;
        margin-bottom: 0px;
        width: 100%;
    }


    .bag-order-summary>h1 {
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 25px;
        letter-spacing: -0.4px;
    }

    .bag-card-price {
        font-size: 18px;
        letter-spacing: -0.36px;
    }

    .mobile_cart_checkout_buttons_wrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 20px;
        margin-top: 32px;
    }


    .bag-container-empty {
        width: fit-content;
    }

    .shipping_payment_container {
        flex-direction: column;
        gap: 0;
    }
}