:root {
    --brandSecondaryColor:#30313A;
}
.br-container{
    height:fit-content;
    background-color: #F0E8E3;
    padding: 5vw;
    padding-top: 120px;
    color: var(--brandSecondaryColor);
}

.br-heading{
    font-size: 42px;
    font-weight: 400;
    line-height: 52px;
    text-align: justify;
    /* margin-top: 25px; */
}
.br-sub-heading{
    font-size: 32px;
    font-weight: 400;
    line-height: 35px;
}

.br-common-style{
    font-size: 20px;
    font-weight: 400;
     margin: 20px 0;
     line-height: 24px;
}
.br-not-into-resale{
    font-size: 20px;
font-style: normal;
font-weight: 700;
line-height: 25px; /* 125% */
/* letter-spacing: -0.4px; */
}
.mt-35{
    margin-top: 35px;
}

.email_signup {
    width:100%;
    height: auto;
    background-color: var(--brandSecondaryColor);
    color: white;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    margin-bottom: 50px;
    padding: 25px;
}

.email_signup > p{
    padding-bottom: 5px;
    font-size:20px;
    text-align: center;

}

.email_signup > button {
    width: fit-content;
    padding: 5px 15px;
    border: 1px solid white;
    border-radius: 20px;
    color: white;
}

@media screen and (min-width:768px) {
    .email_signup {
        height: 250px;
        padding: auto;
    }
   .email_signup  > p{
        font-size:42px;
        padding-bottom: 25px;
    }
    .br-container{
        padding: 0;
        padding-top: 170px;
    }
    .br-heading{
        padding-bottom: 35px;
        font-size: 72px;
    }
    .br-list-container{
        margin-bottom: 35px;
    }
    .br-sub-heading{
        padding-bottom: 35px;
        font-size: 37px;
        line-height: 50px;
    }
    .mb-35{
        margin-bottom: 35px;
    }
    .mt-35{
        margin-top:15px;
    }
}