.eventDetailsCard {
    color: var(--red-color) !important;
    font-size: 24px !important;
    text-align: center !important;
    height: auto !important;
    margin-bottom: 0 !important;
}

.featuredItemCard {
    /* font-family: 'Instrument Sans' !important;
    height: auto !important;
    font-size: 18px !important;
    color: #999 !important;
    font-style: italic !important; */

    font-family: "alternategotno1dregular" !important;
    font-weight: 700;
    font-size: 30px !important;
    line-height: 1.6 !important;
    word-break: break-word;
    margin: 0;
    text-transform: capitalize !important;
    font-weight: bold !important;
    color: var(--black-color) !important;
    height: auto !important;
}

.donationLabel {
    font-family: 'Instrument Sans' !important;
    font-size: 9px !important;
    font-weight: 600 !important;
    line-height: 9.76px !important;
    letter-spacing: 5% !important;
    margin-bottom: 0;
}

@media screen and (max-width:768px) {
    .eventDetailsCard {
        font-size: 16px !important;
    }
}