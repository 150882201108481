.main_section {
    margin: 25px 0px;
    padding-bottom: 35px;
    border-bottom: 1px solid rgb(48, 49, 58)
}

.contianer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px
}

.arrow_image {
    margin-left: 5px;
    cursor: pointer;
    transition:transform 0.8s;
}

.carousel_section {
    display: flex;
    justify-content: space-between
}

.cause_image {
    height: 150px;
    width: 200px;
    object-fit: cover
}

.legal_name {
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    padding-block: 20px;
    font-family: DM Mono;
    margin-bottom: 0
}

.about_cause_text {
    padding-bottom: 35px;
    text-align: left;
    font-size: 18px;
    line-height: 24px;
    font-weight: 400
}

.image_section {
    width: 30%;
}
.content_section {
    width: 50%;
}

@media screen and (max-width:767px) {
    .contianer {
        margin-bottom: auto;
    }
}