.subscribe {
    display: flex;
    flex-direction: column;
    padding: 5vw;
    justify-content: center;
    gap: 20px;
}

.subscribe>p {
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 35px;
    /* 109.375% */
    letter-spacing: -0.64px;
    width: 80%;
}

.subscribe>input {
    width: 100%;
    background: transparent;
    border: solid;
    border-radius: 5px;
    padding: 8px;
    opacity: 0.7;

    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    /* 150% */
}

.subscribe>button {
    display: flex;
    width: 124px;
    height: 38px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    border: solid;
    border-radius: 25px;

    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    /* 100% */
    letter-spacing: 1.12px;
    text-transform: uppercase;
}

.subscribe>input::-webkit-input-placeholder {
    /* WebKit browsers */
    color: #fff;
}

.subscribe>input:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: #fff;
}

.subscribe>input::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: #fff;
}

.subscribe>input:-ms-input-placeholder {
    /* Internet Explorer 10+ */
    color: #fff;
}

.subscribe textarea:focus, input:focus{
    outline: none;
}

.break {
    display: none;
}

@media screen and (min-width:768px) {
    .break {
        display: block;
    }
    .subscribe {
        max-width: 60%;
    }
}

@media screen and (min-width:900px) {
    .subscribe {
        max-width: 40%;
    }
}