.new_main {
    padding:15px;
    padding-top:45px;
    display: flex;
    flex-direction: column;
    gap: 13px;
}
.input_main {
    display: flex;
    flex-direction: column;
    gap: 18px;
    padding: 22px;
    background: rgba(212, 180, 184, 0.50);
}
.label_container {
    display: flex;
    flex-direction: column;
    /* gap: 5px; */
}
.label {
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0.28px;
}
.sub_label {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
}
.input_box {
    display: flex;
    padding-right: 0px;
    flex-shrink: 0;
    border-radius: 5px;
}
.input_box > p {
    display: flex;
    justify-content: center;
    margin: 0;
    padding-left: 11px;
    align-items: center;
}
.max_input {
    border: 0;
    background-color: transparent !important;
}
.max_input {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
}
.time_left {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    text-align: center;
}
.price_label{
    font-size: 17px;
}

.errorMsg {
    color: var(--red-color);
    font-size: 16px;
    padding-top: 4px;
    display: block;
}

@media screen and (min-width:768px) {
    .new_main {
        padding-inline:0;
    }
    .time_left {
        text-align: left;
    }
    .max_input{
        font-size: 20px;
    }
    .label{
        font-size: 18px;
        margin-bottom: 10px;
    }
    .sub_label{
        font-size: 16px;
    }
    .price_label{
        font-size: 19px;
    }
    .time_left{
        font-size: 16px;
    }
}

@media screen and (min-width:900px) {
    .new_main {
        max-width: 50%;
    }
}