:root {
    --white: #ffffff;
    --black: #000000;
    --transparent: transparent;
    --color-lffu6uvb: #603F8B;
    --color-lffudicf: #F43AB0;
    --color-lffy4ue2: #9C9C9C;
    --primary-btn-clr: #1a385c;
}

.section5{
    margin-top: 100px;
    height: 900px;
}


.section5>img {
    width: 100%;
    height: 900px;
    background-position: 50% !important;
    background-repeat: repeat !important;
    object-fit: cover !important;
    filter: brightness(50%);
    position: absolute;
}

.card_container {
    display: flex;
    padding: 0 10px;
    padding-top: 195px;
}

.card {
    position: relative;
    padding: 35px 40px 40px;
    background-color: var(--white);
    border-radius: 10px;
    width: 440px;
    box-shadow: 0 1px 5px #0003;
}
.card>div{
    margin-top: 20px;
}

.card>h2{
    color: var(--color-lffu6uvb);
    /* font-size: 42px; */
    font-size: 36px;
    text-align: center;
    margin-bottom: 10px;
    max-width: 100%;
    
}
.card>h2>strong{
    max-width: 100%;
}

.card>div>div{
    display: flex;
}
.card>div>div>p{
    font-size: 20px;
    padding-left: 5px;
}

.card>div>p{
    color: var(--color-lffy4ue2);
    font-size: 14px;
    padding-left: 30px;
}

.button_container{
    display: flex;
    justify-content: center;

}

.button_container>a>button{
    background-color: var(--primary-btn-clr);
    color: var(--white);
    padding: 15px 40px;
    font-size: 16px;
    border-radius: 5px;
    margin-top: 10px;
    font-weight: 700;
    width: 240px;
}



@media only screen and (max-width: 768px) {

    .section5{
        margin-top: 70px;
        width: 100%;
        height: 800px;
    }

    .section5>img {
        width: 100%;
        height: 800px;
    }
    .card_container {
        height: 100%;
        display: flex;
        padding-top: 0;
        align-items: center;
        justify-content: center;
    }
    .card {
        width: 95%;
        padding: 30px;
    }
    .card>div>p{
        margin-bottom: 0;
    }
    .card>div{
        margin-top: 10px;
    }
    .card>div>div>p{
       margin-bottom: 0;
    }
    .button_container>a>button{
        margin-top: 10px;
        font-size: 14px !important;
    }
    .card>h2{
        font-size: 30px;
    }    
}
@media only screen and (max-width: 400px) {
    .card {
        margin-left: 0;
    }

}