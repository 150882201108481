.aboutUsContainer {
    display: flex;
    gap: 70px;
}

.aboutUsLeft {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 80px;
}

.aboutUsTitle {
    width: min-content;
    color: var(--black-color) !important;
    margin: 0 0 20px;
}

.leftBottom {
    display: flex;
    gap: 4px;
    flex-direction: column;
}

.leftBottomHead {
    font-size: 18px !important;
    line-height: 1.4 !important;
    color: var(--black-color);
    margin: 0 0 30px !important;
    font-style: italic;
    color: #666;
}

.leftBottomPara {
    font-weight: 400;
    font-size: 18px !important;
    line-height: 1.4 !important;
    color: var(--black-color);
    margin: 0;
    color: #666;
}

.leftAction {
    display: flex;
    gap: 16px;
    align-items: center;
    margin-top: 20px;
}

.nonProfitsLinks {
    color: var(--red-color);
    font-size: 12px;
    font-weight: 600;
    line-height: 17.08px;
    text-align: center;
}

.aboutUsRight {
    width: 100%;
    height: 628px;
}

.mainImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

@media screen and (max-width:1100px) {
    .aboutUsContainer {
        flex-direction: column;
        gap: 32px;
    }

    .aboutUsLeft {
        width: 100%;
        gap: 32px;
    }

    .aboutUsRight {
        height: auto;
    }
}

@media only screen and (max-width: 768px) {
    .aboutUsLeft {
        padding: 40px 15px;
    }
}