.auctionGrid {
    display: grid;
    grid-template-columns: repeat(3, 1fr); 
    gap: 20px; 
    margin-top: 20px;
}
@media (max-width: 1200px) {
    .auctionGrid {
        grid-template-columns: repeat(2, 1fr); 
    }
}
@media (max-width: 768px) {
    .auctionGrid {
        grid-template-columns: 1fr;
    }
}
.card {
    background-color: white;
    border-radius: 8px;
    overflow: hidden;
    text-align: center;
    padding: 0;
    /* transition: transform 0.2s ease-in-out; */
    cursor: pointer;
    margin: 30px 0 0;
}
/* .card:hover {
    transform: scale(1.05); 
} */
.imageContainer {
    width: 100%;
    /* padding-top: 100%; */
    position: relative;
    /* background-color: #f5f5f5; */
}
.imageContainer img {
    /* position: absolute;
    top: 0;
    left: 0; */
    width: 100%;
    height: 100%;
    object-fit: cover;
    padding: 0;
    border-radius: 50px;
}
.text_field{
    padding: 15px;
    color: black;
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.title {
    font-size: 14px;
    font-weight: 600;
    color: var(--red-color);
}
.daysLeft {
    font-size: 32px;
    font-weight: 700;
    font-family: Test Founders Grotesk X-Condensed;
    line-height: 24px;
}

.desc {
    font-size: 20px;
    text-align: center;
}

.newTitleClass {
    text-align: center;
}