.banner_contianer {
    background-repeat: no-repeat;
    background-size: cover;
    color: #30313A;
    background-position: center;
    position: relative;
}

.cause_legal_name {
    font-size: 30px;
    font-weight: 400
}

.mobile_info_container {}

.completed_alert {
    margin-top: 10px;
    text-align: center;
    font-size: 14px;
    font-weight: 600
}

.desktop_store_heading {
    font-size: 65px;
    padding-bottom: 15px;
}

.desktop_event_complete_alert {
    margin-top: 10px;
    margin-left: 10px;
    font-size: 14px;
    font-weight: 400;
    position: absolute;
    width: 100%;
    line-height: 16px;
    bottom: 10px
}

.info_btn_class {
    text-transform: capitalize;
}