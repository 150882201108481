/* styles for header in mobile */

.container {
    /* background-color: #FC3300; */
    position: sticky;
    top: 0;
    z-index: 999;
    width: 100%;
    min-height: 90px;
}

.section {
    padding: 17px 25px;
    display: flex;
    justify-content: space-between;
}

.logo_wrapper {
    width: 200px;
}

.logo_wrapper > div {
    width: 100%;
    max-width: 100%;
}

.icon_wrapper {
    display: flex;
    gap: 3px;
    align-items: center;
    margin-left: 7px;
}

.mobile_nav_links_animater {
    margin-top: 5%;
}

.navbar_dropdown {
    display: flex;
    flex-direction: column;
    padding: 16px 25px;
    /* margin-top: 5%; */
}

.navbar_dropdown > h4 {
    padding: 2px 2px;
    font-size: 42px;
}

@media screen and (max-width:420px) {
}

@media screen and (max-width:360px) {
    .navbar_dropdown {
        padding: 10px 25px;
    }

    .navbar_dropdown > h4 {
        font-size: 22px;
    }
}

@media screen and (min-width:768px) {
    .mobile_nav_links_animater {
        margin-top: 3%;
    }
}


 /* styles for header in desktop */

 .desktop_section {
    position: sticky;
    top: 0;
    z-index: 999;
    width: 100%;
    /* padding: 3%; */
 }

.desktop_container {
    margin-block: 34px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.desktop_logo_container {
    cursor: pointer;
}

.desktop_links_section {
    display: flex;
    gap: 20px;
}

.desktop_links_section > h4 {
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 133.333% */
    letter-spacing: 0.36px;
    cursor: pointer;
}

.desktop_icons_container {
    display: flex;
    gap: 15px;
}

@media screen and (max-width:1156px) {
    .desktop_links_section {
        gap: 12px;
    }
    .desktop_links_section > h4 {
        font-size: 12px;
    }
}

@media screen and (max-width:860px) {
    .desktop_logo_container {
        max-width: 170px;
    }
}

@media screen and (max-width:800px) {
    .desktop_links_section {
        gap: 7px;
    }
}