.partner-container {
  text-align: center;
  background-color: #000;
  color: #fff;
  padding: 80px;

  .partner-heading {
    font-size: 96px;
    line-height: 1.4;
    font-weight: bold;
    margin-bottom: 0;
    font-family: "alternategotno1dregular";
  }

  .partner-subheading {
    font-size: 24px;
    margin-bottom: 40px !important;
    line-height: 1.6;
    max-width: 830px;
    text-align: center;
    margin: 0 auto;
  }

  .partner-buttons {
    display: flex;
    justify-content: center;
    gap: 60px;
  }

  .partner-button {
    background-color: #ff0000;
    color: #fff;
    border: none;
    padding: 8px 30px;
    font-size: 36px;
    font-weight: bold;
    cursor: pointer;
    border-radius: 30px;
    min-width: 164px;
    transition: background-color 0.3s ease;
    font-family: "alternategotno1dregular";
    text-transform: uppercase;
  }

  .partner-button:hover {
    background-color: #d40000;
  }

  @media (max-width: 768px) {
    .partner-heading {
      font-size: 40px;
    }

    .partner-subheading {
      font-size: 14px;
    }

    .partner-buttons {
      gap: 30px;
      flex-wrap: wrap;
    }
  }
}

@media only screen and (max-width: 768px) {
  .partner-container {
    padding: 40px 15px;
  }
}