.save-main h1 {
  font-weight: bold;
  font-family: "alternategotno1dregular";
  color: #000;
  margin-bottom: 10px;
  font-size: 2.5rem;
  letter-spacing: 0.5px;
}

.cart-card-contents {
  width: 100%;
  padding: 10px 0;
}

.cart-card-contents h1 {
  color: black;
  font-weight: bold;
  font-family: "alternategotno1dregular";
  font-size: 1rem;

}

.cart-card-contents h2 {
  color: #8F8F8F;
  font-family: "alternategotno1dregular";
  font-size: 1rem;

}

.cart-card-contents b {
  color: black;
  font-weight: bold;
  font-family: "alternategotno1dregular";
  font-size: 1.25rem;
  letter-spacing: 0.5px;
}

.cart-card-contents .itemDetails {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.saved-offers {
  padding: 15px;
  border: 1px solid #e5e1e1;
  border-radius: 15px;
  background-color: #ffffff;
}

.saved-offers h1,
.saved-offers h2 {
  margin-bottom: 4px;
  letter-spacing: 0.5px;
  font-size: 22px;
}

.view-product-btn {
  color: #FF0000;
  background: 'none';
  border: 'none';
  cursor: 'pointer';
  font-weight: bold;
  font-family: "alternategotno1dregular";
  font-size: 27px;
}