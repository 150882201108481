.dheader {
  background-color: #000;
  font-weight: bold;
  text-align: center;
  padding: 20px 80px;
  font-family: "alternategotno1dregular";
  font-size: 60px;
  color: #FF0000;
  line-height: 1.3;
}

@media (max-width: 768px) {
  .dheader {
    padding: 25px;
    font-size: 37px;
  }
}