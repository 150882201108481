.bag-container {
    padding: 30px 10px;
}

.bag-order-summary {
    margin-bottom: 20px;
}

.bag-order-summary>h1 {
    font-size: 24px;
    font-weight: 600;
    /* font-family: "AvenirNext"; */
}

.bag-summary-grid>div>div {
    display: grid;
    grid-template-columns: 4fr 1fr;
    margin: 5px 0;
}

.bag-summary-grid-total {
    display: grid;
    grid-template-columns: 4fr 1fr;
    font-size: 19px;
}

.bag-card-price {
    font-weight: 600;
}

.bag_shipping_sec {
    display: flex;
    min-height: 159px;
    justify-content: space-between;
    align-items: center;
    padding: 20px 14px;
    border-radius: 12px;
    margin-top: 10px;
    background-color: #FCFCFC;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border: none;
    /* width: 55%; */
    width: 100%;
}

.bag_shipping_content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    font-size: 0.9rem;
    font-weight: 600;
    width: 80%;
}

.bag_shipping_edit>img {
    width: 19px;
    height: 19px;
    margin-right: 12px;
}

@media screen and (max-width:900px) {
    .bag_shipping_sec {
        width: 100%;
    }
}

@media screen and (min-width: 768px) {
    .bag-container {
        padding: 0;
        width: 85vw;
        margin: 20px auto;
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 30px;
        position: relative;
    }

    /* .bag-order-summary {
        background-color: #EFEFEF;
    } */

    .bag-order-summary>h1 {
        /* padding: 20px 0 0 20px; */
        padding-top: 20px;
    }

    .bag-order-summary>div>div {
        padding: 0;
        width: 100%;
    }

    .bag-summary-grid-total {
        /* background-color: #D5D5D5; */
        padding: 20px 0 !important;
        font-size: 1.2rem !important;
    }

    .remove-padding-class {
        padding-top: 0 !important;
    }

    .buy-checkout-btn,
    .buy-checkout-btn-outline {
        width: 100% !important;
    }

    .bag-container-empty {
        grid-column-start: 1 !important;
        grid-column-end: 3 !important;
    }
}

@media screen and (max-width:768px) {
    .bag_shipping_content {
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: -0.36px;
        gap: 10px;
    }
}