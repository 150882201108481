/* FeaturedSection.css */
.featured-section {
  text-align: center;
  padding: 30px 80px;
  /* background-color: #ffffff; */
}

.h1 {
  font-family: "alternategotno1dregular";
  font-size: 50px;
  font-weight: bold;
  margin-bottom: 10px;
  color: black;
}

.or-image {
  width: 60px;
  margin: 0 10px;
}

.section-title {
  font-size: 64px;
  font-weight: bold;
  margin-bottom: 20px;
  font-family: "alternategotno1dregular";
}

.section-title .highlight {
  color: #000;
  background-color: #ffffff;
}

.featured-section h2 {
  color: #FF0000;
}

.sectionSubtitle {
  color: #666;
  text-align: center;
  max-width: 800px;
  margin-right: auto;
  margin-left: auto;
}

.campaigns-container {
  display: flex;
  align-items: center;
  gap: 60px;
  /* flex-wrap: wrap; */
  justify-content: center;
  margin-top: 40px;
}

.campaign {
  /* background: #fff; */
  border-radius: 35px;
  text-align: center;
  flex: 1 1 0%;
  max-width: 400px;
}

.compaign-img {
  /* width: 300px; */
  border: 3px solid #000;
  border-radius: 60px;
  overflow: hidden;
  margin: 0 0 10px;
}

.campaign-image {
  width: 100%;
}

.campaign-title {
  font-size: 32px;
  font-weight: bold;
  color: #000;
  margin-bottom: 5px;
  text-align: left;
  font-family: "alternategotno1dregular";
}

.campaign-subtext {
  font-size: 16px;
  color: #888;
  margin-bottom: 5px;
  display: none;
}

.campaign-category {
  font-size: 14px;
  color: #ff0000;
  font-weight: bold;
}

@media (max-width: 768px) {
  .campaigns-container {
    flex-direction: column;
    align-items: start;
    justify-content: space-between;
  }

  .campaign {
    display: flex;
    justify-content: space-between;
    gap: 18px;
    align-items: center;
  }

  .d-mobile-none {
    display: none;
  }

  .section-title .highlight {
    font-size: 50px;
  }

  .compaign-img {
    max-width: 160px;
  }

  .campaign-category {
    margin-top: 6px;
  }

  .featured-section {
    padding: 20px 20px;
  }

  .featured-section h5 {
    font-size: 40px;
    line-height: 40px;
  }
}