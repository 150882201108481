.maindiv {
  padding: 80px;
}

.social_btn_main {
  /* max-width: 550px; */
}

.img-holder img {
  width: 100%;
}

.left_text {
  text-align: left !important;
}

.right_text {
  font-size: 64px;
  text-align: right;
  font-weight: bold;
  font-family: "alternategotno1dregular";
  line-height: 72px;
  letter-spacing: 0.5px;
}
.right_text_bottom {
  text-align: right;
  font-size: 26px;
  line-height: 1.2;
  margin-top: 22px;
}

.btn_div {
  display: flex;
  justify-content: left;
  margin-top: 40px;
}
.right_btn {
  background-color: var(--red-color);
  font-weight: bold;
  font-family: "alternategotno1dregular";
  font-size: 2rem;
  border-radius: 40px;
  min-width: 250px;
  padding: 7px 20px;
  color: #ffffff;
}

@media (max-width: 768px) {
  .right_text {
    text-align: right;
    font-size: 60px;
    line-height: 60px;
  }

  .right_text_bottom {
    font-size: 24px;
    text-align: left;
  }

  .btn_div {
    justify-content: left;
  }

  .maindiv {
    padding: 40px;
  }

   .right_btn {
    min-width: 200px;
    font-size: 1.5rem;
  }

  .cta-button {
    min-width: 200px;
  }

  .social_btn_main {
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: end;
  }

  .right_text_bottom {
    text-align: right;
  }
}

@media only screen and (max-width: 899px) {
  .social-image-wrapper .img-holder {
    margin: 0 auto;
  }

  .social_btn_main p {
    text-align: left !important;
  }
}