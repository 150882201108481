.rounded_container {
    /* background-color:  #F0E8E3; */
    padding: 40px 21px;
    display: flex;
    flex-direction: column;
    gap: 27px;
    border-radius: 15px;
    border-collapse: separate;
    width: 96%;
    margin: auto;
}

/* @media screen and (min-width:768px) {
    .rounded_container {
        width: 400px;
    }
} */

.custom_container {
    /* width: 350px; */
    width: 471px;
    /* height: 276px; */
    height: 100%;
    padding: 0;
}

.custom_blog_container {
    width: 100%;
    height: 100%;
    cursor: pointer;
    padding: 0;
    background-color: var(--shady-white);
}

.eventDetailsCardContainer {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    gap: 10px;
    background-color: var(--white-color);
    border: 2px solid var(--red-color);
}

@media screen and (max-width:768px) {
    .eventDetailsCardContainer {
        /* background-color: var; */
    }
}

@media screen  and (max-width:540px) {
    .custom_container {
        /* width: 83vw; */
        width: 71vw;
    }
}

@media screen  and (min-width: 291px) and (max-width:390px) {
    .custom_container {
        /* width: 80vw; */
        width: 67vw;
    }
}
@media screen and (max-width:290px) {
    .custom_container {
        /* width: 224px; */
        width: 188px;
    }
}