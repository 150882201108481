.container {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #30313A;
}

.main {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 200px;
}

.section_start{
    display: flex;
    gap: 20px;
    width: 75%;
    align-items: flex-start;
}

.preference_desc {
    font-size: 20px;
    font-weight: 500;
    color: #EFE7E2;
}

.preference_check {
    height: 17px;
    width: 17px;
    margin-block: auto
}

.button_container {
    display: flex;
    justify-content: flex-end;
    width: 80%;
    margin-top: 40px;
}

.submit_button {
    font-weight: 600;
    border: 1px solid #d3d3d3;
    padding: 10px 20px;
    border-radius: 6px;
    background-color: var(--primary-btn-clr);
    color: white;
}

.boundary {
    width: 90%;
    margin-top: 0;
    /* opacity: 1; */
}

.heading {
    display: flex;
    justify-content: flex-start;
    width: 92%;
    font-weight: 600;
}

@media screen and (max-width:570px) {
    .preference_desc {
        font-size: 14px;
    }
}

@media screen and (min-width:860px) {
    .main {
        padding-inline: 20px;
    }
}

@media screen and (max-width:800px) {
    .section_start {
        width:90%;
    }
}

@media screen and (max-width:768px){
   
    .preference_desc{
        font-size: 18px;
        line-height: 24px;
        font-weight: 400;
       
    }
    .preference_check{
        height: 25px;
        width: 25px;
    }
    .main {
      padding-top: 50px;
      padding-inline: 30px;
      /* min-height: 80vh; */
      margin-top: 0;
    }
    .section_start {
        width:100%;
    }
}

@media screen and (min-width:768px){
    .section_start{
        width: 100%;
    }
}