.ch-main-div {
  background-color: #FAFAFA;
  padding: 30px;
}

.ch-main-div h1 {
  font-weight: bold;
  font-family: "alternategotno1dregular";
  font-size: 50px;
}

.paynow-btns {
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 100vh;            */
}

.save-payment-details-btns {
  background-color: rgb(252, 51, 0);
  padding-block: 7px;
  margin: 25px 0 25px 0;
  font-weight: bold;
  padding: 5px 40px;
  border-radius: 25px;
  font-family: "alternategotno1dregular";
  color: var(--pure-white);
  letter-spacing: 1px;
  font-size: 25px;


}


/* item */
/* Background overlay */
.cart-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease-in-out;
  z-index: 999;
}

.cart-overlay.visible {
  opacity: 1;
  visibility: visible;
}

/* Right-side sliding cart modal */
.cart-modal {
  position: fixed;
  top: 0;
  right: -100%;
  width: 360px;
  height: 100vh;
  background: #fff;
  border-left: 1px solid #ccc;
  box-shadow: -4px 0 10px rgba(0, 0, 0, 0.2);
  transition: right 0.3s ease-in-out;
  z-index: 1000;
  display: flex;
  flex-direction: column;
}

/* Show cart modal */
.cart-modal.show {
  right: 0;
}

/* Cart header */
.cart-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  border-bottom: 1px solid #ddd;
}

.right-side {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: end;
}

/* .right-side img {
  max-width: 30px;
} */

.modal-title {
  font-size: 24px;
  font-weight: bold;
  font-family: "alternategotno1dregular";
  letter-spacing: 0.5px;
}

/* Close button */
.close-button {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

/* Scrollable cart items */
.cart-items-container {
  flex-grow: 1;
  overflow-y: auto;
  padding: 10px;
}

/* Individual cart item */
.cart-item {
  padding-bottom: 10px;
  margin-bottom: 10px;
  padding: 15px 30px;
  display: flex;
  justify-content: end;
  align-items: start;
}

.item-image {
  width: 60px;
  height: 60px;
  border-radius: 8px;
  margin-right: 10px;
}

.item-details {
  flex: 1;
}

.item-details .item-variant {
  font-size: 12px;
  display: flex;
  gap: 10px;
}

.item-details .item-variant span {
  color: rgb(48, 49, 58);
}

.item-details p {
  font-size: 16px;
  margin: 5px 0;
  font-weight: bold;
  padding: 0 5px;
}

.quantity-control {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 16px 0;
}

.quantity-control button {
  background: none;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  font-size: 1rem;
  cursor: pointer;
}

.quantity-control span {
  /* margin: 0 10px; */
}

.padding {
  padding: 0 40px;
}

.item-price {
  font-weight: bold;
  font-family: "alternategotno1dregular";
  font-size: 22px;
  margin: 10px;
}

/* Cart footer */
.custom-border {
  padding: 15px;
  border-top: 1px solid #ddd;
  text-align: center;
}

.cart-total {
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  font-weight: bold;
  font-family: "alternategotno1dregular";
}

.checkout-button {
  width: 100%;
  background: #ff0000;
  border: none;
  border-radius: 25px;
  padding: 10px;
  font-size: 1rem;
  cursor: pointer;
  margin-top: 10px;
}

.checkout-button button {
  color: var(--pure-white) !important;
}

.checkout-button:hover {
  background: darkred;
}

.empty_cart {
  margin-left: 40px;
}

@media (max-width: 768px) {
  .cart-modal {
    width: 310px;
    /* right: -114%; */
  }

  .subtotal {
    gap: 20px;
  }
}

.item-cart {
  width: 100%;
  /* flex-direction: column; */
  display: flex;
  padding: 10px 20px;
  border-bottom: 1px solid #DEDEDE;
  margin-bottom: 20px;
  align-items: center;
}

.cart-expenditure {
  padding: 20px 20px;
}

.subtotal {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.subtotal h2 {
  font-weight: bold;
  font-family: "alternategotno1dregular";
  font-size: 20px;
}

.pay-now-btn {
  margin-top: 20px;
  background-Color: red;
  color: white;
  font-Size: 20px;
  font-Weight: bold;
  padding: 12px 40px;
  border: none;
  border-Radius: 50px;
  cursor: pointer;
  display: flex;
  align-Items: center;
  justify-Content: center;
  text-Transform: uppercase;
  width: 100%;
  font-weight: bold;
}

@media (max-width: 768px) {
  .padding {
    padding: 20px;
  }

  .ch-main-div {
    padding: 20px 0 20px 20px;
  }

  .item-cart {
    padding: 10px;
  }

  .item-price {
    font-size: 16px;
  }
}