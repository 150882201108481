.eventCardContainer {
    display: flex;
    gap: 48px;
    flex-direction: column;
    width: 380px;
    cursor: pointer;
}

.imageContainer {
    width: 380px;
    /* height: 380px; */
    max-height: 113px;
    position: relative;
}

.bannerImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.eventLogo {
    height: 43px;
    width: 43px;
    position: absolute;
    border-radius: 50%;
    background-color: var(--pure-white);
    top: 24px;
    left: 24px;
}

.cardContent {
    width: 100%;
    display: flex;
    gap: 16px;
    flex-direction: column;
}

.itemName {
    color: var(--black-color) !important;
}

.eventDate {
    font-weight: 400;
    font-size: 18px;
    line-height: 28.8px;
    margin-bottom: 0;
}

.linkBox {
    padding-bottom: 10px;
    border-bottom: 1px solid var(--red-color);
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.link {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.linkText {
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    color: var(--red-color);
}

.arrow {
    color: var(--red-color) !important;
}

@media screen and (min-width: 1500px) {
    .eventCardContainer {
        width: 480px;
    }
    .imageContainer {
        width: 480px;
        height: 480px;
    }
}

@media screen and (min-width: 1750px) {
    .eventCardContainer {
        width: 580px;
    }
    .imageContainer {
        width: 580px;
        height: 580px;
    }
}

@media screen and (max-width:1189px) {
    .eventCardContainer {
        width: 100%;
    }
    
    .imageContainer {
        width: 100%;
    }
    .eventLogo {
        height: 64px;
        width: 64px;
    }
}
@media screen and (max-width:960px) {
    .imageContainer {
        max-height: 87px;
    }
    .eventLogo {
        height: 43px;
        width: 43px;
    }
}

@media screen and (max-width: 768px) {
    .eventCardContainer {
        gap: 38px;
    }
     .imageContainer {
        max-height: 325px;
    }
    .eventLogo {
        height: 64px;
        width: 64px;
    }
    
}

@media screen and (max-width: 450px) {
    .eventCardContainer {
        gap: 20px;
    }
    /* .imageContainer {
        height: 350px;
    } */
    .cardContent {
        gap: 13px;
    }
    .eventLogo {
        height: 43px;
        width: 43px;
    }
}
