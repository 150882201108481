.bid-history-bid_cards {
    display: grid;
    grid-template-columns: 1fr 1fr;
    /* column-gap: 14px; */
    row-gap: 10px;
}

.bid-history-bid_card {
    border-radius: 9px;
    box-shadow: 1px 1px 4px 2px rgba(123, 116, 116, 0.2);
    height: 230px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    padding: 10px 0;
}

.bid-history-bid_card_img {
    height: 120px;
}

.bid-history-bid_card_img>img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.bid-history-bid_card_info {
    flex: 1;
    font-size: 0.8rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
}

.bid-history-bid_card_info>h3 {
    width: 100%;
    font-size: 0.9rem;
    font-weight: 600;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.bid-history-bid_card_grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 2px 0;
    column-gap: 5px;
}

.bid_nav {
    margin-top: 3px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-bottom: 1px solid #CDCDCD;
    background-color: #fff;
}



.bid_nav>p {
    margin: 0;
    padding: 16px 0;
    /* font-family: 'AvenirNext'; */
    font-style: normal;
    /* font-weight: 600; */
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: -0.24px;
    cursor: pointer;
    /* color: #CDCDCD; */
}

.bid_nav>.selected {
    /* color: #173B83 !important; */
    color: #212529 !important;
    border-bottom: 1px solid #173B83;
}

.mobile_main_heading {
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 25px;
    letter-spacing: 0.4px;
}

.bid_card_amount {
    display: flex;
    justify-content: space-between;
}


@media screen and (min-width: 768px) {
    .my_bid_page .container {
        padding-top: 0px;
        width: 75vw;
        margin: 0 auto;
    }

    /* .my_bids_toggle_bar_container {
        position: static !important;
        width: 100%;
    } */

    .bid-history-bid_card_img {
        height: 160px;
    }

    .bid-history-bid_cards {
        display: grid;
        grid-template-columns: 1fr 1fr;
        /* column-gap: 100px;
        row-gap: 30px; */
    }

    .bid-history-bid_card {
        height: 320px;
        width: 80%;
    }

    .bid-history-bid_card_info {
        align-items: start;
        padding-left: 30px;
        padding-right: 30px;
        font-size: 1rem;
    }

    .toggle_bar>span {
        cursor: pointer;
    }

    .bid-history-bid_card_info>h3 {
        font-size: 1.3rem;
        margin-bottom: 20px;
    }

    .bid-history-bid_card_grid {
        width: 80%;
        column-gap: 5px;
    }

    .bid_nav {
        border-bottom: none;
        justify-content: flex-start;
        /* column-gap: 35px; */
        /* margin:0 10%; */
        border-bottom: 1px solid #D3D3D3;
        background-color: #fff;
        margin-top: 22px;
    }

    .bid_nav p {
        width: 200px;
    }

    .bid_nav .selected {
        /* background-color: #efefef; */
        font-weight: 600;
        border-bottom: none;
        width: 200px;
        height: 100%;
        column-gap: 0;
    }
}

@media screen and (max-width: 1250px) {
    .bid-history-bid_card {
        width: 100%;
        column-gap: 5px;
    }
}

@media screen and (max-width: 1210px) {
    .my_bid_page .container {
        width: 85vw;
    }
}

@media screen and (max-width: 1090px) {
    .bid-history-bid_cards {
        /* column-gap: 40px;
        row-gap: 20px; */
    }
}

@media screen and (max-width: 1060px) {
    .my_bid_page .container {
        width: 95vw;
    }
}

@media screen and (max-width:768px) {


    .bid_nav>p {
        /* font-style: normal;
        font-weight: 600;
        color: #CDCDCD; */
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.28px;
        padding-inline: 21px;
        font-family: DM Mono;
    }

    .my_bid_page .container {
        width: 100vw;
    }

    .mobileSelected {
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.28px;
    }
    .bid_card_data {
        display: flex;
        flex-direction: column;
        gap: 3px;
    }
    .bid_card_amount > span {
        font-size: 12px;
    }
    .bid-history-bid_cards {
        row-gap: 10px;
    }
}

@media screen and (max-width:330px) {
    .bid_card_amount {
        flex-direction: column;
    }
}

@media screen and (min-width:768px) {
    .des_bid_card{
        width: 80%;
        /* height: 200px; */
        /* margin: 0 auto; */
        margin-bottom: 20px;
        display: flex;
        overflow: hidden;
        column-gap: 25px;
        cursor: pointer;
    }
    .des_bid_card_banner{
          min-width: 130px;
          min-height: 130px;
    }
    .des_bid_card_banner > img{
        max-width: 90%;
        max-height: 100%;
        object-fit: cover;
        border-radius: 10px;
    }
    .des_bid_card_details{
        padding-top: 3%;
        display: flex;
        flex-direction: column;
        row-gap: 15px;
    }
    .des_bid_card_details  p{
        font-size: 20px;
        font-weight: 600;
    }
}