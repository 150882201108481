.bannerMain {
    position: relative;
    margin: 0 !important;
    height: 956px;
}

.bannerMain img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.banner_image {
    /* width: 100vw;
    max-height: 900px; */
    height: 500px;
    width: 100%;
    object-fit: cover;
    object-position: center;
}

.prime_button {
    background-color: red;
}

.banner_container {
    /* position: absolute;
    top: 33%; */
    position: absolute;
    inset: 0;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 150px;
}

.main_heading {
    /* font-size: 50px;
    font-style: normal;
    font-weight: 400;
    line-height: 55px;
    letter-spacing: -1px; */
    font-size: 56px;
    font-weight: 400;
    line-height: 61.6px;
    color: var(--pure-white);
}

.sectionPara {
    color: var(--pure-white);
}

.banner_content {
    display: flex;
    flex-direction: column;
    gap: 100px;
    flex: 1 1 auto;
    max-width: 694px;
    align-items: center;
}

.shade {
    width: 100%;
    height: 500px;
    /* height: 100%; */
    /* background: rgba(0,0,0,0.3); */
    /* background: linear-gradient(300deg, rgba(239, 231, 226, 0.65) 0%, rgba(217, 217, 217, 0.00) 100%); */
    background: linear-gradient(90deg, rgba(32, 96, 128, 0.8) 0%, rgba(57, 62, 64, 0) 100%);
    /* mix-blend-mode: hard-light; */
    position: absolute;
    top: 0;
    left: 0;
}

.bannerPagination {
    display: flex;
    gap: 20px;
    position: absolute;
    bottom: 84.5px;
    right: 80px;
    z-index: 3;
}

.bannerNavigationButton {
    width: 50px;
    height: 50px;
    background-color: transparent;
    border: 1px solid var(--pure-white);
}

.navigateArrow {
    color: var(--pure-white);
}

.bulletsContainer {
    width: auto !important;
    display: flex;
    gap: 8px;
    justify-content: center;
    align-items: center;
    width: 100% !important;
    position: absolute;
    bottom: 50px !important;
    z-index: 3;
}

.bulletsContainer > span {
    border-radius: 0;
    border: 1px solid var(--pure-white);
    height: 8px;
    width: 8px;
}

@media screen and (min-width:768px) {
    .sectionPara {
        font-size: 25px;
    }
    .subHeadingSeperator {
        display: none;
    }
}

@media screen and (max-width:768px) {
    .banner_image {
        height: 617px;
    }

    .shade {
        height: 617px;
    }

    .main_heading {
        font-size: 36px;
        line-height: 39.6px;
    }
}