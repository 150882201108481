.auction-card {
    box-sizing: border-box;
    background: #fffcfb;
    box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
    border-radius: 10px;
    padding-left: 10px;
    padding-right: 10px;
  
    flex: 1;
    display: flex;
    align-items: center;
  
    /* font-family: "AvenirNext"; */
    font-style: normal;
    font-weight: 600;
    min-height: 150px;
    margin-bottom: 10px;
    margin-top: 10px;
}

.auction-card .time_left {
    display: block;
    position: unset;
    height: 28px;
    background: none;
    padding: 0px 0px;
}

.auction-card .banner{
    background: #f2f2f2;
    width: 120px;
    height: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    border-radius: 5px;
    margin-right: 10px;
    object-fit: cover;
}
.auction-card .img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.auction-card p {
    margin: 0;
}

.auction-card-body {
    width: 70%;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.auction-card-body header {
    /* font-family: "AvenirNext"; */
    font-weight: 600;
    font-size: 18px;
    text-align: left;
}

.auction-card-details {
    display: flex;
    position: relative;
    align-items: center;
}

.auction-card-details-col {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.auction-card-details-col p {
    font-size: 13px;
}