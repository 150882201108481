:root {
    --white: #ffffff;
    --black: #000000;
    --transparent: transparent;
    --color-lffu6uvb: #603F8B;
    --color-lffudicf: #F43AB0;
    --color-lffy4ue2: #9C9C9C;
}

.section7 .headline{
    /* font-size: 42px; */
    font-size: 36px;
    font-weight: 700;
    color: var(--color-lffu6uvb);
    /* margin-left: 20px; */
    margin-left: 17px;
    margin-top: 40px;
}
.subheadline{
    font-size: 20px;
    margin-left: 20px;
}
.section7_clickhere{
    color: var(--color-lffudicf);
    cursor: pointer;
}
.section7_clickhere:hover{
    text-decoration: underline;
}
.gray_line{
    color: var(--color-lffy4ue2);
    font-size: 16px;
    /* margin-bottom: 40px; */
    /* padding: 40px 22px; */
    /* padding: 20px 22px 10px 22px; */
    padding: 20px 22px 6px 22px;
}
.img_container{
    display: flex;
    justify-content: center;
    gap: 25px;
    align-content: center;
    margin-bottom: 60px;
    width: 100%;
    flex-wrap: wrap;
}

.img_container > div {
    width: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    /* height: 80px; */
}

.img_container > div > img {
    max-width: 100%;
}

.image1{
    height: 100px;
}

@media screen and (max-width:767px) {
    .section7 .headline{
        font-size: 30px;
    }
}

@media screen and (max-width:456px) {
    .subheadline{
    font-size: 16px;
    /* margin-left: 0px;
    text-align: center; */
}
.section7 .headline{
    font-size: 25.5px;
    margin-bottom: 17px;
}
}


@media screen and (max-width:700px) {
    .gray_line{
        padding: 3px 22px 1px 22px;
    }    
}

@media screen and (min-width:700px)  {
    .image1 {
        height: 150px;
    }
}