.auction-society__heading {
  display: flex;
  justify-content: space-between;
}

.ReactModal__Overlay {
  z-index: 10;
}

.auction_banner.auction-page-banner {
  margin-top: 0;
}
.modal-body {
  height: 100%;
}

.modal-body .modal-close {
  color: white;
  background-color: #1a385c;
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 16px;
  font-size: 12px;
}
