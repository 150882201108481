.np-container {
  padding: 0;
}

.bannerContainer {
  max-width: 800px;
  margin: 0 auto;
  padding: 70px 0 70px;
}

.headingH2 {
  color: black;
  text-align: center;
}

.banner_dsc {
  color: black;
  font-size: 20px;
  line-height: 1.2;
  text-align: center;
  padding: 5px;
  margin-top: 20px;
}

.banner_btn_div {
  display: flex;
  justify-content: center;
  margin-top: 90px;
}

.demo-btn {
  display: flex;
  justify-content: center;
  margin-top: 40px;
}

.banner_button {
  border-radius: 40px;
  min-width: 250px;
  padding: 5px 20px;
  background-color: #FF0000;
  font-weight: bold;
  font-family: "alternategotno1dregular";
  font-size: 2rem;
  cursor: pointer;
  color: #ffffff;
}

@media (max-width: 768px) {

  .banner_dsc {
    font-size: 24px;
  }

  .banner_title {
    font-size: 40px;
    line-height: 40px;
  }

  .right_text {
    font-size: 60px;
    line-height: 60px;
    text-align: center;
  }

  .bannerContainer {
    padding: 20px;
  }
}