.headerSection {
  padding: 20px 80px !important;
  background-color: var(--pure-white);
  position: relative;
  width: 100%;
  z-index: 10;
}

.headerContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.headerlogo {
  display: flex;
  gap: 50px;
  flex: 1 1 auto;
}

/* .logoLink {
  width: 182px;
  height: 50px;
} */

.logoLink img {
  width: 77px;
}

.linksContainer,
.iconLinks {
  display: flex;
  /* justify-content: center; */
  align-items: center;
}

.linksContainer {
  gap: 24px;
  position: relative;
  flex: 1 1 auto;
}

.iconLinks {
  gap: 20px;
}

.textLinks {
  font-weight: 600;
  font-size: 16px;
  line-height: 17.08px;
  /* text-transform: uppercase; */
}

.textLinks:hover {
  color: #ff0000;
  border-bottom: 2px solid #ff0000;
}

.icon,
.textLinks {
  color: var(--black-color);
  cursor: pointer;
}

.search_icon {
  width: 25px;
  margin-top: 5px;
}

.account_icon {
  width: 20px;
}

.bag_icon {
  width: 22px;
  height: 25px;
}

.cart_icon {
  width: 24px;
  /* height: 22px; */
  margin-top: 5px;
}

.menuIcon {
  cursor: pointer;
}

.mobileNavLinksAnimater {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: var(--pure-white);
  z-index: 10;
  /* min-height: 100vh; */
  padding: 20px 24px !important;
  overflow: hidden;
}


.openHeaderTop {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.mobileLinksContainer {
  display: flex;
  flex-direction: column;
  gap: 2px;
  width: 100%;
  margin-top: 15%;
}

.mobileLinks {
  border-bottom: 2px solid var(--black-color);
  padding-bottom: 1px;
  font-size: 42px;
  text-transform: capitalize;
}

.subHeaderSection {
  position: relative;
  z-index: 2;
}

.sectionContainer {
  width: 100%;
  top: 0;
}

@media screen and (max-width:1200px) {
  .linksContainer {
    gap: 14px;
  }
}

@media screen and (max-width:1080px) {
  .textLinks {
    font-size: 10px;
  }
}

@media screen and (max-width:980px) {
  .iconLinks {
    gap: 10px;
  }
}


@media screen and (max-width:940px) {
  .linksContainer {
    gap: 10px;
  }
}

@media screen and (max-width:865px) {
  .headerSection {
    padding: 20px 60px !important;
  }
}

@media screen and (max-width:768px) {
  .headerSection {
    padding: 20px 24px !important;
  }

  .iconLinks {
    gap: 7px;
  }
}