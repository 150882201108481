.cardsAction {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 60px;
}

.swipperPagination {
    display: flex;
    gap: 20px;
}

.swiperNavigationButton {
    width: 50px;
    height: 50px;
    background-color: transparent;
    border: 1px solid var(--red-color);
}

.navigateArrow {
    color: var(--red-color);
}

.bulletsContainer {
    width: auto !important;
    display: flex;
    gap: 8px;
}

.bulletsContainer > span {
    border-radius: 0;
    border: 1px solid var(--red-color);
    height: 8px;
    width: 8px;
}


@media screen and (max-width:767px) {
    .cardsAction {
        flex-direction: column;
        gap: 50px;
        margin-top: 30px;
    }
    .displayMobileClass {
        display: none;
    }
}

@media screen and (max-width:540px) {
    .link {
        width: 100%;
    }
}