.favouriteCardActions {
    display: flex;
    justify-content: flex-start;
    gap: 10px;
    align-items: center;
    flex-wrap: wrap;
}

.priceContainer {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 10px;
}

.priceText {
    font-size: 16px;
    margin-bottom: 0;
    font-weight: 500;
}

@media screen and (max-width: 600px) {
    .favouriteCardActions {
        /* flex-direction: column; */
        align-items: flex-start;
        gap: 5px;
    }
    .priceText {
        font-size: 12px;
    }
}

@media screen and (max-width: 450px) {
    .priceContainer {
        gap:0px;
    }
}