:root {
    --order-dot-color: #FC3300;
}

.track-order-container {
    padding: 5px 20px;
}

.track-order-info div:nth-child(1) {
    /* font-family: "AvenirNext"; */
    font-size: 29px;
    font-weight: 600;
}

.track-order-image {
    width: 172px;
    height: 172px;
    border-radius: 5px;
    overflow: hidden;
    margin: 20px 0;
}

.track-order-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.order-item-contianer{
   
    display: flex;
    flex-direction: column;
    /* gap: 10px; */
}

.track-order-progess {
    display: flex;
    margin: 50px 0 30px 0;
}

.track-order-line {
    margin-top: 13px;
    width: 3px;
    height: 250px;
    background-color: #cecdcd;
    margin-right: 40px;
    margin-left: 10px;
    position: relative;
}

.track-order-line::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    background-color: #173B83;
    z-index: 2;
}

.track-order-1.track-order-line::before {
    bottom: 200px;
}

.track-order-2.track-order-line::before {
    bottom: 150px;
}

.track-order-3.track-order-line::before {
    bottom: 100px;
}

.track-order-4.track-order-line::before {
    bottom: 50px;
}

.track-order-5.track-order-line::before {
    bottom: 0;
}

.track-order-status {
    flex: 1;
}

.track-order-status div {
    height: 50px;
}

.track-order-dot {
    width: 20px;
    height: 20px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    background-color: #cecdcd;
    border-radius: 50%;
    z-index: 4;
}

.track-order-dot:nth-of-type(1) {
    top: -10px;
    /* background-color: #173B83; */
    background-color: var(--order-dot-color);
}

.track-order-dot:nth-of-type(2) {
    top: 40px;
}

.track-order-dot:nth-of-type(3) {
    top: 90px;
}

.track-order-dot:nth-of-type(4) {
    top: 140px;
}

.track-order-dot:nth-of-type(5) {
    top: 190px;
}

.track-order-dot:nth-of-type(6) {
    top: 240px;
}

.track-order-1 > .track-order-dot:nth-of-type(2) {
    /* background-color: #173B83; */
    background-color: var(--order-dot-color);
}

.track-order-2 > .track-order-dot:nth-of-type(2),
.track-order-2 > .track-order-dot:nth-of-type(3) {
    /* background-color: #173B83; */
    background-color: var(--order-dot-color);
}

.track-order-3 > .track-order-dot:nth-of-type(2),
.track-order-3 > .track-order-dot:nth-of-type(3),
.track-order-3 > .track-order-dot:nth-of-type(4) {
    /* background-color: #173B83; */
    background-color: var(--order-dot-color);
}

.track-order-4 > .track-order-dot:nth-of-type(2),
.track-order-4 > .track-order-dot:nth-of-type(3),
.track-order-4 > .track-order-dot:nth-of-type(4),
.track-order-4 > .track-order-dot:nth-of-type(5) {
    /* background-color: #173B83; */
    background-color: var(--order-dot-color);
}

.track-order-5 > .track-order-dot:nth-of-type(2),
.track-order-5 > .track-order-dot:nth-of-type(3),
.track-order-5 > .track-order-dot:nth-of-type(4),
.track-order-5 > .track-order-dot:nth-of-type(5),
.track-order-5 > .track-order-dot:nth-of-type(6) {
    /* background-color: #173B83; */
    background-color: var(--order-dot-color);
}

.track-order-0 > .track-order-header:nth-of-type(1) {
    font-weight: 700;
}

.track-order-1 > .track-order-header:nth-of-type(2) {
    font-weight: 700;
}

.track-order-2 > .track-order-header:nth-of-type(3) {
    font-weight: 700;
}

.track-order-3 > .track-order-header:nth-of-type(4) {
    font-weight: 700;
}

.track-order-4 > .track-order-header:nth-of-type(5) {
    font-weight: 700;
}

.track-order-5 > .track-order-header:nth-of-type(6) {
    font-weight: 700;
}

.track-order-contact {
    text-align: center;
    margin-top: 20px;
}

.track-order-numbers {
    margin-top: 10px;
    font-size: 10px;
    display: flex;
    gap: 20px;
    justify-content: center;
}

@media screen and (min-width: 768px) {
    

    .track-order-contact {
    text-align: start;
    margin-top: 20px;
    width: 100%;
    }
    .track-order-back-button{
        padding-left: 45px;
    }
    .track-order-container{
        /* display: grid; */
        /* grid-template-columns: 55% 41%; */
        /* gap: 4%; */
        /* margin: 10px 25px; */
        padding-bottom: 20px;
    }
    /* .track-order-progess{
        padding-top: 5px;
        margin-top: 0px;
    } */
    .track-order-contact{
        grid-column: 1 / 3;
    }
  }

  @media screen and (max-width:768px) {
    .order-item-contianer{
        gap: 2px;
    }
    .track-order-container {
        padding-bottom: 20px;
    }
  }