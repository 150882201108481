.bg-white {
    background-color: var(--white-color);
}

.pure-white {
    background-color: var(--pure-white);
    /* background-color: aqua; */
}

.transparent {
    background-color: transparent;
}

.sectionChild {
    padding: 80px 80px;
}

.bannerHeroText {
    padding-block: 80px;
    padding-left: 80px;
    padding-right: 0px;
    width: 784px;
}

.browseBannerContent {
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: transparent;
    z-index: 1;
    top: 0;
    left: 0;
}

.sectionContainer > .sectionChild {
    margin: 0 !important;
}

.paddingBottomZero {
    padding-bottom: 0;
}

@media screen and (max-width:846px) {
    .bannerHeroText {
        width: 650px;
    }
}

@media screen and (max-width:768px) {
    .sectionChild {
        padding: 32px 24px;
    }
    .bannerHeroText {
        padding-block: 32px;
        padding-left: 24px;
        padding-right: 24px;
        width: 100%;
    }
}