.shop_now_bottom_container {
    margin-top: 70px;
}

.section1_container {
    padding-bottom: 30px;
}

.section_heading {
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 25px;
    letter-spacing: 0.4px;
    text-align: left;
}

.section2_main {
    padding-block: 30px;
}

.section3_container {
    margin-inline: 30px;
    padding-block: 30px;
}

.section4_container, .section5_container {
    padding-block: 30px;
}

.donor_section {
    display: flex;
    gap: 10px;
    flex-direction: column;
    padding-block: 20px;
}

.donor_heading {
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 35px;
    letter-spacing: 0.64px;
}

.donor_container {
    display: flex;
    justify-content: flex-start;
    max-width: 100%;
    min-width: 100%;
    overflow: auto;
    gap: 45px;
}

@media screen and (min-width:768px) {
    .section3_main {
       margin-inline: 3%;
    }
}

@media screen and (min-width:980px) {
    .section3_main {
       margin-inline: 9%;
    }
}

@media screen and (max-width:768px) {
    .mobile_padding_container {
        padding-inline: 15px;
    }
    .shop_now_bottom_container {
        margin-top: 25px;
    }
    .section2_main {
        padding-block: 23px;
    }
    .section3_container {
        margin-inline: 0px;
    }
    .donor_heading {
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 25px;
    }
    .donor_section {
        padding-block: 2px;
    }
    .section4_container {
        padding-top: 0px !important;
    }
    
}