.impact_main {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  background-color: black;
  padding: 80px;
}

.impact_main h1 {
  color: white;
  font-size: 96px;
  font-weight: bold;
  font-family: 'alternategotno1dregular';
  line-height: 1;
}
.impact_main h2 {
  color: white;
  font-size: 25px;
  max-width: 790px
}

.impact_btn_div {
  display: flex;
  justify-content: center;
  margin-top: 40px;
}

.impact_btn{
    border-Radius: 40px;
    min-width: 250px;
    padding: 7px 20px;
    background-color: var(--red-color);
    font-Weight: bold;
    color:white;
    font-family: 'alternategotno1dregular';
    font-size: 2rem;
    letter-spacing: 0.5px;
}

@media (max-width: 768px) {

  .impact_main {
    padding: 40px;
  }
  
  .impact_main h1 {
    font-size: 60px;
  }

}