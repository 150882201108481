.termsServiceContainer {
    background-color: var(--pure-white);
}

.termsDate {
    padding-bottom:19px;
}

.t_p_main {
    padding: 35px 23px;
    display: flex;
    flex-direction: column;
    gap: 25px;
    padding-top: 155px;
}
.t_p_main > h1 {
    /* font-weight: 800; */
    font-size: 42px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.84px;    
}
.t_p_main > .section {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 25px;
}
.t_p_main > .section > h3 {
    font-size: 25px;
    font-weight: 800;
    text-decoration: underline;
}
.text_sub {
    text-decoration: underline;
    font-style: italic;
}
.linkText {
    color: blue;
}
.listAlpha {
    counter-reset: list;
  }
  .listAlpha > li {
    list-style: none;
  }
  .listAlpha > li:before {
    content: "(" counter(list, lower-alpha) ") ";
    counter-increment: list;
  }
  .normalAlphaList > li {
    list-style: lower-alpha;
  }
.t_p_main > .section > ol,
.t_p_main > .section > .lists {
    padding: 0;
    padding-left: 17px;
    display: flex;
    flex-direction: column;
    gap: 3px;
}
.t_p_main > .section > .lists > .lists {
    padding: 0;
    padding-left: 17px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.t_p_main > .text > b,
.t_p_main > .section > .text > b,
.t_p_main > .section > .lists > .lists > li > b {
    font-weight: 700;
}
.sub_section {
    display: flex;
    flex-direction: column;
    gap: 15px;
}
.sub_section > h3 {
    /* font-weight: 600; */
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: -0.36px;
}

.text {
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.36px;
}

.termsEndLine {
    margin-top: 100px;
    color: var(--line-color);
}

@media screen and (min-width: 768px) {
    .t_p_main {
        /* margin: 50px auto !important;
        width: 85vw; */
        /* background-color: #EFEFEF; */
        padding-top: 170px;
        gap: 43px;
    }
    .t_p_main > h1 {
        font-size: 70px;
        font-style: normal;
        font-weight: 400;
        line-height: 75px; 
        letter-spacing: -1.4px;   
    }
}

@media screen and (max-width:768px) {
    .t_p_main {
        gap: 0;
    }
    .t_p_main > h1 {
        margin-bottom: 23px;
    }
    .section {
        gap: 0;
    }
    .section > h1 {
        font-size: 32px !important;
        font-style: normal;
        font-weight: 400 !important;
        line-height: 35px;
        letter-spacing: 0.64px;
    }
    .privacy_headings {
        margin-top: 23px;
    }
    .sub_section {
        gap: 0px;
    }
    .sub_section > h3 {
        display: flex;
        gap: 8px;
    }
    .termsEndLine {
        margin-top: 50px;
    }
}