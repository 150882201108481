.social-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  gap: 20px;
  background-color: white;
  padding: 80px;
}

.social-image {
  flex: 1;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  max-width: 550px;
}

.text-black {
  color: black;
}

.social-image img {
  max-width: 100%;
  height: auto;
  display: block;
}

.social-content {
  flex: 1;
  text-align: left;
  max-width: 480px;
}

.social-content h1 {
  font-size: 64px;
  /* line-height: 1.4; */
  margin-bottom: 15px;
}

.social-content .highlight {
  color: #FF0000;
  font-weight: bold;
  font-family: 'alternategotno1dregular';
  font-size: 80px;
  letter-spacing: 0.5px;
  line-height: 72px;
}

.social-content .text-black {
  font-family: 'alternategotno1dregular';
  font-size: 80px;
  letter-spacing: 0.5px;
  line-height: 72px;
  font-weight: bold;
}

.social-content p {
  font-size: 26px;
  line-height: 1.2;
  margin-bottom: 20px;
  color: black;
}

.cta-button {
  border-Radius: 40px;
  min-width: 250px;
  padding: 7px 20px;
  background-color: var(--red-color);
  font-Weight: bold;
  font-family: 'alternategotno1dregular';
  font-size: 2rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 40px;
}

.cta-button:hover {
  background-color: darkred;
}

.social-grid {
  display: flex;
  flex-wrap: wrap;
}

.social-content-wrapper {
  order: 2;
}

.social-image-wrapper {
  order: 1;
}

@media (max-width: 768px) {

  .social-content .highlight,
  .social-content .text-black {
    font-size: 60px;
  }

  .social-content-wrapper {
    order: 1;
  }

  .social-image-wrapper {
    order: 2;
  }

  .social-content h1 {
    font-size: 60px;
    margin-bottom: 15px;
    margin-left: 15px;
    letter-spacing: 0.5px;
    line-height: 65px;
  }

  .social-content .highlight {
    color: #FF0000;
    font-weight: bold;
    font-family: 'alternategotno1dregular';
    font-size: 60px;
    letter-spacing: 0.5px;
    line-height: 60px;
  }

  .social-content .text-black {
    font-family: 'alternategotno1dregular';
    font-size: 60px;
    letter-spacing: 0.5px;
    line-height: 60px;
    font-weight: bold;
  }

  .social-container {
    padding: 40px;
  }

  /* .social-image {
    max-width: 210px;
    margin: auto;
    margin-left: 20px;
  } */

  .social-content {
    margin: 0 0 30px;
  }
}

.social-image img {
  width: 100%;
}

@media only screen and (max-width: 899px) {
  .social-image {
    margin: 0 auto 30px;
  }
}