.dsub_navbar {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 20px 0;
    box-shadow: 0px 4px 8px 0px #0000001B;
}

.dsub_nav_links {
    display: flex;
    gap: 2rem;
    font-size: 0.9rem;
}

.dsub_nav_links > div {
    cursor: pointer;
}

.dsearch_container {
    border-bottom: 1px solid #d3d3d3;
    background-color: white;
    padding: 3px 10px;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
}

.dsearch_container > input {
    outline: none;
    border: none;
    margin-right: 5px;
    padding: 3px 5px;
}

.menu_icon{
    cursor: pointer;
    display: flex;
    justify-content: flex-end;
    padding-inline: 20px;
    z-index: 100;
    position: absolute;
    top: 32px;
    right: 0;
}
.mobile_nav_links_animater {
    position: absolute;
    left: 0;
    right: 0;
    z-index: 90;
    overflow: hidden;
    border-bottom: 1px solid #ccc;
}
.mobile_nav_links{
    display: flex;
    flex-direction: column;
    overflow: hidden;
    gap: 20px;
    padding-inline: 29px;
    padding-block:15px;
    font-size: 0.8rem;
    width: 100%;
    background-color: white;
    height: 300px;
}
.for_menu_close{
    position: fixed;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 80;
    left: 0;
    backdrop-filter: blur(10px);
    background-color: rgba(255,255,255,0.2);
}

@media screen and (max-width:320px) {
    .menu_icon{
        top: 18px;
    }
}

@media screen and (max-width:946px) {
    .dsearch_container{
        padding: 3px 5px;
    }
}

@media screen and (max-width:1000px) {
    .dsub_nav_links{
        /* gap: 0.9rem; */
        font-size: .8rem;
    }
}

@media screen and (max-width:933px) {
    .dsub_nav_links{
        gap: 0.9rem;
        font-size: 0.74rem;
    }
}
@media screen and (min-width:768px){
    .mobile_wrapper{
        display: none;
    }
}