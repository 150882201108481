.default-search-page {
  /* height: 100%; */
  display: flex;
  flex-direction: column;
}
.search-header {
  display: flex;
  align-items: center;
  gap: 12px;
  /* margin-top: 16px; */
}

.search-header .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
  transform: translate(14px, 8px) scale(1);
}

.search-header .MuiOutlinedInput-input {
  padding: 8px 4px;
  /* height: 32px; */
}

.search-header .MuiOutlinedInput-root {
  height: 36px;
}

.cancel-search {
  margin: 0;
  font-family: "AvenirNext";
  font-weight: 600;
  font-size: 16px;
}

.search-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
}

.search-body__text {
  margin-top: 8px;
}

@media screen and (min-width:768px) {
  .search-header {
    width: 100%;
  }
}