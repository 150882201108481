.daccount_container {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
}

.daccount_content {
    min-height: 50vh;
    width: 75vw;
    margin: 0 auto;
    background-color: white;
    overflow: hidden;
    border-radius: 10px;
    box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
}

.daccount_content_tabs {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #d3d3d3;
}

.daccount_content_tabs > div {
    padding: 15px 25px;
    flex-shrink: 0;
    cursor: pointer;
}

.daccount_content_tabs > div.active {
    background-color: #EFEFEF;
    font-weight: 600;
}

.daccount_content_body {
    padding: 20px;
}

.daccount_content_info {
    margin-bottom: 50px;
}

.daccount_content_shipping {
    margin-top: 30px;
}

.daccount_content_body h5 {
    font-weight: 600;
}

.daccount_content_shipping > div {
    font-weight: 600;
    font-size: 0.9rem;
}

.daccount_add_btn {
    font-weight: 600;
    border: 1px solid #d3d3d3;
    padding: 10px 20px;
    border-radius: 6px;
    background-color: var(--primary-btn-clr);
    color: white;
}

@media screen and (max-width: 1210px) {
    .daccount_content {
        width: 85vw;
    }
}

@media screen and (max-width: 1060px) {
    .daccount_content {
        width: 95vw;
        font-size: 0.9rem;
    }
}

@media screen and (max-width: 880px) {
    .daccount_content_tabs > div {
        padding: 15px 20px;
        flex-shrink: 0;
    }
}