button.primary-btn--default {
    /* margin: 6px 12px 24px 12px; */
    margin: 6px 2px 18px 2px;
    width: -webkit-fill-available;
    height: 48px;
    background-color: #1A385C;
    color: white;
    border-radius: 4px;
    padding: 8px 32px;
}

@media screen and (min-width: 768px) {
    button.primary-btn--default {
        margin: 0 auto;
        width: auto;
        height: auto;
    }
} 