:root {
    --red-color:#FC3300;
    --black-color:#30313A;
    --white-color:#F0E8E3;
}

.snackContainer {
    min-width: 100%;
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
    padding: 20px 20px;
    border-radius: 25px;
    background-color: var(--white-color);
    border: 2px solid var(--black-color);
}

.successMessage {
    font-size: 18px;
    font-weight: 600;
    color: var(--red-color);
    margin-bottom: 0;
}

@media screen and (max-width:767px) {
    .snackContainer {
        min-width: 80%;
        width: 80%;
        gap: 10px;
    }
}

@media screen and (max-width:380px) {
    .snackContainer {
        min-width: 90%;
        width: 90%;
        gap: 6px;
    }
}