.go_back_button {
    display: flex;
    column-gap: 5px;
    justify-content: center;
    align-items: center;
    font-size: 18px
}

.items_list_main {
    margin-bottom: 10px;
}

.back_container {
    margin-bottom: 25px;
}

.group_name {
    font-size: 1.3rem;
    margin-top: 16px;
    margin-left: 10px;
    font-weight: bold;
    text-transform: capitalize;
}

.countdown_container {
    /* background-color: aquamarine; */
}

.no_data {
    padding-top: 4vh;
    display: flex;
    justify-content: center;
    align-items: center
}
/* .old_pagination{

    font-size: 1.1rem;
    margin-top: 30px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    margin-bottom: 0px;
} */
.pagination_container {
   margin-top: 80px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.return_buttons {
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin: 25px auto 40px;
    padding-bottom: 50px
}

.return_event {
    text-align: left;
    width: fit-content
}

.return_event_button {
    border-bottom: 1px solid #30313a;
    margin: 0
}

.sortby_container {
    display: flex;
    justify-content: space-between;
    gap: 30px;
    width: 100%;
    max-width: 100%;
    margin-top: 30px;
    margin-bottom: 70px;
    align-items: center;
    height: 20px;
}

.sortby_section {
    display: flex;
    gap: 8px;
    max-width: 67%;
    min-width: 67%;
    overflow-x: auto;
    position: relative;
    scroll-behavior: smooth;
}

.width_class, .marketplace_width_class {
    width: auto;
    max-width: 70%;
    min-width: 70%;
}
.full_width_class {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
}
.search_section {
    width: 30%;
}
.search_icon {
    cursor: pointer;
}
.bread_crumb_container {
    display: flex;
    gap: 1px;
    align-items: center;
}
.bread_crumb_container > p {
    color: #30313A;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0px;
    margin-bottom: 0;
}
.category_crumb {
    border-bottom: 1px solid #30313A;
}
.filters_buttons_container {
    display: flex;
    font-size: 6px;
}
.margin_class{
    padding-inline: 80px;
}
.filterdOutputs {
    /* max-width: 60vw; */
    overflow-x: auto;
    display: flex;
    gap: 24px;
    align-items: center;
    margin-bottom: 70px;
}
.outPut {
    padding: 12px 16px;
    border-radius: 25px;
    font-size: 14px;
    font-weight: 700;
    line-height: 17.08px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    width: max-content;
    background-color: var(--off-white);
}
.outSpan {
    width: max-content;
    text-transform: uppercase;
}
.flashCardContainer {
    max-width: 290px;
}
.event_title{
    font-size: 48px;
    font-weight: 700;
    text-wrap: balance;
    position: sticky;
}
.event_header{
    margin: 20px 80px 0;
    /* position: sticky; */
    background-color: white;
    top: 0;
    z-index: 100;
}
.event_main_title{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
}
.event_main_2{
    display: flex;
    align-items: center;
}
.product_count_2{
    display: none;
}
@media screen and (max-width:1400px) {
    .width_class {
        width: auto;
        max-width: 60%;
        min-width: 60%;
    }
}
@media screen and (max-width:1080px) {
    .search_section {
        width: 100%;
        margin-bottom: 30px;
    }
    .marketplace_width_class {
        width: 90%;
        max-width: 90%;
        min-width: 90%;
    }
}
@media screen and (max-width:767px) {
    .go_back_button {
        font-size: 12px;
    }
    .bread_crumb_container > p {
        font-size: 12px;
        line-height: 18px;
    }
    .search_section {
        margin-bottom: 34px;
        padding-inline: 5vw;
    }
    .margin_class {
      padding-inline: 0;
    }
    .sortby_container {
        padding-inline: 5vw;
    }
    .filterdOutputs {
        overflow-x: hidden;
        flex-wrap: wrap;
        /* max-width: 100%; */
        margin-bottom: 0;
        padding-inline: 5vw;
    }
    .outPut{
        width: auto;
    }
    .outSpan {
        width: auto;
    }
}
@media screen and (max-width:610px) {
    .event_main_title{
        flex-direction: column;
    }
    .button_container{
        display: flex;
        width: 100%;
        justify-content: space-between;
    }
    .event_main_2{
        flex-direction: column;
        width: 100%;
        align-items: start;
    }
    .product_count{
        display: none;
    }
    .product_count_2{
        display: block;
        border: none !important;
        margin: 0  0 !important;
    }
    
    .event_header{
        margin: 20px 10px;
    }
    
    .filter_button{
        display: none;
    }
    .sortby_container {
        gap: 9px;
    }
    .width_class {
        width: auto;
        max-width: none;
        min-width: 60%;
    }
    .event_title{
        font-size: 1.5rem;
        padding: 0px 5px;
    }
}
@media screen and (max-width:420px) {
    .filters_buttons_container {
        flex-direction: column;
        gap: 7px;
        align-items: flex-end;
    }
}
@media screen and (max-width:340px) {
    .go_back_button {
        flex-wrap: wrap;
        justify-content: flex-start;
    }
    .sortby_container {
        gap: 5px;
    }
}
@media screen and (max-width:660px) {
    .flashCardContainer {
        max-width: 345px;
    }
  
}