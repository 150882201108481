.main_container {
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
    max-width: 196px;
    /* height: auto; */
    gap: 39px;
    height: 100%;
    transition: height 0.8s;
    position: relative;
}

.img_container {
    width: 100%;
    height: 196px;
    max-height: 196px;
    min-height: 196px;
    border-radius: 25px;
    cursor: pointer;
}

.browsePageCardImage {
    margin: auto;
}

.img_container > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 25px;
}

.content_container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: space-between;
    height: 100%;
}

@media screen and (max-width:1300px) {
    .browsePageCardImage {
        width: 180px;
        height: 180px;
        max-height: 180px;
        min-height: 180px;
    }
}

@media screen and (max-width:1150px) {
    .browsePageCardImage {
        max-height: 154.5px;
        min-height: 154.5px;
        height: 154.5px;
        width: max-content;
        aspect-ratio: 1 / 1;
    }
}

@media screen and (max-width:360px) {
    .browsePageCardImage {
        max-height: 134.5px;
        min-height: 134.5px;
        height: 134.5px;
        width: 134.5px;
        aspect-ratio: 1 / 1;
    }
}

@media screen and (max-width:340px) {
    .browsePageCardImage {
        max-height: none;
        min-height: auto;
        height: auto;
        width:100%;
        aspect-ratio: 1 / 1;
    }
}