.go_back_button {
  display: flex;
  column-gap: 5px;
  justify-content: center;
  align-items: center;
  font-size: 18px;
}

.checkboxlabel {
  display: flex;
  align-items: center;
  margin-right: 10px;
  cursor: pointer;
}

.checkboxlabel input {
  margin-right: 5px;
}

.items_list_main {
  margin-bottom: 10px;
}

.back_container {
  margin-bottom: 25px;
}

.group_name {
  font-size: 1.3rem;
  margin-top: 16px;
  margin-left: 10px;
  font-weight: bold;
  text-transform: capitalize;
}

.no_data {
  padding-top: 4vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* .old_pagination{
  
      font-size: 1.1rem;
      margin-top: 30px;
      font-weight: 600;
      display: flex;
      justify-content: center;
      margin-bottom: 0px;
  } */
.pagination_container {
  margin-top: 80px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.return_buttons {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin: 25px auto 40px;
  padding-bottom: 50px;
}

.return_event {
  text-align: left;
  width: fit-content;
}

.return_event_button {
  border-bottom: 1px solid #30313a;
  margin: 0;
}

.sortby_container {
  display: flex;
  gap: 12px;
  flex-direction: column;
  /* display: flex;
    justify-content: space-between;
    gap: 30px;
    width: 100%;
    max-width: 100%;
    margin-top: 30px;
    margin-bottom: 70px;
    align-items: center; */
  /* height: 20px; */
}

.sortby_section {
  display: flex;
  gap: 8px;
  max-width: 67%;
  min-width: 67%;
  overflow-x: auto;
  position: relative;
  scroll-behavior: smooth;
  flex-direction: column;
}

.width_class,
.marketplace_width_class {
  width: auto;
  max-width: 70%;
  min-width: 70%;
}

.full_width_class {
  width: 100%;
  max-width: 100%;
  min-width: 100%;
}

.search_section {
  width: 30%;
}

.search_icon {
  cursor: pointer;
}

.bread_crumb_container {
  display: flex;
  gap: 1px;
  align-items: center;
}

.bread_crumb_container>p {
  color: #30313a;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  margin-bottom: 0;
}

.category_crumb {
  border-bottom: 1px solid #30313a;
}

.filters_buttons_container {
  display: flex;
}

.margin_class {
  padding-inline: 80px;
}

.filterdOutputs {
  /* max-width: 60vw; */
  overflow-x: auto;
  display: flex;
  gap: 24px;
  align-items: center;
  margin-bottom: 70px;
}

.outPut {
  padding: 12px 16px;
  border-radius: 25px;
  font-size: 14px;
  font-weight: 700;
  line-height: 17.08px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  width: max-content;
  background-color: var(--off-white);
}

.outSpan {
  width: max-content;
  text-transform: uppercase;
}

.page_header {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  position: relative;
  margin: 0 0 50px;
}

.page_header:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: -9999px;
  right: -9999px;
  height: 1px;
  background-color: #f1f1f1;
}

.page_header ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

.page_header ul>li:first-child .d_dropdown {
  left: 0;
  right: auto;
  min-width: 100px;
}

.page_header ul>li:last-child .d_dropdown a {
  text-align: left;
}

.goalsSection {
  background-color: #f1f1f1;
  padding: 30px 80px;
}

.goalCards {
  background-color: #fff;
  padding: 20px;
  text-align: center;
}

.iconText {
  color: var(--red-color);
  font-weight: bold;
}

.custom_dropdown {
  position: relative;
  margin-bottom: 0 !important;
}

.custom_dropdown .d_dropdown {
  border: 3px solid #f1f1f1;
  border-radius: 30px;
  padding: 0;
  display: none;
  min-width: 230px;
  position: absolute;
  top: 100%;
  right: -28px;
  background-color: #fff;
  z-index: 99;
}

.custom_dropdown:hover .d_dropdown {
  display: block;
}

.custom_dropdown .d_dropdown ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: block;
}

.custom_dropdown .d_dropdown ul li {
  display: block;
}

.custom_dropdown .d_dropdown ul li button {
  display: block;
  padding: 6px 15px;
  text-align: center;
}

.custom_dropdown .d_dropdown ul li button:hover {
  background-color: #f1f1f1;
}

.color_palete {
  display: flex !important;
  gap: 10px;
  flex-wrap: wrap;
  padding: 20px !important;
  justify-content: center;
}

.color_palete li {
  width: 23%;
}

.color_palete button {
  width: 30px;
  height: 30px;
  border-radius: 100%;
  margin: 0 auto;
  display: block !important;
  padding: 0 !important;
}

.color_palete button.white {
  border: 2px solid #000;
}

.color_palete .grey {
  background-color: #8f8f8f;
}

.color_palete .pink {
  background-color: #c85287;
}

.color_palete .orange {
  background-color: #c2127f;
}

.color_palete .red {
  background-color: #9d0000;
}

.color_palete .brown {
  background-color: #793f10;
}

.color_palete .yellow {
  background-color: #f4d66e;
}

.color_palete .purple {
  background-color: #4f1769;
}

.color_palete .blue {
  background-color: #28508d;
}

.color_palete .green {
  background-color: #254b2f;
}

.color_palete .black {
  background-color: #000000;
}

.color_palete .white {
  background-color: #ffffff;
}

.desktop_none {
  display: none;
}


.shop_now_filter_btn .filterButtons {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  border: none;
}

.shop_now_filter_btn button {
  border-radius: 0 !important;
  color: black !important;
  border-bottom: 1px solid rgb(134, 133, 133);
  display: flex !important;
  justify-content: space-between !important;
  padding: 40px 0 !important;
  width: 100% !important;
  border-top: 0 !important;
  border-left: 0 !important;
  border-right: 0 !important;
}

.brand_name {
  font-size: 45px;
  font-family: 'alternategotno1dregular';
  font-weight: bold;
  margin-bottom: 20px;
}


@media screen and (max-width: 1400px) {
  .width_class {
    width: auto;
    max-width: 60%;
    min-width: 60%;
  }
}

@media screen and (max-width: 1080px) {
  .search_section {
    width: 100%;
    margin-bottom: 30px;
  }

  .marketplace_width_class {
    width: 90%;
    max-width: 90%;
    min-width: 90%;
  }
}

@media screen and (max-width: 768px) {

  .brand_name {
    padding: 0 20px;
  }

  .go_back_button {
    font-size: 12px;
  }

  .desktop_none {
    display: block;
  }

  .bread_crumb_container>p {
    font-size: 12px;
    line-height: 18px;
  }

  .search_section {
    margin-bottom: 34px;
    padding-inline: 5vw;
  }

  .margin_class {
    padding-inline: 0;
  }

  .sortby_container {
    flex-direction: row;
    flex-wrap: wrap;
    /* padding-inline: 5vw; */
  }

  .d_flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    justify-content: space-between;
  }

  .d_flex h2 {
    margin: 0;
    font-size: 40px;
  }

  .filterdOutputs {
    overflow-x: hidden;
    flex-wrap: wrap;
    /* max-width: 100%; */
    margin-bottom: 0;
    padding-inline: 5vw;
  }

  .outPut {
    width: auto;
  }

  .outSpan {
    width: auto;
  }

  .filter_section_div {
    display: flex;
    width: 100% !important;
    margin-bottom: 5%;
  }

  .page_header ul {
    display: none;
    /* currently hidden on mobile, will show onClick */
  }

  .page_header {
    align-items: start;
  }

  .goalsSection {
    padding: 20px;
  }

  .goalCards {
    background-color: #fff;
    padding: 5px;
    text-align: center;
  }

  .goalCards span {
    font-size: 14px;
  }

  .h-100 {
    height: 100% !important;
  }
}

@media screen and (max-width: 610px) {

  /* .sortby_container {
      gap: 9px;
    } */
  .width_class {
    width: auto;
    max-width: none;
    min-width: 60%;
  }
}

@media screen and (max-width: 420px) {
  .filters_buttons_container {
    flex-direction: column;
    gap: 7px;
    align-items: flex-end;
  }
}

@media screen and (max-width: 340px) {
  .go_back_button {
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  /* .sortby_container {
      gap: 5px;
    } */
}

.shop_collection {
  padding: 32px;
}

.collection_title {
  font-size: 100px;
  /* margin-bottom: 24px; */
  font-family: fantasy;
  text-align: center;
  font-family: "alternategotno1dregular";
  font-weight: bold;
  margin-left: 10%;
}

.collection_content {
  display: flex;
  /* gap: 24px; */
}

.filter_section_div {
  width: 25%;
  /* background-color: #F9F9F9; */
  /* padding: 16px; */
}

.product_grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  row-gap: 70px;
  column-gap: 14px;
  padding: 0 15px;
  width: 80%;
}

.product_card {
  overflow: hidden;
}

.product_card .img_holder {
  width: 290px;
  height: 290px;
  margin: 0 0 20px;
}

.product_image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.product_info {
  font-size: 16px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.product_info strong {
  display: block;
  margin: 0;
  font-size: 22px;
  line-height: 1;
  font-family: var(--test-founders-grotesk-x-condensed-bold);
}

.product_info span {
  display: block;
  margin: 0;
}

.product_info .retail_price {
  font-style: normal;
  color: #212529;
  font-size: 16px;
  line-height: 1.5;
  margin: 0;
  text-decoration: line-through;
}

.product_info .pay {
  color: red;
}

.product_brand {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 8px;
}

.product_description {
  font-size: 14px;
  color: #555;
  margin-bottom: 8px;
}

.retail_price {
  font-size: 14px;
  color: #888;
  /* text-decoration: line-through; */
  margin-bottom: 8px;
}

.sale_price {
  font-size: 16px;
  color: red;
  font-weight: bold;
  margin-bottom: 8px;
}

.price_amount {
  font-size: 18px;
  font-weight: bold;
}

.donation_amount {
  font-size: 14px;
  color: #555;
  margin-top: 8px;
}

.shop_now_banner {
  background-color: red;
  min-height: 350px;
}

@media screen and (max-width: 768px) {
  .product_grid {
    grid-template-columns: repeat(2, 1fr);
    width: 100%;
  }

  .collection_title {
    font-size: 70px;
    font-weight: bold;
    font-family: "alternategotno1dregular";
  }

  .collection_content {
    display: block;
  }
}

@media screen and (max-width: 480px) {
  .collection_title {
    font-size: 55px;
    font-weight: bold;
    font-family: "alternategotno1dregular";
    margin-left: 0;
  }

  .product_grid {
    grid-template-columns: repeat(1, 1fr);
  }
}
