.main_div {
    /* padding: 100px 24px 20px 24px !important; */
    padding: 10px 24px 20px 24px !important;
}

.label_container {
    display: flex;
    justify-content: center;
    align-items: end;

}

.label_container button {
    position: absolute;
    text-align: center;
    color: white;
    font-size: 14px;
    background-color: #1a385c;
    margin-bottom: 15px;
    border-radius: 5px;
    font-weight: 500;
    padding: 10px;
}

.auction_main_load_more {
    text-align: center;
    font-weight: 600;
    font-size: 14px;
    margin-top: 30px;
}

.moblie_auction_group_title {
    font-size: 42px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.84px;
    color: var(--charcoal, #30313A);
    margin-top: 35px;
}

.desktop_auction_group_title_wrapper {
    margin: 20px auto 0px;
    max-width: 1020px;
    padding: 0px 20px;
}

.desktop_auction_group_title {
    margin-top: 20px;
    font-weight: 600;
    font-size: 42px;
    color: var(--color-lffu6uvb)
}

.event_card_image_container {
    width: 110px;
    height: 110px;
    min-height: 110px; 
    margin-bottom: 10px;
    background: transparent;
}

.event_empty_container {
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
  }
  
/* @media screen and (max-width: 768px) { */
    /* .main_div {
        background-color: #F0E8E3;
    } */

    .event_card {
        display: flex;
        /* width: 315px; */
        /* height: 332px; */
        margin: 20px auto;
        padding: 25px;
        flex-direction: column;
        align-items: flex-start;
        flex-shrink: 0;
        border-radius: 25px;
        border: 2px solid var(--charcoal, #30313A);
        background: var(--transparent, rgba(255, 255, 255, 0.00));
    }

    .details_section {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
    }

    .names_section>p {
        color: var(--charcoal, #30313A);
        margin-bottom: 20px;
    }

    .event_card img {
        margin-bottom: 20px;
    }

    .names_section>button {
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        letter-spacing: 0.98px;
        text-transform: uppercase;

        display: flex;
        width: 143px;
        height: 28px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 25px;

        background: #30313a;
        color: #F0E8E3;
    }

    .names_section>.cause_name {
        font-family: DM Mono;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -0.32px;
    }

    .names_section>.event_title {
        font-size: 32px;
        font-style: normal;
        font-weight: 400;
        line-height: 35px;
        letter-spacing: -0.64px;
    }
/* } */


@media screen and (min-width: 768px) {
    .dauctionMain_no {
        max-width: 1020px;
        padding: 0 20px;
        margin: 0 auto;
        padding-top: 20px;
    }

    .dauctionMain_cards {
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 20px;
        row-gap: 20px;
        /* max-width: 1020px; */
        /* margin: 0 auto; */
        /* padding: 0 20px; */
    }

    .dauctionMain_card {
        flex-direction: column;
        padding-left: 0;
        box-shadow: none;
        border-radius: 0;
        border: 1px solid #d3d3d3;
        width: 100%;
        transition: box-shadow 0.2s;
    }

    .dauctionMain_card:hover {
        box-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1);
    }

    .dauctionMain_card_image {
        width: 100%;
        height: 300px;
        border-radius: 0;
        border-bottom: 1px solid #d3d3d3;
        background: #f6f6f6;
    }

    .dauctionMain_card_image>img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    .dauctionMain_card_content>div:nth-child(1) {
        font-weight: 600;
        padding: 0 20px;
        padding-top: 10px;
    }

    .dauctionMain_card_content>div:nth-child(2) {
        padding: 0 20px;
        font-size: 0.8rem;
    }

    .dauctionMain_card_content>div:nth-child(3) {
        padding: 0 20px;
        font-weight: 600;
        display: grid;
        place-items: center;
        background-color: #e4e4e4;
        height: 40px;
        cursor: pointer;
        margin-top: 10px;
    }

    .main_div {
        width: auto !important;
    }

    .main_div > div {
        padding: 0 !important;
    }

    .event_card {
        margin: 0;
        /* padding: 40px; */
        padding: 30px;
        gap: 9px;
    }

    .names_section>.event_title {
        /* font-size: 42px; */
        font-size: 38px;
        line-height: 47px;
        letter-spacing: 0.84px;
    }

    .names_section>p {
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.36px;
    }

    .names_section>button {
        margin-top: 6px;
    }

    .moblie_auction_group_title {
        font-size: 55px;
        line-height: 75px;
        letter-spacing: 1.4px;
        margin-top: 35px;
    }

    .event_empty_container {
        height: 400px;
    }

}
