:root{
    
    --headlinefont: Roboto Condensed;
    --contentfont: Cabin;
}

.landing_page{
    overflow-x: hidden;
}

.landing_page section>div {
    max-width: 1170px;
    margin: auto auto;
}

@media only screen and (min-width: 768px) {
    .landing_page h1,h2,h3{
        font-family: var(--headlinefont);
    }
    .landing_page p{
        font-family: var(--contentfont);
    }
}
