.capture-card-details {
  padding-top: 170px;
}

.capture-card-details__section__heading {
  /* font-family: "AvenirNext"; */
  font-weight: 800;
  font-size: 21px;
  margin-bottom: 24px;
}

.capture-card-details input {
  width: 100%;
  display: flex;
  padding: 14px 20px 14px 10px;
  align-items: center;
  gap: 10px;
  border-radius: 8px !important;
  margin-bottom: 23px;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  font-family: 'Instrument Sans';
}

.capture-card-details input[type=number] {
  height: 48px;
  padding: 12px;
  margin-bottom: 24px;
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 4px;
  background-color: rgb(240, 232, 227);
}

.capture-card-details input:hover {
  border-color: black;
}

.capture-card-details section {
  display: flex;
  flex-direction: column;
}

#billing-address {
  margin-bottom: 36px;
}

.card-secure-info {
  display: flex;
  justify-content: space-between;
  column-gap: 20px;
}

.card-secure-info__input {
  flex: 1;
  width: 100%;
}

.card-secure-info .country-selector {
  flex: 1;
}

.card-secure-info .country-selector__value-container {
  position: initial;
}

.card-icons-container {
  display: flex;
  gap: 18px;
  margin-bottom: 15px;
  align-items: center;
}

.card-icons>img {
  width: 60px;
  height: 60px;
}

.capture-card-details input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.input_label {
  margin-bottom: 10px;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.36px;
}

#demo-simple-select{
  font-size: 12px;
  font-family: 'Instrument Sans';
}

.visa_container {
  border-radius: 25px;
  background: white;
  padding: 7px 10px;
}

.visa_img {
  height: 16px !important;
  width: auto !important;
}

.master_container {
  padding: 3px 10px;
  background: white;
  border-radius: 25px;
}

.master_card_img {
    width: auto !important;
    height: 32px !important;
    object-fit: cover;
}

.amex_container {
    padding: 6px 13px;
    background: white;
    border-radius: 25px;
}

.amex_img {
  object-fit: cover;
  height: 26px !important;
  width: 52px !important;
  background-color: transparent;
  border-radius: 3px;
}

@media screen and (max-width:768px) {
  .capture-card-details {
    padding-top: 135px;
    padding-inline: 20px;
  }

  .capture-card-details__section__heading {
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 25px;
  }

  .capture-card-details input{
    display: flex;
    padding: 14px 20px 14px 10px;
    align-items: center;
    gap: 10px;
    border-radius: 8px !important;
    margin-bottom: 23px;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    font-family: 'Instrument Sans';
  }

  .shipping-address-label {
    margin-top: 20px;
  }

}