.buy-checkout-container {
    min-height: 100vh;
    padding: 10px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.ani-placeholder {
    margin: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.check-message {
    font-family: "AvenirNext";
    font-size: 25px;
    font-weight: 600;
    margin-bottom: 10px;
}

.order-info {
    font-family: "AvenirNext";
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 25px;
    color: var(--primary-blue-clr);
}

.buy-checkout-image {
    width: 220px;
    height: 220px;
    margin-bottom: 20px;
}

.buy-checkout-image img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.buy-checkout-name {
    font-size: 20px;
    font-weight: 600;
    padding: 1px 5px;
}

.buy-checkout-btn, .buy-checkout-btn-outline{
    width: 200px;
    height: 40px;
    border-radius: 5px;
}

.buy-checkout-btn {
    margin-top: 20px;
    background-color: var(--primary-btn-clr);
    color: white;
}

.buy-checkout-btn-outline {
    margin-top: 13px;
    border: 1px solid  var(--primary-btn-clr);
    color:  var(--primary-btn-clr);
}
.new-checkout-screen{
    /* height: 92vh; */
    min-height: 70vh;
    padding: 35px;
    font-family: "Instrument Sans";
    background-color: #FC3300;
    color: #F0E8E3;
    max-width: 500px;
    margin-inline: auto;
}
.return-to-event-btn{
    border: 2px solid #F0E8E3;
    height: 38px;
    width: 300px;
    border-radius: 25px;
    color: #F0E8E3;
    font-size: 16px;
    font-weight: 600;
    margin-top: 35px;
    text-transform: uppercase;

}

.bnc-tax-benefits {
    background-color:#FC3300 ;
    padding: 0 35px 40px 35px;
    color:#F0E8E3 ;
    text-align: justify;
    -webkit-text-align: justify;
    font-size: 17px;
    padding-top: 5px;
}

.bnc_container {
    min-height: 100vh;
}

.bnc_main {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

@media screen and (min-width: 768px) {
    .new-checkout-screen{
        min-height: 95vh;
    }
    .buy-checkout-container {
        height: 50vh;
        display: block;
    }
    .ani-placeholder {
        transform: scale(0.7);
    }
    .dconfirm-info {
        width: 75vw;
        margin: 0 auto;
        display: flex;
        align-items: center;
        flex-direction: column;
    }
    .dconfirm-info {
        width: fit-content;
    }
    .bnc-tax-benefits {
        font-size: 18px; 
        padding-top: 0;
    }
}