.section {
    position: relative;
    /* min-height: 50vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;  */
    /* display:flex;
    justify-content: center;
    align-items: center; */
    padding-block: 60px;
    height: 100%;
}

.heading,.featured_title {
    font-family: "Instrument Sans";
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 35px;
    letter-spacing: 0px;
    text-align: center;
}

.date,.featured_ends_on {
    font-family: "Instrument Sans";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: center;
}

.main_container {
    border-radius: 25px;
    padding-block: 50px;
    background-size: cover;
}

.new_non_profit_container {
    display: flex;
    flex-direction: column;
    gap: 31px;
    justify-content: center;
    align-items: center;
    border-radius: 25px;
}

.section_heading {
    text-align: center;
    font-size: 42px;
    font-style: normal;
    font-weight: 400;
    line-height: 47px;
    letter-spacing: 0px;
}

.carosel_container_wrapper {
    min-width: 100%;
    max-width: 100%;
    display: flex;
    justify-content: center;
}

.carosel_container {
    display: grid;
    grid-auto-flow: column;
    column-gap: 30px;
    overflow-x: auto;
    max-width: 90%;
    min-width: 90%;
    max-height: 100%;
    height: 100%;
    overflow-y: hidden;
}

.card_main {
    /* min-width: 309px;
    min-height: 276px; */
    border-radius: 25px;
    background-color: transparent;
    display: flex;
    gap: 15px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;
}

.featured_image_container {
    /* height: 80px; */
    /* width: 80px; */
    /* height: 40%; */
    height: 140px;
    width: 100%;
}

.featured_image {
    max-width: 100%;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}

.card_bottom_section {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* height: 100%; */
    flex-grow: 1;
    align-items: center;
    padding-inline: 21px;
    padding-bottom: 26px;
}

.date_view_section {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    justify-content: flex-end;
}

@media screen and (min-width:476px) {
    .section {
        min-height: 40vh;
        /* padding-left: 50px; */
    }
}

@media screen and (max-width:768px){
    .section_heading {
        font-size: 32px;
        line-height: 35px;
    }
    .new_non_profit_container {
        /* height: 405px; */
    }
    
    /* .featured_image_container {
    height: 80px;
    width: 80px;
    } */
}

@media screen and (max-width:540px) {
    .featured_image_container {
        height: 136px;
    }
}

@media screen and (max-width:390px) {
    .featured_image_container {
        height: 90px;
    }
}