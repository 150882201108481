.history_wrapper {
    min-height: 100vh;
    width: 100vw;
}
.history_header {
    background-color: #1A385C;
    box-shadow: 0px 12px 30px rgba(0, 0, 0, 0.1);
    height: 154px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 30px 20px;
}
.history_sticky {
    position: sticky;
    top: 0;
}
.top_menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #FFFFFF;
}
.history_header > h3 {
    font-family: 'AvenirNext';
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    line-height: 20px;
    /* text-align: center; */
    letter-spacing: -0.24px;
    color: #FFFFFF;
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.history_nav {
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-bottom: 1px solid #CDCDCD;
    background-color: #fff;
}
.history_nav > p {
    margin: 0;
    padding: 16px 0;
    /* font-family: 'AvenirNext'; */
    /* font-style: normal; */
    /* font-weight: 600; */
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    letter-spacing: -0.24px;
    /* color: #CDCDCD; */
}
.history_nav > .selected {
    /* color: #173B83 !important; */
    color: #212529 !important;
    border-bottom: 1px solid #173B83;
}

.history_body {
    display: flex;
    flex-direction: column;
    gap: 30px;
    flex-grow: 1;
    padding: 46px 16px;
}
.history_body > .section {
    display: flex;
    flex-direction: column;
    gap: 32px;
}
.history_body > .section > h5 {
    font-family: 'AvenirNext';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.24px;
    color: #000000;
}

.paper_div {
    /* margin: 30px 10%; */
    box-shadow: 1px 1px 4px 2px rgba(123, 116, 116, 0.2);
    background-color: white;
}

.current_order_card {
    display: flex;
    gap: 10px;
    flex-direction: column;
    background-color:#173B830A;
    padding: 12px;
    border-radius: 10px;
}
/* .current_order_card > .help {
    font-family: 'AvenirNext';
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 11px;
    text-align: center;
    letter-spacing: -0.24px;
    color: #173B83;
    display: flex;
    justify-content: space-between;
    align-items: center;
} */

.help {
    /* font-family: 'AvenirNext'; */
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 11px;
    text-align: center;
    letter-spacing: -0.24px;
    color: #173B83;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.current_order_card > .main {
    display: flex;
    gap: 10px;
}
.current_order_card > .main > .image > img {
    width: 100px;
    height: 100px;
    /* flex-grow: 1; */
    border-radius: 5px;
}
.current_order_card > .main > .image {
    flex-shrink: 0;
}
.current_order_card > .main > .content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.current_order_card > .main > .content > h3 {
    font-family: 'AvenirNext';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.24px;
    color: #000000;
    margin: 0;
    margin-bottom: 6px;
}
.current_order_card > .main > .content > p {
    font-family: 'AvenirNext';
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 10px;
    letter-spacing: -0.2px;
    color: #000000;
    margin: 0;
    margin-bottom: 14px;
}
.current_order_card > .main > .content > .info {
    font-family: 'AvenirNext';
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 10px;
    letter-spacing: -0.24px;
    color: #000000;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.past_history_carousel {
    flex-grow: 1;
    background: rgba(23, 59, 131, 0.04);
    border-radius: 10px;
    display: flex;
    overflow: auto;
    gap: 28px;
    padding: 20px;
}
.past_history_carousel::-webkit-scrollbar {
    display: none;
  }
.past_history_carousel {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}
.past_orders_card {
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    /* align-items: center; */
    width: fit-content;
}
.past_orders_card > img {
    width: 115px;
    height: 115px;
    border-radius: 5px;
}
.past_orders_card > h3 {
    font-family: 'AvenirNext';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    letter-spacing: -0.24px;
    color: #000000;
    margin: 0;
}

.history_footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.history_footer > h3 {
    font-family: 'AvenirNext';
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 17px;
    text-align: center;
    letter-spacing: -0.24px;
    color: #000000;
}
.history_footer > p {
    font-family: 'AvenirNext';
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 11px;
    text-align: center;
    letter-spacing: -0.24px;
    color: #173B83;
}
.history_footer_contacts {
    display: flex;
    gap: 25px;
    justify-content: center;
}
.history_footer_contacts > p {
    display: flex;
    align-items: center;
    gap: 5px;
    font-family: 'AvenirNext';
    font-style: normal;
    font-weight: 500;
    font-size: 9px;
    line-height: 9px;
    text-align: center;
    letter-spacing: -0.24px;
    color: #000000;
}

.need_help {
    display: flex;
    flex-direction: column;
    gap: 80px;
    justify-content: flex-start;
    /* font-family: 'AvenirNext'; */
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    letter-spacing: -0.24px;
    color: #000000;
}
.link_content {
    text-decoration-line: underline;
    color: #173B83;
}
.section_1 {
    display: flex;
    gap: 32px;
    justify-content: flex-start;
    align-items: center;
}
.section_1 > .content {
    display: flex;
    flex-direction: column;
    gap: 15px;
}
.section_1 > .content > p {
    margin: 0;
}

/* Media Queries */
@media screen and (min-width:375px) {
    .current_order_card > .main > .image > img {
        width: 100px;
    }
}
@media screen and (min-width:425px) {
    .section > h5 {
        font-size: 18px;
    }
    .current_order_card > .main > .content > h3 {
        font-size: 17px;
    }
    .current_order_card > .main > .content > p {
        font-size: 12px;
    }
    .current_order_card > .main > .content > .info {
        font-size: 12px;
    }
    .current_order_card > .help {
        font-size: 12px;
    }
}
@media screen and (min-width:560px) {
    .current_order_card > .main > .image > img {
        width: 100px;
    }
    .current_order_card > .main > .content > h3 {
        font-size: 18px;
    }
    .current_order_card > .main > .content > p {
        font-size: 14px;
    }
    .current_order_card > .main > .content > .info {
        font-size: 14px;
    }
    .current_order_card > .help {
        font-size: 14px;
    }
}

@media screen and (min-width: 768px){
    .history-back-button{
        padding-left: 10%;
    }
    .history_sticky{
        position: relative;
    }
    .dheader{
        min-height: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #EFEFEF;
        margin-bottom: 5%;
    }
    .history_nav{
        border-bottom: none;
        justify-content: flex-start;
        /* column-gap: 35px; */
        /* margin:0 10%; */
        width: 75vw;
        border-bottom: 1px solid #D3D3D3;
        margin-inline: auto;
        margin-top: 22px;
        
    }
    .history_nav_shadow{
        box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
    }
    .history_nav p{
        width:200px;
        cursor: pointer;
    }
    .history_nav .selected{
        /* background-color: #CDCDCD; */
        background-color: #efefef;
        font-weight: 600;
        border-bottom: none;
        width:200px;
        height: 100%;
        column-gap: 0;
    }
    .history_body{
        position: relative;
        padding: 0;
        gap: 0;
        min-height: 500px;
        max-width: 100%;
        /* margin: auto; */
        margin-inline: auto;
        margin-top: 5px;
    }
    .dorder_table{
        position: static;
        scroll-behavior: unset;
    }
    .css-1ps6pg7-MuiPaper-root{
        width:80%;
        margin: auto;
    }
    .css-rorn0c-MuiTableContainer-root{
        width:80%;
    }
    .css-rqglhn-MuiTable-root{
        width:80%;
    }
    .css-1psng7p-MuiTablePagination-spacer{
        display: none;
    }
    .table-btn{
        display: flex;
        justify-content: center;
        align-items: center;
        width:fit-content;
        height: 35px;
        background-color:#B0F3E4;
        border-radius: 35px;
        column-gap: 10px;
        padding: 0 10px;
    }
    .history-table-loader {
        min-height: 500px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    /* p{
        margin-bottom: 0 !important;
    } */
}

@media screen and (max-width: 1210px) {
    .history_nav {
        width: 85vw;
    }
    /* .history_body{
        width: 85vw;
    } */
}

@media screen and (max-width: 1060px) {
    .history_nav {
        width: 95vw;
        font-size: 0.9rem;
    }
    /* .history_body{
        width: 95vw;
    } */
}

@media screen and (max-width:767px) {
    .history_nav {
        width: 100vw;
        font-size: 0.9rem;
    }
    .history_nav > p {
        /* font-family: 'AvenirNext'; */
        font-style: normal;
        font-weight: 600;
        color: #CDCDCD;
    }
    /* .history_body{
        width:100vw;
    } */
    .history_body > .section {
        gap: 2px;
    }
    .order_amount_text {
        font-size: 17px !important;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: -0.36px;
    }
    .history_body {
        padding-bottom: 0;
        padding-top: 27px;
        padding-inline: 20px;
    }
    
}

@media screen and (min-width:768px) {
    .des_order_card{
        width: 40%;
        height: 150px;
        /* margin: 0 auto; */
        margin-bottom: 20px;
        display: flex;
        overflow: hidden;
        column-gap: 25px;
        cursor: pointer;
    }
    .des_order_card_banner{
    min-width: 130px;
    min-height: 130px;
    }
    .des_order_card_banner > img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 10px;
    }
    .des_order_card_details{
        padding-top: 3%;
        display: flex;
        flex-direction: column;
        row-gap: 15px;
    }
    .des_order_card_details  p{
        font-size: 20px;
        font-weight: 600;
    }
}