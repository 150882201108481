:root {
    --primary-white-clr: var(--pure-white);
    --primary-red-clr: #FC3300;
}

.section{
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    background-color: var(--primary-white-clr);
}

.container{
    /* max-width: 1170px; */
    /* margin: 50px auto auto auto; */
    /* min-height: 60vh; */
    padding: 5vw;
    padding-top: 10px
}

.heading {
    font-size: 56px;
    font-weight: 400;
    line-height: 75px;
    text-align: justify;
    margin-bottom: 33px;
    letter-spacing: 0;
    color: var(--primary-red-clr);
}

/* .trending_blogcard{
    display: flex;
    gap:20px;
    width:100%;
    padding: 10px;
    cursor: pointer;
} */

.trending_blog_card{
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: flex-start;
    gap:20px;
    width:100%;
}

.trending_card_image {
    /* height: auto; */
    height: 100%;
    min-width: 50%;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    border-top-right-radius: 0px;
}

.trending_card_image > img {
    height: 100%;
    width: 100%;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    border-top-right-radius: 0px;
}

/* .imageContainer{
    width: 40%;
} */

/* .imageContainer > img{
    width: 100%;
    max-width: 100%;
    max-height: 100%;
} */

/* .about_trending_blog{
    width: 70%;
    padding: 12px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    justify-content: center;
} */

/* .about_trending_blog > h3{
    font-weight: 600;
    font-size: 42px;
} */


.trending_card_content {
    height: 100%;
    padding-bottom: 36px;
    padding-top: 22px;
}

.trending_content_top {
    justify-content: flex-start !important;
    gap: 12px;
}

.trending_title {
    font-size: 30px !important;
    height: auto;
}

.trending_subtitle {
    font-size: 16px;
}

.trending_author{
    font-weight: 600;
    margin-bottom: 0;
}

.created_at{
    color: gray;
    /* font-size: 14px; */
}

@media screen and (max-width:1000px) {
    .trending_blogcard{
        flex-direction: column;
        gap: 0px;
    }
    .imageContainer{
        width: 82%;
        margin: auto;
    }
    /* .imageContainer > img{
        height: 350px;   
    } */
    /* .about_trending_blog{
        width: 82%;
        gap: 0px;
        margin: auto;
    } */
}

.blogcards_grid{
    margin-top: 55px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap:30px;
    /* border: 1px solid; */
    width: 100%;
    padding: 10px;
}

.card_main{
    width: 100%;
    cursor: pointer;
}

.card_image_main{
    width: 100%;
    /* height: 200px; */
    height: fit-content;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
}

.card_image_main > img{
    width: 100%;
    /* height: 100%; */
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
}

.card_image {
    height: 140px;
    width: 100%;
}

/* .card_content{
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    gap: 5px;
} */

.card_content{
    margin-top: 10px;
    gap: 1px;
    align-items: flex-start;
    justify-content: start;
    width: 100%;
}

.content_top {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.content_top > h3 {
    font-weight: 600;
    font-size: 18px;
    /* height: 48px; */
}

.subtitle {
    font-size: 13px;
    height: 58px;
}

.card_content_bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.pagination_container {
    font-size: 1.1rem;
    margin-top: 30px;
    margin-bottom: 60px;
    font-weight: 600;
    display: flex;
    justify-content: center;
}

@media screen and (max-width:1100px) {
    .blogcards_grid{
        grid-template-columns: 1fr 1fr;
    }
    .trending_blog_card {
        grid-template-columns: 1fr;
        gap: 0px;
    }
    .trending_card_image {
        border-bottom-left-radius: 0px;
        border-top-right-radius: 15px;
    }
    
    .trending_card_image > img {
        border-bottom-left-radius: 0px;
        border-top-right-radius: 15px;
    }
    .trending_card_content {
        padding-top: 8px;
    }
}

@media screen and (max-width:600px) {
    .blogcards_grid{
        grid-template-columns: 1fr ;
    }
    /* .imageContainer > img{
        height: 250px;   
    } */
}

@media screen and (max-width:767px) {
    /* .container {
        padding-top: 170px;
    } */
    .card_content{
        margin-top: 10px;
        gap: 0px;
    }
    /* .imageContainer{
        width: 100%;
    } */
    /* .about_trending_blog{
        width: 100%;
        gap: 0px;
    }
    .about_trending_blog > h3{
        font-size: 36px;
    } */
    .heading {
        font-size: 42px;
        line-height: 52px;
        margin-bottom: 25px;  
    }
}