.product_card {
    width: 300px;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    overflow: hidden;
    /* font-family: Arial, sans-serif; */
    margin-inline:auto;
    cursor: pointer;
  }
  .product_image {
    position: relative;
    height: 400px;
  }
  .itemRedAlert {
    color: var(--red-color);
  }
  .product_image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .discount_tag {
    font-size: 16px;
    font-weight: 900 !important;
    color: #FC3300;
  }
  .product_info {
    padding: 16px;
  }
  .product_name {
    margin: 0 0 8px 0;
    font-size: 14px;
    font-weight: 600;
    color: #333;
  }
  .product_price {
    display: flex;
    align-items: start;
    flex-direction: column;
  }
  .current_price {
    font-size: 18px;
    font-weight: bold;
    color: #333;
    margin-right: 8px;
    display: flex;
    align-items: center;
    gap: 4px;
  }
  .original_price {
    font-size: 14px;
    color: #999;
    text-decoration: line-through;
  }
  .donation_impact {
    position: absolute;
    bottom: 10px;
    left: 10px;
    background-color: white;
    border: 2px solid #ff6600;
    padding: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .impact_label {
    font-size: 10px;
    font-weight: bold;
    color: #333;
    margin-bottom: 2px;
  }
  .impact_amount {
    font-size: 16px;
    font-weight: bold;
    color: #ff6600;
  }

  .live_now_container {
    display: flex;
    justify-content: space-between;
  }

  .live_now{
    padding: 3px 5px;
    border: 2px solid greenyellow;
    /* border-radius: 20%; */
    cursor: pointer;
  }