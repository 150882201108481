.desktop_container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 69px;
    padding-top: 118px;
    /* padding-bottom: 154px; */
    padding-bottom: 100px;
    flex-direction:row;
}

.desktop_para_container {
    width: 100%;
}

.desktop_para {
    font-size: 23px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0.46px;
}

.logo_container {
    width: 100%;
    display: flex;
    justify-content: center;
}
.learn_more_btn{
    margin-top: 25px;
    font-size: 18px;
    font-weight: 600;
    padding: 5px 21px;
    border-radius: 25px;
    border: 2px solid #FC3300;
    width: fit-content;
    font-style: normal;
    letter-spacing: 0.98px;
    color: #FC3300;
}
@media screen and (max-width:875px) {
    .desktop_container {
        flex-direction: column;
    }
    .logo_container {
        width: auto;
    }
    .desktop_para_container {
        width: auto;
    }
}