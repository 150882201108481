/* .variant-selector-container {
    display: grid;
    align-content: center;
    grid-template-columns: 1fr 3fr;
    align-items: center;
} */

.variant-optionName button {
    margin: 10px !important;
}

.variant-selector-container button {
    margin: 0 10px 10px 0 !important;
}