.boundlessEventContainer {
    display: flex;
    gap: 40px;
}

.eventsLeft {
    width: 400px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
}

.topPart {
    display: flex;
    gap: 24px;
    flex-direction: column;
}

.eventsTitle {
    width: min-content;
}

.para {
    font-weight: 400;
    font-size: 20px;
    line-height: 32px;
    color: var(--black-color);
}

.eventsRight {
    display: flex;
    gap: 40px;
}

@media screen and (min-width: 1500px) {
    .eventsLeft {
        width: 480px;
    }
}

@media screen and (min-width:1750px) {
    .eventsLeft {
        width: 580px;
    }
}

@media screen and (max-width:1189px) {
    .boundlessEventContainer {
        flex-direction: column;
        gap: 32px;
    }
    .eventsLeft {
        width: 100%;
    }
}

@media screen and (max-width:768px) {
    .eventsRight {
        flex-direction: column;
    }
}