.formTextareaContainer {
    width: 100%;
}

.formtextArea {
    outline: none;
    resize: none;
    width: 100%;
    border: 1px solid var(--black-color);
    background-color: var(--pure-white);
    padding: 12px;
    border-radius: 5px;
    font-size: 14px;
}

.formtextareaMaxChars {
    font-size: 12px;
    margin-top: 3px;
    text-align: right;
}