.customer_support_section {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding-top: 1px;
}

.customer_support {
  display: flex;
  flex-direction: column;
  gap: 35px;
  padding-inline: 30px;
  color: #f0e8e3;
  gap: 0;
  justify-content: space-between;

}

.customer_support>h1 {
  font-weight: 800;
  font-size: calc(1.275rem + 0.3vw);
}

.customer_support>.part {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.customer_support>.part>p {
  margin: 0;
}

.customer_support>div>.part>p {
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.36px;
  color: #8f8f8f;
}

.customer_support_section>.footer-extra-container-class-30313A {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}