.dsection {
    max-width: 1250px;
    margin: 30px auto;
    padding: 0 20px;
}

.dcurrent_events {
    margin: 35px auto;
}

.dcurrent_events>h3 {
    font-weight: 600;
    /* font-size: 42px; */
    font-size: 36px;
    color: var(--color-lffu6uvb);
    /* margin-bottom: 50px; */
    margin-bottom: 35px;
}

.dbrowse_cards {
    display: flex;
    flex-direction: row;
    overflow-x: auto;
    flex-wrap: nowrap;
    gap: 30px;
}

.dbrowse_cards::-webkit-scrollbar {
    display: none;
}

.mobileHeading {
    font-size: 32px !important;
    font-style: normal;
    font-weight: 400 !important;
    line-height: 35px;
    /* letter-spacing: -0.64px; */
}

.donor_name {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    text-align: center;
    margin-top: 12px;
    max-width: 100px;
    display: none;
}

/* styles for new desktop design */

.desktop_section {
    padding-block: 60px;
    background-color: #EFF0F3;
}

.desktop_container {
    display: flex;
    flex-direction: column;
    gap: 55px;
    justify-content: center;
}

.mobileHeading {
    font-family: "alternategotno1dregular";
    font-size: 64px !important;
    font-weight: bold !important;
    text-align: center;
    color: var(--black-color) !important;
}

.paraheading {
    font-size: 20px;
    line-height: 1.3;
    text-align: center;
}

.category_container_donor {
    display: flex;
    align-items: center;
    /* display: grid; */
    grid-auto-flow: column;
    overflow-y: hidden;
    overflow-x: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    gap: 20px;
    position: relative;
    justify-content: center;
    flex-wrap: wrap;
    max-width: 990px;
    margin: 0 auto !important;
}

.category_container_donor > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    /* width: 80%; */
    height: 100%;
    /* margin-inline: auto; */
    flex: 0;
}

.category_container_donor img {
    display: block;
    margin-inline: auto;
    width: 100px;
}

.bottomLink {
    text-align: center;
}

.bottomLink a {
    text-decoration: underline !important;
}

.headingH2 {
    color: var(--black-color);
    text-align: center;
}

.DonorHeader {
    text-align: center;
}

.sectionSubtitle {
    margin-bottom: 0 !important;
}


@media screen and (max-width:768px) {
    .category_container_donor > div {
        width: auto;
    }
}