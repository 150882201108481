.paginationButtonsContainer {
    display: flex;
    gap: 20px;
}

.paginationButton {
    width: 44px;
    height: 44px;
    background-color: transparent;
    border: 1px solid var(--red-color);
}
.paginationButton:disabled {
    cursor: not-allowed;
}

.navigateArrow {
    color: var(--red-color);
}