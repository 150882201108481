/* .dcurrent_cards {
    display: flex;
    gap: 30px;
    flex-wrap: nowrap;
    overflow-x: auto;
    padding-bottom: 13px;
}

.dcurrent_cards::-webkit-scrollbar {
    display: none;
}

.dcurrent_card {
    flex-shrink: 0;
    width: 240px;
    height: 500px;
}

.dcurrent_card_img {
    height: 75%;
    width: 100%;
}

.dcurrent_card_img>img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.dsection {
    max-width: 1250px;
    margin: 70px auto;
    padding: 0 20px;
}


.featured_dcurrent_cards {
    display: flex;
    gap: 30px;
    flex-wrap: nowrap;
    overflow-x: auto;
    padding-bottom: 13px;
}

.featured_dcurrent_cards::-webkit-scrollbar {
    display: none;
}

.featured_h3 {
    font-weight: 600;
    font-size: 36px;
    margin-bottom: 35px;
}

.featured_card {
    min-width: 320px;
    max-width: 320px;
}

@media (max-width: 768px) {
    .featured_h3 {
        font-weight: 700;
        font-size: 22px;
        margin-bottom: 15px;
    }
}

@media (min-width: 768px) {
    .featured_h3 {
        color: var(--color-lffu6uvb);
    }
} */



/* New design */
.featuredContainer {
    display: flex;
    flex-direction: column;
    gap: 60px;
}

.featuredHeader {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.featuredHeading {
    width: min-content;
}

.featuredPara {
    font-size: 20px;
    line-height: 32px;
    font-weight: 400;
    max-width: 358px;
}

@media screen and (max-width:768px) {
    .featuredHeader {
        flex-direction: column;
        gap: 20px;
    }
}