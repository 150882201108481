.sale-promo {
  display: flex;
  justify-content: space-between;
  padding: 80px;
  background-color: #eff0f3;
}

.promo-header {
  margin-bottom: 2rem;
  max-width: 413px;
}

.sales-promo {
  display: flex;
  align-items: center;
  height: 100%;
}

.promo-title {
  font-size: 4rem;
  font-weight: bold;
  margin: 0;
  color: black;
  font-family: 'alternategotno1dregular';
}

.promo-description {
  font-size: 1.5rem;
  color: #555;
  margin: 1rem 0 !important;
}

.promo-button button{
  border-radius: 40px;
  min-width: 250px;
  padding: 7px 20px;
  background-color: var(--red-color);
  color: var(--pure-white);
  font-weight: bold;
  font-family: "alternategotno1dregular";
  font-size: 2rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 40px;
}

.promo-button button:hover {
  background-color: darkred;
}

.promo-image {
  max-width: 520px;
  
}

.promo-features {
  display: flex;
  align-items: center;
  justify-content: right;
}

.promo-image img {
  width: 100%;
}

.feature-item {
  background: #fff;
  padding: 1rem;
  border: 1px solid #ddd;
  border-radius: 10px;
  text-align: center;
  width: 40vw;
  display: flex;
  flex-direction: column;
  align-items: start;
}

.feature-item svg path {
  fill: var(--red-color);
}

.feature-icon {
  height: 50px;
  width: 50px;
  margin: 0 auto 1rem auto;
}

.feature-title {
  font-size: 1.2rem;
  font-weight: bold;
  margin: 0;
  color: black;
  margin: 10px 0 8px;
}

.feature-description {
  font-size: 1rem;
  color: #777;
  margin: 0 0 8px 0;
}

@media (max-width: 899px) {
  .promo-title {
    font-size: 3rem;
    font-family: "alternategotno1dregular";
    text-align: left;
  }

  .feature-item {
    width: 80vw;
  }

  .promo-features {
    justify-content: center;
    align-items: center;
  } 

  .sale-promo {
    justify-content: center;
    text-align: center;
    padding: 40px;
  }

  .promo-button {
    display: flex;
    justify-content: start;
  }

}
